@media (min-width: 992px) {
    .horizontal {
      .horizontal-main .sub-slide2 .sub-slide-menu1 {
        left: 185px;
        top: 8px;
        width: 190px;
        position: absolute;
        background-color: $white;
        z-index: 9999;
        border-radius: 5px;
        padding: 0px;
        min-width: fit-content;
        box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
        border: 1px solid $border;
      }
      .app-sidebar {
        border-bottom: 1px solid $border !important;
        border-top: 0;
      }
      .horizontal-main {
        border-left: 0 !important;
        border-right: 0 !important;
      }

      .hor-header .container,
      .horizontal-main .container,
      .main-content.hor-content .container {
        max-width: 80% !important;
        padding: 0;
      }

      .app-sidebar .side-item.side-item-category {
        display: none;
      }

      .side-menu {
        display: flex;
        overflow: hidden;
      }

      .main-sidemenu {
        margin-top: 0;
      }

      .main-sidebar-header {
        display: none;
      }

      .side-menu__item {
        border-bottom: 0 !important;
      }

      ul.slide-menu li:last-child a.slide-item ,
      // ul.slide-menu li.sub-slide a.sub-side-menu__item ,
      ul.sub-slide-menu li:last-child a.sub-slide-item,
      ul.sub-slide-menu1 li:last-child a.sub-slide-item2 {
        border-bottom: 0;
      }

      .hor-header .header-brand {
        display: block;
        margin: 0;
        line-height: 2.7rem;
      }

      .side-menu__item .angle {
        transform: rotate(-90deg);
      }

      .horizontal-main {
        width: 100%;
        position: relative;
        bottom: initial;
        z-index:1;
        padding-bottom: 0;

        &.ps {
          overflow: visible !important;
        }

        .slide {
          .slide-menu,
          .sub-slide-menu {
            width: 190px;
            position: absolute;
            background-color: $white;
            z-index: 9999;
            border-radius: 5px;
            padding: 0px;
            min-width: fit-content;
            box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
            border: 1px solid $border;
          }
        }
      }

      .slide-item {
        padding: 8px 25px !important;
      }

      .sub-side-menu__item {
        padding: 8px 25px !important;
      }

      .sub-slide-item {
        padding: 8px 25px !important;
        color: $color;
      }

      .sub-slide-menu .sub-slide-item2 {
        padding: 8px 25px !important;
      }

      .sub-slide .sub-slide-menu {
        position: absolute;
        background-color: $white;
        z-index: 9999;
        box-shadow: 5px 5px 5px #b9b9b9;
        border-radius: 5px;
        padding: 0px;
        min-width: fit-content;
        border: 1px solid #f0f0f8;
        left: 185px;
        top: 13px;
      }

      .side-menu > li > a {
        display: flex;
        margin: 0px -4px !important;
        text-decoration: none;
        position: relative;
        color: #7b8191;
        padding: 10px 13px 10px 13px !important;
        font-weight: 400;
      }
      .breadcrumb-header {
        margin-top: 20px;
      }

      .slide {
        margin: 0 5px;
      }

      .logo-horizontal {
        display: block;
      }

      .app-sidebar__toggle {
        display: none;
      }

      .mobile-logo.dark-logo-1 {
        display: none;
      }

      .main-content {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      .main-sidemenu .slide-right {
        right: 0;
      }

      .main-sidemenu .slide-left,
      .main-sidemenu .slide-right {
        position: absolute;
        top: 5px;
        padding: 10px;
        font-size: 16px;
        color: $color;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        border: 1px solid $border;
        background-color: $background;
        margin: 0 10px;
        border-radius: 50px;
      }

      .main-sidemenu .slide-left {
        left: 0;
      }

      .horizontal-main {
        position: relative;
        margin: 0 auto;
        &.fixed-header {
          position: fixed;
        }
      }

      .main-sidemenu {
        overflow: hidden;
      }

      .sticky-pin {
        position: fixed;
      }
      .hor-header {
        position: relative !important;
        z-index: 9 !important;
        padding: 0.45rem 0;
        box-shadow: none;
        border-bottom: 1px solid $border;
        background-color: white;
      }
      footer.footer {
          padding: 1.25rem;
      }
    }
    .horizontal-hover.horizontal {
      .slide {
        &:hover {
          ul.slide-menu {
            display: block !important;
          }
        }
      }
      .sub-slide {
        &:hover {
          .sub-slide-menu {
            display: block !important;
          }
        }
      }
    }
    .horizontal-main {
      .app-sidebar__user {
        display: none;
      }
      #bg-side-text {
        display: none;
      }
      &.app-sidebar .slide .side-menu__item.active::before {
        display: none;
      }
    }

    .horizontal .horizontal-switcher {
      display: block;
    }
}

.logo-horizontal {
  display: none;
}

.horizontal {
  @media (max-width: 991.98px) {
    // .app-sidebar.horizontal-main {
    //   left: -300px;
    // }
    .main-sidemenu {
      padding: 0;
    }
    .horizontalMenucontainer .app-header.hor-header {
      position: fixed;
    }
    .slide-left,
    .slide-right {
      display: none !important;
    }
    .main-content {
      min-width: 100%;
      min-height: calc(100vh - 50px);
      margin-top: 65px;
      margin-bottom: 0 !important;
      -webkit-transition: margin-left 0.2s ease;
      -o-transition: margin-left 0.2s ease;
      transition: margin-left 0.2s ease;
      overflow: hidden;
      .side-app {
        padding: 0 0.75rem;
      }
    }
  }
  .main-sidemenu.container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: none;
  }
}
@media (min-width: 992px) {
  .horizontal-hover.horizontal .sub-slide2:hover .sub-slide-menu1 {
    display: block !important;
  }
}

.hor-header {
  .responsive-logo {
    display: block;
    img {
      height: 2rem;
      text-align: center;
      margin: 0 auto;
      justify-content: center;
    }
  }
}

@media (min-width: 992px) {
  .horizontalMenucontainer .header-brand-img {
      &.mobile-logo, &.darkmobile-logo, &.dark-logo {
          display: none;
      }
  }
}

@media (max-width: 991px) {
  .header.top-header .container {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .horizontal.center-logo {
    .header-brand {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      .header-brand-img {
        margin: 0 auto;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .horizontal.center-logo .header .form-inline .form-control {
    width: 300px;
  }
}

.horizontal  {

  &.app {
    .main-sidemenu {
      #side2, #side3, #side4, #side6, #side7, #side8, #side10, #side11, #side12, #side14,
      #side15, #side16, #side18, #side19, #side20, #side22, #side23, #side24,  #side26, #side27, #side28,
      #side30, #side31, #side32,  #side34, #side35, #side36,  #side38, #side39, #side40,  #side42, #side43, #side44 {
        display: none !important;
      }
      .tab-content>.tab-pane {
        display: block !important;
      }
    }
  }

}
