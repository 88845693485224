.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

@media (min-width: 576px) {
	.text-sm-start {
		text-align: left !important;
	}

	.text-sm-end {
		text-align: right !important;
	}

	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-start {
		text-align: left !important;
	}

	.text-md-end {
		text-align: right !important;
	}

	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-start {
		text-align: left !important;
	}

	.text-lg-end {
		text-align: right !important;
	}

	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1280px) {
	.text-xl-start {
		text-align: left !important;
	}

	.text-xl-end {
		text-align: right !important;
	}

	.text-xl-center {
		text-align: center !important;
	}
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-500 {
	font-weight: 500 !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-italic {
	font-style: italic !important;
}

.fw-600 {
	font-weight: 600;
}

.text-white {
	color: #fff !important;
}

.text-white-transparent {
	color: #fff !important;
	opacity: 0.9;
}

.text-white-transparent-1 {
	color: #fff !important;
	opacity: 0.5;
}

.text-success {
	color: #21c44c !important;
}

a.text-success {

	&:hover,
	&:focus {
		color: #448700 !important;
	}
}

.text-info {
	color: #26c2f7 !important;
}

a.text-info {

	&:hover,
	&:focus {
		color: #1594ef !important;
	}
}

.text-warning {
	color: #ffb209 !important;
}

a.text-warning {

	&:hover,
	&:focus {
		color: #c29d0b !important;
	}
}

.text-danger {
	color: #ff1a1a !important;
}

a.text-danger {

	&:hover,
	&:focus {
		color: #d22827 !important;
	}
}

.text-light {
	color: #ebedef !important;
}

a.text-light {

	&:hover,
	&:focus {
		color: #dae0e5 !important;
	}
}

.text-dark {
	color: #182d49 !important;
}

a.text-dark {

	&:hover,
	&:focus {
		color: #7b6fe0 !important;
	}
}

.text-body {
	color: #495057 !important;
}

.text-default-body {
	color: #323c53 !important;
}

.text-muted {
	color: #8c8ea9 !important;
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.text-wrap {
	font-size: 14px;
	line-height: 1.66;

	> {
		:first-child {
			margin-top: 0;
		}

		:last-child {
			margin-bottom: 0;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 1em;
		}
	}
}

.leading-none {
	line-height: 1 !important;
}

.leading-tight {
	line-height: 1.25 !important;
}

.leading-normal {
	line-height: 1.5 !important;
}

.leading-loose {
	line-height: 3 !important;
}

.display-1 i,
.display-2 i,
.display-3 i,
.display-4 i {
	vertical-align: baseline;
	font-size: 0.815em;
}

.text-inherit {
	color: inherit !important;
}

.text-default {
	color: $color !important;
}

.text-muted-dark {
	color: #6e7687 !important;
}

.tracking-tight {
	letter-spacing: -0.05em !important;
}

.tracking-normal {
	letter-spacing: 0 !important;
}

.tracking-wide {
	letter-spacing: 0.05em !important;
}

.display-1 {
	font-size: 10.5rem;
	font-weight: 500;
	line-height: 1.1;
}
.display-2 {
	font-size: 4rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-3 {
	font-size: 2.5rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-4 {
	font-size: 3rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-5 {
	font-size: 2.5rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-6 {
	font-size: 2.3rem;
	font-weight: 300;
	line-height: 1.1;
}


.fs-10 {
	font-size: 10px !important;
}
.fs-11 {
	font-size: 11px !important;
}
.fs-12 {
	font-size: 12px !important;
}
.fs-13{
	font-size: 13px !important;
}
.fs-14{
	font-size: 14px !important;
}
.fs-15{
	font-size: 15px !important;
}
.fs-16{
	font-size: 16px !important;
}
.fs-17 {
	font-size: 17px !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-19 {
	font-size: 19px !important;
}
.fs-20 {
	font-size: 20px !important;
}
.fs-23 {
	font-size: 23px !important;
}
.fs-25 {
	font-size: 25px !important;
}
.fs-30 {
	font-size: 30px !important;
}
.fs-40 {
	font-size: 40px;
}
.fs-50 {
	font-size: 50px;
}
.op-1 {
	opacity: 1 !important;
}
.op-0 {
	opacity: 0 !important;
}
.op-5 {
	opacity: 0.5 !important;
}