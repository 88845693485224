.fileinput-button {
	background: none repeat scroll 0 0 #eeeeee;
	border: 1px solid #e6e6e6;
	float: left;
	margin-right: 4px;
	overflow: hidden;
	position: relative;

	input {
		cursor: pointer;
		direction: ltr;
		font-size: 23px;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-300px, 0px) scale(4);
	}
}

* {
	html .fileinput-button {
		line-height: 24px;
		margin: 1px -3px 0 0;
	}

	+html .fileinput-button {
		margin: 1px 0 0;
		padding: 2px 15px;
	}
}