.br-100 {
	border-radius: 100% !important;
}

.br-7 {
	border-radius: 7px;
}

.br-3 {
	border-radius: 3px;
}

.br-tl-7 {
	border-top-left-radius: 7px !important;
}

.br-bl-7 {
	border-bottom-left-radius: 7px !important;
}

.br-tr-7 {
	border-top-right-radius: 7px !important;
}

.br-br-7 {
	border-bottom-right-radius: 7px !important;
}

.br-tl-0 {
	border-top-left-radius: 0px !important;
}

.br-bl-0 {
	border-bottom-left-radius: 0px !important;
}

.br-tr-0 {
	border-top-right-radius: 0px !important;
}

.br-br-0 {
	border-bottom-right-radius: 0px !important;
}

.noborder {
	border-radius: 0;
}

.brround {
	border-radius: 50%;
}

.bradius {
	border-radius: 25%;
}

.border {
	border: 1px solid $border !important;
}

.border-top {
	border-top: 1px solid $border !important;
}

.border-end {
	border-right: 1px solid $border !important;
}

.border-bottom {
	border-bottom: 1px solid $border !important;
}

.border-start {
	border-left: 1px solid $border !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-end-0 {
	border-right: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-start-0 {
	border-left: 0 !important;
}

.border-success {
	border-color: #4ecc48 !important;
}

.border-info {
	border-color: #45aaf2 !important;
}

.border-warning {
	border-color: #ecb403 !important;
}

.border-danger {
	border-color: #c21a1a !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #343a40 !important;
}

.border-white {
	border-color: #fff !important;
}

.rounded {
	border-radius: 0px !important;
}

.rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.rounded-end {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.rounded-start {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-0 {
	border-radius: 0 !important;
}