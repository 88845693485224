/*-- rating--*/

.rating-stars {
	width: 100%;
	text-align: center;

	.rating-stars-container {
		font-size: 0px;

		.rating-star {
			display: inline-block;
			font-size: 32px;
			cursor: pointer;
			padding: 5px 10px;
			color: #e0e5ea;

			&.sm {
				display: inline-block;
				font-size: 14px;
				color: $border;
				cursor: pointer;
				padding: 5px;
			}

			&.is--active,
			&.is--hover {
				color: #f1c40f;
			}

			&.is--no-hover {
				color: $background;
			}

			&.is--active .fa-heart,
			&.is--hover .fa-heart {
				color: #f00;
			}

			.fa-heart .is--no-hover {
				color: $background;
			}
		}
	}

	input {
		display: block;
		margin: 0 auto;
		text-align: center;
		padding: 0.375rem 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.6;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid $border;
		border-radius: 3px;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
}

.price-detail-wrap .rating-stars {
	text-align: left !important;
}