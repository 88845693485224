@media (max-width: 1279px) {
	.wideget-user-info .wideget-user-warap {
		margin-top: 2rem !important;
	}
}

@media (max-width: 460px) {
	.wideget-user-desc {
		.wideget-user-img {
			width: 130px;
			height: 130px;
		}

		.user-wrap {
			margin-top: 0.7rem !important;
			margin-left: 13px;
		}
	}

	.wideget-user-info .wideget-user-warap .wideget-user-warap-r {
		margin-left: 5rem !important;
	}
}

.page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}

.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.page-content {
	margin: .75rem 0;
}

@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0;
	}
}

/*------- Forms -------*/

.authentication {
	input {
		border: 0;
		padding-left: 32px !important;
		border-radius: 0;
	}

	.card:hover {
		box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.4), 0 3px 6px 0 rgba(0, 0, 0, 0.4);
	}

	.card-title {
		font-size: 1.185rem;
	}

	.form-control {
		&:focus {
			box-shadow: none;
		}

		margin-bottom: 1rem;
	}

	input::placeholder {
		color: #8c8ea9;
		margin-top: 1px;
	}

	.flex-c-m .login100-social-item i {
		color: $white;
		margin-left: 0;
	}

	.wrap-input {
		width: 100%;
		margin: 0 auto;
	}
}

.login100-social-item {
	font-size: 20px;
	color: $white;
	display: inline-block;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 5px;
	text-align: center;
	line-height: 2;

	&:hover {
		color: $white;
	}
}

.bg1 {
	background: linear-gradient(to right bottom, #163b7c 0%, #548beb 100%);
}

.bg2 {
	background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%) !important;
}

.bg3 {
	background: linear-gradient(to bottom right, #f53e31, #dd4b39);
}

.form-inline .form-check {
	width: 100%;
}

.fade {
	transition: opacity 0.15s linear;

	&:not(.show) {
		opacity: 0;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.collapse:not(.show) {
	display: none;
	background: $white;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

.notifyimg {
	color: $white;
	float: left;
	height: 40px;
	line-height: 40px;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	border-radius: 50%;
}

.drop-icon-wrap {
	text-align: center;

	li {
		display: inline-block;
	}

	.drop-icon-item {
		display: inline-block;
		padding-bottom: 10px;
		padding-top: 10px;
		text-align: center;
		color: #878787;
		text-transform: capitalize;
		min-width: 75px;
	}
}

.block {
	display: block !important;
}

.drop-icon-item i {
	font-size: 20px;
	padding-bottom: 5px;
}

.drop-icon-wrap .drop-icon-item:hover {
	text-decoration: none;
	background-color: $background;
}

.dropdown-item.user {
	font-size: 18px;
	padding: 5px;
}

.user-semi-title {
	font-size: 14px;
	line-height: 23px;
}

.floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}

/*----- Global Loader -----*/

#global-loader {
	position: fixed;
	z-index: 50000;
	background: rgba(255, 255, 255);
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.loader-img {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 35%;
	margin: 0 auto;
}

ul li ul:hover {
	visibility: visible;
	opacity: 1;
}

.list.d-flex img {
	width: 60px;
}

#popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: white;
	border-radius: 5px;
}

#copy {
	background: none;
	color: white;
	font-weight: bold;
	padding: 8px 25px;
	border: 0;
}

/*alerts*/
/*----- Typography ------*/

.heading-inverse {
	background-color: #333;
	color: $white;
	padding: 5px;
}

.heading-primary {
	color: $white;
	padding: 5px;
}

.heading-secondary {
	color: $white;
	padding: 5px;
}

.heading-success {
	background-color: $success;
	color: $white;
	padding: 5px;
}

.heading-info {
	background-color: $info;
	color: $white;
	padding: 5px;
}

.heading-warning {
	background-color: $warning;
	color: $white;
	padding: 5px;
}

.heading-danger {
	background-color: $danger;
	color: $white;
	padding: 5px;
}

/* ----- v CAN BE DELETED v ----- */

.wrap {
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}

a {

	&:focus,
	&:hover,
	&:active {
		outline: 0;
		text-decoration: none;
	}
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}

	to {
		background-position: 0 0;
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}

	to {
		background-position: 0 0;
	}
}

.social {
	i {
		font-size: 40px;
	}

	.social-icon i {
		font-size: 14px;
	}
}

.instagram {
	background: linear-gradient(to right bottom, #de497b 0%, #e1164f 100%);
}

.linkedin {
	background-image: linear-gradient(to right bottom, #0d97de 0%, #13547a 100%);
}

.twitter {
	background-image: linear-gradient(to right bottom, #00f2fe 0%, #1e63c3 100%);
}

.facebook {
	background-image: linear-gradient(to right bottom, #3d6cbf 0%, #1e3c72 100%);
}

.close {
	float: right;
	font-size: 1.40625rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: none;
	opacity: .65;

	&:hover,
	&:focus {
		color: inherit;
		text-decoration: none;
		opacity: 1;
	}

	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	color: inherit;
}

.widget-info {
	i {
		width: 100px;
		height: 100px;
		padding: 27px 0;
		text-align: center;
		border: 2px solid $white;
		border-radius: 50%;
	}

	a {
		border-bottom: 1px solid $white;
	}
}

/*------ Headers -------*/

.page-header {
	display: -ms-flexbox;
	-ms-flex-align: center;
	align-items: center;
	margin: 1rem 0;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	border-radius: 3px;
	position: relative;
	min-height: 50px;
	// background: $white;
	// border: 1px solid $border;
	padding: 0;
	display: flex;
}

.page-title {
	margin: 0;
	font-size: 25px;
	font-weight: 500;
	line-height: 1.5rem;
	position: relative;
	color: $color;
	margin-bottom: 0.7rem;
}

.page-header .breadcrumb {
	font-size: 14px;
}

.page-title-icon {
	color: #9aa0ac;
	font-size: 1.25rem;
}

.page-subtitle {
	font-size: 0.8125rem;
	color: #c0a0f1;
	margin-left: 2rem;
	position: relative;

	a {
		color: inherit;
	}
}

.page-options {
	margin-left: auto;
}

.page-breadcrumb {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.page-description {
	margin: .25rem 0 0;
	color: #6e7687;

	a {
		color: inherit;
	}
}

.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;

	&:first-child {
		margin-top: 0;
	}
}

.aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: $white;
	border-left: 1px solid $border;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}

@media (min-width: 1600px) {
	body.aside-opened .aside {
		visibility: visible;
	}
}

.aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
}

.aside-footer {
	padding: 1rem 1.5rem;
	border-top: 1px solid $border;
}

.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid $border;
}

.nav-unread {
	position: absolute;
	top: 0.4rem;
	background: #ecd938;
	width: .5rem;
	height: .5rem;
	border-radius: 50%;

	&.badge {
		position: absolute;
		top: 0;
		text-align: center;
		right: 0;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		border-radius: 50%;
	}
}

.nav-link.icon {
	display: block;
	text-align: center;
	font-size: 20px;
	position: relative;
}

.center-block {
	margin-right: auto;
	margin-left: auto;
	float: inherit !important;
}

.icon i {
	vertical-align: -1px;
}

a.icon {
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: #1a1a1a !important;
	}
}

.o-auto {
	overflow: auto !important;
}

.o-hidden {
	overflow: hidden !important;
}

.shadow {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow-none {
	box-shadow: none !important;
}

#headerMenuCollapse {

	.nav-link .badge,
	.nav-item .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}

.page-main {
	position: relative;
	overflow: hidden;
}

@media (max-width: 992px) {
	.page-header {
		// border-radius: 3px;
		// padding: 15px;
	}

	.page-title {
		font-size: 22px;
	}

	.breadcrumb {
		right: 15px;
	}

	.with-sub .nav-link,
	.nav-item.with-sub {
		display: block !important;
	}

	.about-con {
		border-bottom: 1px solid $border;
	}
}

@media (max-width: 480px) {
	.list-media .info {
		.text-right {
			display: none;
		}

		padding-bottom: 15px;
	}

	#user-profile .tabs-menu2 ul li a {
		padding: 10px 8px 11px 8px;
	}

	.wizard-card .nav-pills>li {
		text-align: center;
		padding: 9px !important;
	}

	.form-control.custom-select.w-auto {
		display: none;
	}

	.mail-inbox .badge {
		margin: 0;
	}

	.mail-option .hidden-phone {
		display: none;
	}

	.countdown-timer-wrapper .timer .timer-wrapper {
		width: 50px;
		margin: 10px;
		margin-bottom: 0 !important;

		.time {
			width: 50px;
			height: 50px;
			font-size: 25px;
		}
	}
}

@media (max-width: 320px) {
	.countdown-timer-wrapper .timer {
		padding: 0 !important;
	}

	.display-2 {
		text-align: center;
		font-size: 3rem;
	}
}

@media (max-width: 480px) {
	.page-header {
		display: block !important;

		.breadcrumb {
			margin-top: 0;
			margin-bottom: 0.5rem;
		}
	}

	#user-profile .tabs-menu2 ul li a {
		padding: 10px 6px 11px 6px;
	}

	.calendar-header th {
		padding: 2px !important;
		font-size: 10px;
	}
}

@media (max-width: 767px) {
	.card-tabs {
		display: block !important;
	}

	.footer .privacy {
		text-align: center !important;
	}

	.shop-dec .col-md-6 {
		&.pr-0 {
			padding-right: 0.75rem !important;
		}

		&.pl-0 {
			padding-left: 0.75rem !important;
		}
	}
}

@media (max-width: 768px) {
	.page-subtitle {
		display: none;
	}

	.ticket-card {
		.col-md-1 {
			width: 100%;
			display: block;
		}

		img {
			display: block;
			text-align: Center;
			margin: 0 auto;
		}
	}

	.page-title {
		line-height: 1.5rem;
	}

	.demo-gallery>ul>li {
		width: 100% !important;
	}

	ul.inbox-pagination li span {
		display: none;
	}
}

@media (max-width: 320px) {
	.wizard-card {

		.nav-pills>li>a,
		.moving-tab {
			font-size: 11px;
		}
	}

	.tui-full-calendar-popup-container {
		padding: 17px 24px !important;
	}
}

@media (max-width: 576px) {
	.sm-mb-3 {
		margin-bottom: 1.5rem !important;
	}
}

@media (max-width: 568px) and (min-width: 480px) {
	.container-messages {
		position: absolute;
		top: 50px !important;
	}

	.messages-list {
		top: 24px !important;
		bottom: 73px !important;
	}

	.messages-right {
		top: 10px !important;
		bottom: 71px !important;
	}

	.messages-left-footer {
		bottom: 40px !important;
		display: none;
	}

	.messages-left .card-header {
		display: none;
	}

	.message-footer,
	.message-header {
		height: 50px !important;
	}

	.message-body {
		top: 50px !important;
		bottom: 50px !Important;
	}
}

@media (max-width: 990px) and (min-width: 569px) {

	.message-header,
	.message-footer {
		height: 50px !important;
	}

	.messages-right {
		top: 10px !important;
		bottom: 80px !important;
	}

	.message-body {
		top: 50px !important;
		bottom: 50px !Important;
	}

	.messages-left .card-header {
		display: none;
	}

	.messages-list {
		top: 24px !important;
	}
}

@media (max-width: 990px) {
	form.convFormDynamic button.submit {
		margin: 4px !important;
	}

	.messages-list .media {
		padding: 9px !important;
	}
}

@media (max-width: 1200px) {
	.richText .richText-toolbar ul li a {
		border: 0px !important;
	}
}

.product_price {
	.old_price {
		text-decoration: line-through !important;
		color: #93a3ba;
	}

	li {
		display: inline-block;
		padding: 0 10px;
	}
}

.close {
	font-size: 1.5rem;
	line-height: 0.8;
	transition: .3s color;
}

.btn-custom {
	background: $primary-1;
	color: $primary;
}

.z-index2 {
	z-index: 2;
}

.page-breadcrumb {
	background: none;
	padding: 0;
	margin: 1rem 0 0;
	font-size: 0.875rem;

	.breadcrumb-item {
		color: #9aa0ac;

		&.active {
			color: #6e7687;
		}
	}
}

@media (min-width: 768px) {
	.page-breadcrumb {
		margin: -.5rem 0 0;
	}
}

.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: right;
}

.profie-img .flex-md-row img {
	width: 60px;
}

.profile-img {
	display: block;
	border: 1px solid rgba(167, 180, 201, 0.2);
	border-radius: 50%;
	font-size: 10px;
	text-align: center;
	background: rgba(225, 225, 225, 0.2);
	z-index: 1000;
	position: relative;
}

.cover-image {
	background-size: cover !important;
}

.widgets-cards .wrp {
	&.icon-circle {
		width: 73px;
		height: 73px;
		border-radius: 50px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		padding: 0;
		margin-right: 15px;

		i {
			font-size: 1.6rem;
			color: $white;
		}
	}

	p {
		margin-bottom: 0;
		line-height: 1;
		margin-bottom: 10px;

		&:first-child {
			font-weight: 600;
			font-size: 25px;
		}
	}
}

ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: .5rem;
}

.legend li {
	padding: 5px;
	display: inline;
}

.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;

	>.card {
		width: 100%;
		min-width: 100%;
	}
}

.icon-size {
	font-size: 2rem !important;
}

.statistics-box {
	position: relative;
	padding-left: 80px;
	text-align: right;
	padding-top: 14px;
	min-height: 80px;
	text-align: center;

	.ico {
		&.small {
			font-weight: 900;
			font-size: 42px;
		}

		position: absolute;
		top: 0px;
		left: 0px;
		width: 80px;
		height: 80px;
		font-size: 70px;
		line-height: 80px;
		text-align: center;
	}

	h2 {
		font-weight: 600 !important;
	}
}

.visitor-list {
	p {
		font-size: 12px;
	}

	i {
		font-size: 20px;
	}
}

.sales-relative {
	position: relative;
}

.icon-absolute {
	position: absolute;
	display: block;
	bottom: 10px;
	right: 10px;
	padding: 20px 0;
	text-align: center;
	border-radius: 50%;
	width: 70px;
	height: 70px;
}

@media (min-width: 991px) and (max-width: 1024px) {
	.icon-absolute {
		display: none;
	}
}

@media (max-width: 991px) {
	.page-main:after {
		content: "";
		height: 200px;
		top: 57px;
	}
}

/*----- Avatars -----*/

.country-selector .avatar {
	width: 1.4rem;
	height: 1.2rem;
}

.product-price {
	font-size: 1rem;

	strong {
		font-size: 1.5rem;
	}
}

@-webkit-keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}

	100%,
	60% {
		left: 100%;
		right: -90%;
	}
}

@keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}

	100%,
	60% {
		left: 100%;
		right: -90%;
	}
}

@-webkit-keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}

	100%,
	60% {
		left: 107%;
		right: -8%;
	}
}

@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}

	100%,
	60% {
		left: 107%;
		right: -8%;
	}
}

@-webkit-keyframes loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.link-overlay {
	position: relative;

	&:hover .link-overlay-bg {
		opacity: 1;
	}
}

.link-overlay-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: .3s opacity;
}

textarea[cols] {
	height: auto;
}

.form-signin .form-control {
	border-right: 0;
	border-left: 0;
	border-top: 0;
	border-radius: 0;

	&:focus {
		box-shadow: none;
	}
}

/**widgets*/

.widgets {
	font-size: 35px;
	padding: 20px;
}

.widgetstext {
	top: 0;
	bottom: 0;
	padding: 20px 40px;
}

.widget-line h4 {
	font-size: 24px;
	font-weight: 600;
}

.fs {
	font-size: 25px;
}

.widget-line p {
	font-size: 16px;
}

.widget-line-list li {
	display: inline-block;
	font-size: 16px;
	line-height: 27px;
	padding: 5px 20px 0 15px;
	list-style-type: none;
}

.wideget-user-info {
	margin-top: 1rem;
}

/*-----Icon input-----*/

.input-icon {
	position: relative;
}

.input-icon-addon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 2.5rem;
	pointer-events: none;
	color: $white;

	&.search-icon {
		right: auto !important;
		left: 0 !important;
		color: #636262 !important;
	}

	&:last-child {
		left: auto;
		right: 0;
	}
}

.sparkline {
	display: inline-block;
	height: 2rem;
}

.jqstooltip {
	box-sizing: content-box;
	font-family: inherit !important;
	background: #333 !important;
	border: none !important;
	border-radius: 3px;
	font-size: 11px !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	padding: 6px !important;

	.jqsfield {
		font: inherit !important;
	}
}

.social-links li a {
	background: #f8f8f8;
	border-radius: 50%;
	color: #9aa0ac;
	display: inline-block;
	height: 1.75rem;
	width: 1.75rem;
	line-height: 1.75rem;
	text-align: center;
}

.map {
	position: relative;
	padding-top: 56.25%;
}

.map-square {
	padding-top: 100%;
}

.map-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.map-header {
	margin-bottom: 1.5rem;
	height: 15rem;
	position: relative;
	margin-bottom: -1.5rem;

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 10rem;
		background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, $background 95%);
		pointer-events: none;
	}
}

.map-header-layer {
	height: 100%;
}

.map-static {
	height: 120px;
	width: 100%;
	max-width: 640px;
	background-position: center center;
	background-size: 640px 120px;
}

@-webkit-keyframes status-pulse {

	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: .32;
	}
}

@keyframes status-pulse {

	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: .32;
	}
}

.status-icon {
	content: '';
	width: 0.5rem;
	height: 0.5rem;
	display: inline-block;
	background: currentColor;
	border-radius: 50%;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	margin-right: .375rem;
	vertical-align: middle;
}

.status-animated {
	-webkit-animation: 1s status-pulse infinite ease;
	animation: 1s status-pulse infinite ease;
}

.widget-line-list {
	margin: 6px;
}

.chips {
	margin: 0 0 -.5rem;
}

.team i {
	float: right;
	color: #8c8ea9;
}

.chips .chip {
	margin: 0 .5rem .5rem 0;
}

.chip {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #6e7687;
	padding: 0 .75rem;
	border-radius: 1rem;
	background-color: rgba(70, 127, 207, 0.06);
	transition: .3s background;

	.avatar {
		float: left;
		margin: 0 .5rem 0 -.75rem;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
	}
}

a.chip:hover {
	color: $white;
	text-decoration: none;
}

.emp-tab table {
	margin-bottom: 0;
	border: 0;
}

.stamp {
	color: $white;
	background: #868e96;
	display: inline-block;
	min-width: 40px;
	height: 40px;
	padding: 0 .25rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
	font-weight: 600;
}

.stamp-md {
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}

.stamp-lg {
	min-width: 4rem;
	height: 4rem;
	line-height: 4rem;
}

.colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 3px;
	color: $white;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

	&:before {
		content: '';
		opacity: 0;
		position: absolute;
		top: .25rem;
		left: .25rem;
		height: 1.25rem;
		width: 1.25rem;
		transition: .3s opacity;
		background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	}
}

.colorinput-input {
	&:checked~.colorinput-color:before {
		opacity: 1;
	}

	&:focus~.colorinput-color {
		border-color: inherit;
		box-shadow: none;
	}
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	&:before,
	&:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}

/*-----Back to Top-----*/

#back-to-top {
	color: $white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	display: none;
	text-align: center;
	z-index: 99999;
	height: 50px;
	width: 50px;
	border-radius: 4px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color 0.1s linear;
	-moz-transition: background-color 0.1s linear;
	-webkit-transition: background-color 0.1s linear;
	-o-transition: background-color 0.1s linear;

	i {
		padding-top: 15px;
		font-size: 16px;
	}

	&:hover {
		background: $white !important;
	}
}

svg {
	-ms-touch-action: none;
	touch-action: none;
}

/*-----Gallery-----*/

.demo-gallery {
	>ul {
		margin-bottom: 0;

		>li {
			float: left;
			margin-bottom: 15px;
			width: 200px;
			border: 0;

			a {
				border: 3px solid $white;
				border-radius: 3px;
				display: block;
				overflow: hidden;
				position: relative;
				float: left;

				>img {
					-webkit-transition: -webkit-transform 0.15s ease 0s;
					-moz-transition: -moz-transform 0.15s ease 0s;
					-o-transition: -o-transform 0.15s ease 0s;
					transition: transform 0.15s ease 0s;
					-webkit-transform: scale3d(1, 1, 1);
					transform: scale3d(1, 1, 1);
					height: 100%;
					width: 100%;
				}

				&:hover {
					>img {
						-webkit-transform: scale3d(1.1, 1.1, 1.1);
						transform: scale3d(1.1, 1.1, 1.1);
					}

					.demo-gallery-poster>img {
						opacity: 1;
					}
				}

				.demo-gallery-poster {
					background-color: rgba(0, 0, 0, 0.1);
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					-webkit-transition: background-color 0.15s ease 0s;
					-o-transition: background-color 0.15s ease 0s;
					transition: background-color 0.15s ease 0s;

					>img {
						left: 50%;
						margin-left: -10px;
						margin-top: -10px;
						opacity: 0;
						position: absolute;
						top: 50%;
						-webkit-transition: opacity 0.3s ease 0s;
						-o-transition: opacity 0.3s ease 0s;
						transition: opacity 0.3s ease 0s;
					}
				}

				&:hover .demo-gallery-poster {
					background-color: rgba(0, 0, 0, 0.5);
				}
			}
		}
	}

	.justified-gallery>a {
		>img {
			-webkit-transition: -webkit-transform 0.15s ease 0s;
			-moz-transition: -moz-transform 0.15s ease 0s;
			-o-transition: -o-transform 0.15s ease 0s;
			transition: transform 0.15s ease 0s;
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
			height: 100%;
			width: 100%;
		}

		&:hover {
			>img {
				-webkit-transform: scale3d(1.1, 1.1, 1.1);
				transform: scale3d(1.1, 1.1, 1.1);
			}

			.demo-gallery-poster>img {
				opacity: 1;
			}
		}

		.demo-gallery-poster {
			background-color: rgba(0, 0, 0, 0.1);
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			-webkit-transition: background-color 0.15s ease 0s;
			-o-transition: background-color 0.15s ease 0s;
			transition: background-color 0.15s ease 0s;

			>img {
				left: 50%;
				margin-left: -10px;
				margin-top: -10px;
				opacity: 0;
				position: absolute;
				top: 50%;
				-webkit-transition: opacity 0.3s ease 0s;
				-o-transition: opacity 0.3s ease 0s;
				transition: opacity 0.3s ease 0s;
			}
		}

		&:hover .demo-gallery-poster {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	.video .demo-gallery-poster img {
		height: 48px;
		margin-left: -24px;
		margin-top: -24px;
		opacity: 0.8;
		width: 48px;
	}

	&.dark>ul>li a {
		border: 3px solid #04070a;
	}
}

.home .demo-gallery {
	padding-bottom: 80px;
}

.col-sm-3 a {
	border: 1px solid transparent;
	border-radius: 0;
	transition: all 3s ease;

	&:hover {
		border: 1px solid #ff4647;
		border-radius: 100% 60% / 30% 10%;
		background: linear-gradient(rgba(56, 123, 131, 0.7), rgba(56, 123, 131, 0.7));
	}
}

.features {
	overflow: hidden;

	h2 {
		font-weight: 600;
		margin-bottom: 12px;
		text-align: center;
		font-size: 2.2em;
	}

	h3 {
		font-size: 20px;
		font-weight: 600;
	}

	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}

.feature {
	.feature-svg {
		width: 25%;
	}

	.feature-svg3 {
		width: 12%;
	}
}

.col-sm-2 .feature {
	padding: 0;
	border: 0px;
	box-shadow: none;
}

.feature {
	.feature-service {
		position: relative;
		display: inline-block;
		width: 3em;
		height: 3em;
		line-height: 2em;
		vertical-align: middle;
		padding-top: 8px;
		border-radius: 50%;
		color: $white;
	}

	p {
		margin-bottom: 0;
	}
}

#messages-main {
	position: relative;
	margin: 0 auto;

	&:after,
	&:before {
		content: " ";
		display: table;
	}

	.ms-menu {
		position: absolute;
		left: 0;
		top: 0;
		border-right: 1px solid #eee;
		padding-bottom: 50px;
		height: 100%;
		width: 240px;
		background: $white;
	}

	.ms-user {
		padding: 15px;
		background: #f8f8f8;

		>div {
			overflow: hidden;
			padding: 3px 5px 0 15px;
			font-size: 11px;
		}
	}

	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 1;
		right: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}

@media (max-width: 767px) {
	#messages-main {
		.ms-menu {
			height: calc(100% - 58px);
			display: none;
			z-index: 1;
			top: 58px;

			&.toggled {
				display: block;
			}
		}

		.ms-body {
			overflow: hidden;
		}
	}
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;

	i {
		font-size: 21px;
	}

	&.toggled i:before {
		content: "";
	}
}

.login-content:after {
	content: "";
}

.message-feed {
	padding: 10px;
}

#footer,
.fileinput .thumbnail {
	text-align: center;
}

.four-zero {
	text-align: center;

	footer>a {
		text-align: center;
	}
}

.ie-warning,
.login-content,
.login-navigation {
	text-align: center;
}

.pt-inner {
	text-align: center;

	.pti-footer>a {
		text-align: center;
	}
}

.message-feed {
	&.right>.pull-right {
		margin-left: 15px;
	}

	&:not(.right) .mf-content {
		color: $white;
	}

	&.right .mf-content {
		background: $background;
	}
}

.mf-content {
	padding: 9px;
	border-radius: 2px;
	display: inline-block;
	max-width: 80%;
	position: relative;
}

.message-feed {
	&:not(.right) .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		left: -20px;
		top: 11px;
	}

	&.right .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-left-color: $background;
		right: -20px;
		top: 11px;
	}
}

.mf-date {
	display: block;
	margin-top: 7px;

	>i {
		font-size: 14px;
		line-height: 100%;
		position: relative;
		top: 1px;
	}
}

.msb-reply {
	position: relative;
	margin-top: 3px;
	background: $white;
	padding: 10px;
	border-radius: 0 7px 7px 7px;
}

.four-zero,
.lc-block {
	box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}

.msb-reply {
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 10px 15px;
		resize: none;
		background: 0 0;
	}

	button {
		top: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 60px;
		font-size: 20px;
		color: $white;
		border-radius: 0 3px 3px 0;
		line-height: 36px;
	}
}

.list-group.lg-alt .list-group-item {
	border: 0;
}

.p-15 {
	padding: 15px !important;
}

.action-header {
	position: relative;
	background: #f8f8f8;
	padding: 15px 13px 15px 17px;
}

.ah-actions {
	z-index: 3;
	float: right;
	margin-top: 7px;
	position: relative;
}

.actions {
	list-style: none;
	padding: 0;
	margin: 0;

	>li {
		display: inline-block;
	}

	&:not(.a-alt)>li>a>i {
		color: #939393;
	}

	>li>a {
		>i {
			font-size: 15px;
		}

		display: block;
		padding: 0 10px;
	}
}

.ms-body {
	background: $white;
	border-radius: 7px;
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;
	cursor: pointer;
	text-align: right;
}

.message-feed.right {
	text-align: right;
}

#ms-menu-trigger,
.toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}

.message-feed {

	&.media .media-body,
	&.right .media-body {
		overflow: visible;
	}
}

.amcharts-chart-div a,
.canvasjs-chart-container a {
	display: none !important;
}

.rotation {
	-webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(359deg);
	}
}

.amChartsLegend.amcharts-legend-div {
	display: none;
}

.input-icon .form-group.is-empty {
	.form-control {
		margin-bottom: 0;
	}

	margin-bottom: 0;
}

/* --------Added--------- css*/

.wizard-card.form-group .form-control {
	background-image: linear-gradient(#c4c4c4, #c4c4c4);
}

.richText {
	.richText-toolbar {
		ul li a {
			border-right: rgba(156, 162, 161, 0.2) solid 1px;
		}

		border-bottom: rgba(156, 162, 161, 0.1) solid 1px;
	}

	border: $border solid 1px;
	background-color: $background;
}

.note-popover .popover-content,
.card-header.note-toolbar {
	background: $background !important;
}

.conv-form-wrapper div#messages div.message.to {
	background: $background;
}

.job-box-filter {
	label {
		width: 100%;
	}

	select.input-sm {
		display: inline-block;
		max-width: 120px;
		margin: 0 5px;
		border: 1px solid rgba(119, 119, 119, 0.3);
		border-radius: 2px;
		height: 34px;
		font-size: 15px;
	}

	label input.form-control {
		max-width: 200px;
		display: inline-block;
		border: 1px solid rgba(119, 119, 119, 0.3);
		border-radius: 2px;
		height: 34px;
		margin-left: 5px;
		font-size: 15px;
	}
}

.job-box-filter {
	padding: 12px 15px;
	border-bottom: 1px solid #e4e4e4;
}

.job-box {
	display: inline-block;
	width: 100%;
	padding: 0px;
}

.job-box-filter {
	a.filtsec {
		margin-top: 8px;
		display: inline-block;
		margin-right: 15px;
		padding: 4px 10px;
		font-family: 'Quicksand', sans-serif;
		transition: all ease 0.4s;
		background: #edf0f3;
		border-radius: 50px;
		font-size: 13px;
		color: #81a0b1;
		border: 1px solid #e2e8ef;

		&.active {
			color: $white;
			background: #16262c;
			border-color: #16262c;
		}

		i {
			color: #03A9F4;
			margin-right: 5px;
		}

		&:hover,
		&:focus {
			color: $white;
			background: #07b107;
			border-color: #07b107;
		}

		&:hover i,
		&:focus i {
			color: $white;
		}
	}

	h4 i {
		margin-right: 10px;
	}
}

/*=====================================
  Inbox Message Style
  =======================================*/

.inbox-message {
	ul {
		padding: 0;
		margin: 0;

		li {
			list-style: none;
			position: relative;
			padding: 15px 20px;
			border-bottom: 1px solid #e4e4e4;

			&:hover,
			&:focus {
				background: #eeeeee;
			}
		}
	}

	.message-avatar {
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.message-avatar img {
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.inbox-message .message-body {
	margin-left: 85px;
	font-size: 15px;
	color: #999999;
}

.message-body-heading h5 {
	font-weight: 600;
	display: inline-block;
	color: #999999;
	margin: 0 0 7px 0;
	padding: 0;
}

.message-body h5 span {
	border-radius: 50px;
	line-height: 14px;
	font-size: 12px;
	color: $white;
	font-style: normal;
	padding: 4px 10px;
	margin-left: 5px;
	margin-top: -5px;

	&.unread {
		background: #07b107;
	}

	&.important {
		background: #dd2027;
	}

	&.pending {
		background: #11a3dd;
	}
}

.message-body-heading span {
	float: right;
	font-size: 14px;
}

.messages-inbox .message-body p {
	margin: 0;
	padding: 0;
	line-height: 27px;
	font-size: 15px;
}

@media (max-width: 600px) {
	.job-box-filter label {
		width: 100%;
		text-align: center;
	}

	.message-body h5 span {

		&.pending,
		&.unread {
			display: none;
		}
	}
}

/* ----Added css----*/

div.conv-form-wrapper div.options div.option.selected,
form.convFormDynamic button.submit:hover {
	color: $white;
}

.addui-slider .addui-slider-track .addui-slider-handle {
	.addui-slider-value {
		background: rgba(111, 63, 115, 0.9) !important;
	}

	&.addui-slider-handle-active {
		background: rgba(111, 63, 115, 0.5);
		box-shadow: 0 0 5px rgba(111, 63, 115, 0.5);
	}
}

/* line 77, C:/wamp/www/github/addSlider/src/partials/_addSlider.scss */

.image-size {
	width: 128px;
	height: 128px;
}

.circle-icon {
	height: 70px;
	width: 70px;
	position: absolute;
	margin-top: 9px;
	margin-left: 13px;
	float: right;
	border-radius: 5px;
}

.square-icon {
	height: 88px;
	width: 81px;
	position: absolute;
	float: right;
	border-radius: 7px 0px 0px 7px;
	line-height: 7;
}

.card-padding {
	padding: 14px 2px 2px 11px;
}

.shadow-primary {
	box-shadow: 0 7px 30px $primary-1 !important;
}

.shadow-secondary {
	box-shadow: 0 7px 30px rgba($secondary, 0.1) !important;
}

.shadow-warning {
	box-shadow: 0 7px 30px rgba($warning, 0.1) !important;
}

.shadow-info {
	box-shadow: 0 7px 30px rgba($info, 0.1) !important;
}

.shadow-success {
	box-shadow: 0 7px 30px rgba($success, 0.1) !important;
}

.shadow-danger {
	box-shadow: 0 7px 30px rgba($danger, 0.1) !important;
}

.line-divide {
	border: 1px solid rgba(218, 216, 219, 0.2);
}

.bg-round {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	position: absolute;
}

.activity {
	position: relative;
	border-left: 1px solid $border;
	margin-inline: 20px;

	.img-activity {
		width: 25px;
		height: 25px;
		text-align: center;
		line-height: 34px;
		border-radius: 50%;
		position: absolute;
		left: -12px;
		-webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
		box-shadow: 0 0 0 0.5px #f8f9fa;
	}

	.item-activity {
		margin-left: 40px;
		margin-bottom: 20px;
	}
}

.bg-square {
	color: $white;
	background: #868e96;
	display: inline-block;
	min-width: 2rem;
	height: 2rem;
	padding: .5rem .25rem;
	text-align: center;
	border-radius: 3px;
	font-weight: 600;
}

.card-items {
	padding: 10px;
}

ul.timeline {
	list-style-type: none;
	position: relative;

	&:before {
		content: ' ';
		background: #d4d9df;
		display: inline-block;
		position: absolute;
		left: 29px;
		width: 2px;
		height: 100%;
		z-index: 400;
	}

	>li {
		margin: -5px 42px;
		padding-left: 20px;

		&:before {
			content: ' ';
			display: inline-block;
			position: absolute;
			border-radius: 50%;
			border: 1px solid #6c6c6f;
			left: 20px;
			width: 20px;
			height: 20px;
			z-index: 400;
		}
	}
}

.activity .img-activity {
	width: 42px;
	height: 42px;
	text-align: center;
	line-height: 34px;
	border-radius: 50%;
	position: absolute;
	left: -22px;
	-webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
	box-shadow: 0 0 0 0.5px #f8f9fa;
}

.user-profile .box {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
	width: 100%;

	.img {
		width: 120px;
		height: 120px;
		margin: 0 auto;
		border-radius: 50%;
		overflow: hidden;
	}
}

.card .box {
	.img img {
		width: 150px;
		height: 150px;
		border-radius: 100%;
	}

	h2 {
		font-size: 20px;
		color: #262626;
		margin: 20px auto;

		span {
			font-size: 14px;
			color: $white;
			display: inline-block;
			padding: 4px 10px;
			border-radius: 15px;
		}
	}

	p {
		color: #262626;
	}
}

.container-login100 .box {
	.social-icons {
		position: relative;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;
			float: left;
			padding: 7px 7px;

			a {
				margin: 0 10px;
				font-size: 20px;
				transition: 0.5s;
				text-align: center;
			}
		}
	}
}

.primary b {
	color: #623AA2;
}

.secondary b {
	color: #f66b4e;
}

.info b {
	color: #1e63c3;
}

.success b {
	color: #21a544;
}

.deco-layer {
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.userprofile {
	width: 100%;
	float: left;
	clear: both;
	margin: 7px auto;

	.userpic {
		height: 100px;
		width: 100px;
		clear: both;
		margin: 0 auto;
		display: block;
		border-radius: 100%;
		box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		position: relative;
	}
}

.userlist a:hover {
	color: $white;
	opacity: 0.9;
}

.userprofile .userpic .userpicimg {
	height: auto;
	width: 100%;
	border-radius: 100%;
}

.username {
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	color: $white;
	margin-top: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	+p {
		color: #f2f2f2;
		font-size: 13px;
		line-height: 15px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.settingbtn {
	height: 30px;
	width: 30px;
	border-radius: 30px;
	display: block;
	position: absolute;
	bottom: 0px;
	right: 0px;
	line-height: 30px;
	vertical-align: middle;
	text-align: center;
	padding: 0;
	box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
	-ms-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.userprofile.small {
	width: auto;
	clear: both;
	margin: 0px auto;

	.userpic {
		height: 40px;
		width: 40px;
		margin: 0 10px 0 0;
		display: block;
		border-radius: 100%;
		box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		position: relative;
		float: left;
	}

	.textcontainer {
		float: left;
		max-width: 100px;
		padding: 0;
	}

	.userpic .userpicimg {
		min-height: 100%;
		width: 100%;
		border-radius: 100%;
	}

	.username {
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: #000000;
		margin: 0px;
		float: left;
		width: 100%;

		+p {
			color: #607d8b;
			font-size: 13px;
			float: left;
			width: 100%;
			margin: 0;
		}
	}
}

.text-transparent {
	color: $white;
	opacity: 0.5;
}

.bg-primary-light {
	background: $white;
	border-radius: 7px;
}

.product-grid6 {
	overflow: hidden;

	.product-image6 {
		overflow: hidden;
	}

	&:hover {
		border-radius: 7px;
	}

	.product-image6 {
		a {
			display: block;
		}

		img {
			width: 100%;
			height: auto;
			transition: all .5s ease 0s;
		}
	}

	&:hover .product-image6 img {
		transform: scale(1.1);
	}

	.product-content {
		text-align: center;
		transition: all .5s ease 0s;
		padding: 15px 0 0 0;
	}

	&:hover .product-content {
		opacity: 0;
	}

	.title {
		font-size: 16px;
		font-weight: 600;
		text-transform: capitalize;
		margin: 0 0 10px;
		transition: all .3s ease 0s;
		margin-bottom: 7px;
	}

	.price {
		font-size: 18px;
		font-weight: 600;

		span {
			color: #8c8ea9;
			font-size: 15px;
			font-weight: 400;
			text-decoration: line-through;
			margin-left: 7px;
			display: inline-block;
		}
	}

	.icons {
		padding: 0;
		margin: 0;
		list-style: none;
		opacity: 0;
		left: 0;
		right: 0;
		text-align: center;
		position: absolute;
		bottom: -3px;
		z-index: 1;
		transition: all .5s ease 0s;
	}

	&:hover .icons {
		opacity: 1;
		bottom: 33px;
	}

	.icons li {
		display: inline-block;

		a {
			font-size: 16px;
			line-height: 45px;
			text-align: center;
			height: 45px;
			width: 45px;
			margin: 2px 7px;
			border-radius: 50px;
			display: block;
			position: relative;
			transition: all .3s ease-in-out;

			&:after,
			&:before {
				content: attr(data-tip);
				color: $white;
				font-size: 12px;
				letter-spacing: 1px;
				line-height: 20px;
				padding: 1px 5px;
				border-radius: 5px;
				white-space: nowrap;
				opacity: 0;
				transform: translateX(-50%);
				position: absolute;
				left: 50%;
				top: -30px;
			}

			&:after {
				content: '';
				height: 15px;
				width: 15px;
				border-radius: 0;
				transform: translateX(-50%) rotate(45deg);
				top: -20px;
				z-index: -1;
			}

			&:hover {

				&:after,
				&:before {
					opacity: 1;
				}
			}
		}
	}
}

.card-counter {
	padding: 20px 10px;
	background-color: $white;
	height: 100px;
	border-radius: 7px;
	transition: .3s linear all;
	margin-bottom: 1.5rem;

	&:hover {
		transition: .3s linear all;
	}

	&.success {
		background: linear-gradient(to bottom right, #62fb62, #21a544) !important;
		color: $white;
	}

	&.info {
		background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%) !important;
		color: $white;
	}

	i {
		font-size: 5em;
		opacity: 0.3;
	}

	.count-numbers {
		position: absolute;
		right: 35px;
		top: 10px;
		font-size: 32px;
		display: block;
	}

	.count-name {
		position: absolute;
		right: 35px;
		top: 65px;
		text-transform: capitalize;
		opacity: 0.5;
		display: block;
		font-size: 18px;
	}
}

.gallery-wrap {
	.img-big-wrap img {
		height: 350px;
		display: inline-block;
		cursor: zoom-in;
		width: auto;
		margin-top: 7px;
	}

	.img-small-wrap {
		.item-gallery {
			width: 80px;
			height: 80px;
			border: 1px solid #ddd;
			border-radius: 4px;
			margin: 7px 2px;
			display: inline-block;
			overflow: hidden;
			text-align: center;
		}

		text-align: center;

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			border-radius: 4px;
			cursor: zoom-in;
		}
	}
}

.social-card-header {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 96px;

	i {
		font-size: 32px;
		color: $white;
	}
}

.card-label {
	border: 0;

	&.list-group-item {
		border-bottom: 0;
	}
}

.error-template h2 {
	font-size: 3.5rem;
}

.counters h3 {
	font-weight: 400;
}

/*-----user-profile----*/

#user-profile {
	h2 {
		padding-right: 15px;
	}

	.profile-status {
		font-size: 0.75em;
		margin-top: -10px;
		color: #7e47a9;

		&.offline {
			color: #fe635f;
		}
	}

	.profile-img {
		padding: 4px;
	}

	.profile-label {
		text-align: center;

		.label {
			padding: 5px 15px;
			font-size: 1em;
		}
	}

	.profile-stars {
		color: #FABA03;
		padding: 7px 0;
		text-align: center;

		>i {
			margin-left: -2px;
		}
	}

	.profile-since {
		text-align: center;
		margin-top: -5px;
	}

	.profile-details {
		ul {
			padding: 0;
			margin-top: 0;
			margin-bottom: 0;
			margin-left: 40px;

			>li {
				margin: 3px 0;
				line-height: 1.5;
			}
		}

		a>i {
			padding-top: 2px;
		}

		ul>li>span {
			color: #643ba2;
		}
	}

	.profile-header {
		position: relative;

		>h3 {
			margin-top: 10px;
		}

		.edit-profile {
			margin-top: -6px;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.profile-tabs {
		margin-top: 30px;
	}

	.profile-user-info {
		padding-bottom: 20px;

		.profile-user-details {
			position: relative;
			padding: 4px 0;

			.profile-user-details-label {
				width: 110px;
				float: left;
				bottom: 0;
				font-weight: bold;
				left: 0;
				position: absolute;
				text-align: right;
				top: 0;
				width: 110px;
			}
		}
	}

	.profile-image {
		position: absolute;
		top: 33px;
		right: 30%;
		display: block;
		border: 1px solid rgba(167, 180, 201, 0.2);
		border-radius: 50%;
		font-size: 10px;
		line-height: 24px;
		width: 24px;
		text-align: center;
		height: 24px;
		background: rgba(240, 243, 249, 0.6);
		z-index: 1000;

		&:hover {
			background: rgba(164, 166, 169, 0.9);
		}
	}
}

@media only screen and (max-width: 767px) {
	#user-profile {
		.profile-user-info .profile-user-details {
			.profile-user-details-label {
				float: none;
				position: relative;
				text-align: left;
			}

			.profile-user-details-value {
				margin-left: 0;
			}
		}

		.profile-social {
			margin-top: 20px;
		}
	}
}

@media only screen and (max-width: 420px) {
	#user-profile {
		.profile-header .edit-profile {
			display: block;
			position: relative;
			margin-bottom: 15px;
		}

		.profile-message-btn .btn {
			display: block;
		}
	}
}

.main-box {
	background: $white;
	-webkit-box-shadow: 1px 1px 2px 0 #CCCCCC;
	-moz-box-shadow: 1px 1px 2px 0 #CCCCCC;
	-o-box-shadow: 1px 1px 2px 0 #CCCCCC;
	-ms-box-shadow: 1px 1px 2px 0 #CCCCCC;
	box-shadow: 1px 1px 2px 0 #CCCCCC;
	margin-bottom: 16px;
	padding: 20px;

	h2 {
		margin: 0 0 15px -20px;
		padding: 5px 0 5px 20px;
		border-left: 10px solid #c2c2c2;

		/*7e8c8d*/
	}
}

/* CONVERSATION */

.conversation-inner {
	padding: 0 0 5px 0;
	margin-right: 10px;
}

.conversation-item {
	padding: 5px 0;
	position: relative;
}

.conversation-user {
	width: 50px;
	height: 50px;
	overflow: hidden;
	float: left;
	border-radius: 50%;
	margin-top: 6px;
}

.conversation-body {
	background: #f5f5f5;
	font-size: 0.875em;
	width: auto;
	margin-left: 60px;
	padding: 8px 10px;
	position: relative;

	&:before {
		border-color: transparent #f5f5f5 transparent transparent;
		border-style: solid;
		border-width: 6px;
		content: "";
		cursor: pointer;
		left: -12px;
		position: absolute;
		top: 25px;
	}
}

.conversation-item.item-right {
	.conversation-body {
		background: #efeef1;

		&:before {
			border-color: transparent transparent transparent #efeef1;
			left: auto;
			right: -12px;
		}
	}

	.conversation-user {
		float: right;
	}

	.conversation-body {
		margin-left: 0;
		margin-right: 60px;
	}
}

.conversation-body> {
	.name {
		font-weight: 600;
		font-size: 1.125em;
	}

	.time {
		position: absolute;
		font-size: 0.875em;
		right: 10px;
		top: 0;
		margin-top: 10px;
		color: #605f5f;
		font-weight: 300;

		&:before {
			content: "\f017";
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			margin-top: 4px;
			font-size: 0.875em;
		}
	}

	.text {
		padding-top: 6px;
	}
}

.conversation-new-message {
	padding-top: 10px;
}

textarea.form-control {
	height: auto;
}

.search-element {
	position: relative;
}

@media (max-width: 575.98px) {
	.dash1 {
		margin-bottom: 1rem;
	}

	.dash-font p {
		font-size: 9px;
	}

	.dash-font1 {
		font-size: 12px;
	}

	.chart-toolbar .apexcharts-toolbar {
		top: -24px;
	}

	.dash-space .col {
		margin-bottom: 1rem;
	}
}

.server i {
	width: 50px;
	height: 50px;
	font-size: 1.5rem;
	line-height: 50px;
	border-radius: 50%;
	text-align: center;
	position: relative;
}

.icon-size1 {
	font-size: 2.1rem !important;
}

.text-primary-gradient {
	text-transform: uppercase;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-transform: uppercase;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-secondary-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right, #ed6ea0 0%, #fb8d34 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-warning-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right, #f66b4e 0%, #fbc434 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-info-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-danger-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right, #f58c45 0%, #FF4B2B 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-success-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right, #21a544 0%, #62fb62 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-facebook-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right bottom, #1e3c72 0%, #3d6cbf 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-twitter-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-google-plus-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-pinterset-gradient {
	text-transform: uppercase;
	background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.sparkline_area {
	position: relative;
	bottom: -3px;
}

.highcharts-credits,
.highcharts-button,
.highcharts-data-label,
.highcharts-label {
	display: none;
}

.highcharts-data-label-connector {
	display: none !important;
}

#orders {
	position: relative;
	bottom: -10px;
	height: 102px !important;
	width: 100% !important;
}

#profit {
	position: relative;
	bottom: -13px;
	height: 111px !important;
}

#total-customers {
	position: relative;
	left: -11px;
}

#sales-revenue {
	position: relative;
	bottom: -13px;
}

#resolved-complaints {
	position: relative;
	bottom: -13px;
	left: -4px;
	height: 199px;
}

.overflow-hidden {
	overflow: hidden;
}

.card-img-holder i {
	font-size: 45px;
}

.widget-progress .progress {
	background-color: rgba(0, 0, 0, 0.1);
}

.bg-progress-white {
	background-color: #eaeceb;
}

.bg-image {
	// background: url(../images/photos/seigaiha.png);
}

.widget-card-1 i {
	position: relative;
	bottom: 1px;
	right: 11px;
	border-radius: 5px;
	font-size: 57px;
	color: $white;
	opacity: 0.5;

	&:hover {
		color: $white;
		font-size: 80px;
		position: absolute;
		bottom: -10px;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
}

.secondary-card {
	position: relative;
	bottom: 20px;
	border-radius: 0;
}

.dot-label {
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 100%;
	margin-right: 5px;
}

@media (max-width: 578px) {
	.m-portlet__head .nav-pills {
		.nav-link {
			font-size: .5rem;
			font-weight: 400;
			padding: .35rem 0.5rem;
		}

		.nav-item {
			margin-bottom: 0;
		}
	}

	.m-widget4 {
		.m-widget4__item {
			&:first-child {
				padding-top: 0 !important;
			}

			&:last-child {
				padding-bottom: 0 !important;
			}

			display: inline-grid !important;
			width: 100%;

			.m-widget4__img {
				text-align: center;
			}
		}

		&.m-widget4--progress {

			.m-widget4__info,
			.m-widget4__progress {
				width: 100% !important;
				text-align: center;
				margin-top: 10px;
			}
		}

		.m-widget4__item .m-widget4__ext {
			width: 100% !important;
			text-align: center;
		}
	}

	.m-widget17__progress-number,
	.m-widget17__progress-label {
		width: 50%;
	}
}

@media (max-width: 768px) {
	.m-widget4.m-widget4--progress .m-widget4__progress {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
		margin-bottom: 20px;
	}
}

.socailicons i {
	font-size: 126px;
	text-align: right;
	position: absolute;
	top: -21px;
	right: -29px;
	overflow: hidden;
	padding: 12px;
	border-radius: 0 0 0 34%;
	outline: none;
	color: #f1f6fb;
	text-shadow: none;
	z-index: 0;
}

.social-content {
	position: relative;
	z-index: 99;
}

.google-plus1 {
	background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
}

.pinterest1 {
	background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
}

@media (max-width: 992px) and (min-width: 768px) {
	#resolved-complaints {
		height: 190px !important;
	}

	#total-coversations {
		height: 168px !important;
	}
}

@media (max-width: 1279px) and (min-width: 993px) {
	#resolved-complaints {
		height: 190px !important;
	}

	#total-coversations {
		height: 171px !important;
	}
}

.icon-icon-shape {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	text-align: center;

	i {
		font-size: 24px;
		line-height: 2;
	}
}

.settings {
	content: "\e8c3";
	top: -7px;
	font-size: 18px;
	position: absolute;
	right: 4px;
	font-family: "feather";
	z-index: 1029;
	line-height: 1;
	color: $white;
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	-webkit-animation: anim-rotate 1.5s linear infinite;
	animation: anim-rotate 1.5s linear infinite;
}

.setting-1 {
	content: "\62";
	top: 0;
	font-size: 120px;
	position: absolute;
	right: -45px;
	font-family: "pct";
	z-index: 1001;
	line-height: 0;
	color: #04a9f5;
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	text-shadow: 1px 0 2px rgba(4, 169, 245, 0.9), 3px 0 5px rgba(0, 0, 0, 0.1);
	letter-spacing: -44px;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.wideget-user-desc {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;

	.wideget-user-img img {
		border-radius: 100%;
		margin-right: 2rem;
	}

	.user-wrap {
		margin-top: 1.5rem;
	}
}

.wideget-user .wideget-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: 0.3rem;

	&:hover {
		color: $white;
	}
}

.wideget-user-info {
	.wideget-user-warap {
		display: flex;

		h4 {
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: 0.4rem;
		}

		.wideget-user-warap-r {
			margin-left: 10rem;
		}
	}

	.wideget-user-rating a {
		font-size: 18px;
	}
}

.wideget-user-tab .tab-menu-heading {
	padding: 0;
	border: 0;

	.nav li a {
		color: #374254;
		font-size: 15px;
		font-weight: 400;
	}
}

.usertab-list {
	display: inline-block;

	li {
		width: 50%;
		margin-bottom: 1rem;
		float: left;
	}
}

.wideget-user-tab .tabs-menu1 ul li a {
	padding: 14px 20px 14px 20px;
	display: block;
}

.widgets-cards .widgets-cards-data {
	margin-top: 8px;

	.wrp p:first-child {
		font-size: 20px;
		font-weight: 600;
	}
}

.info-box small {
	font-size: 14px;
}

.info-box-icon {
	display: block;
	float: left;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: rgba(0, 0, 0, 0.2);
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.info-box {
	display: block;
	min-height: 90px;
	background: $white;
	width: 100%;
	border-radius: 2px;
	margin-bottom: 15px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.info-box-icon>img {
	max-width: 100%;
}

.info-box-content {
	padding: 1.5rem 1.5rem;
	margin-left: 90px;
}

.info-box-number {
	display: block;
}

.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.info-box-more {
	display: block;
}

.memberblock {
	width: 100%;
	float: left;
	clear: both;
	margin-bottom: 15px;
}

.member {
	width: 100%;
	float: left;
	background: $white;
	border: 1px solid #e6eaea;
	padding: 3px;
	position: relative;
	overflow: hidden;
	text-decoration: none !important;
	margin-bottom: 10px;
	border-radius: 5px;
}

.memmbername {
	bottom: -30px;
	background: rgba(0, 0, 0, 0.9);
	color: $white;
	line-height: 30px;
	padding: 0 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: center;
	width: 100%;
	font-size: 11px;
	border-radius: 0 0 3px 3px;
}

.member {
	&:hover .memmbername {
		bottom: 0;
	}

	img {
		width: 100%;
		border-radius: 3px 3px 0 0;
	}
}

#particles-js {
	position: absolute;
	height: 100%;
	left: 0;
	right: 0;
	width: 100%;
	top: 0;
	bottom: 0;
}

.relative {
	position: relative !important;
}

.floating {
	animation: floating 3s ease infinite;
	will-change: transform;

	&:hover {
		animation-play-state: paused;
	}
}

.floating-lg {
	animation: floating-lg 3s ease infinite;
}

.floating-sm {
	animation: floating-sm 3s ease infinite;
}

@keyframes floating-lg {
	0% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(15px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes floating {
	0% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes floating-sm {
	0% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(5px);
	}

	100% {
		transform: translateY(0px);
	}
}

/*---about us---*/

.statistics-info .counter-icon {
	i {
		font-size: 1.2rem;
	}

	margin-bottom: 1rem;
	display: inline-flex;
	width: 4rem;
	height: 4rem;
	padding: 1.3rem 1.4rem;
	border-radius: 50%;
	text-align: center;
}

.service .item-box i {
	font-size: 35px;
}

.productdec {
	background: $background;
}

.productdesc {
	.list-unstyled li {
		border-bottom: 0 !important;
		margin-bottom: 18px;
	}

	.tab-content i {
		color: inherit;
	}
}

.productdesc-1 .list-unstyled li {
	border-bottom: 0 !important;
	margin-bottom: 5px;
}

.cart .input-group-prepend>.btn,
.input-group-append>.btn,
.input-group-btn>.btn {
	height: 100%;
	border-color: rgb(226, 167, 136);
}

.input-indec .input-group-btn>.btn {
	height: 100%;
	border-color: $border;
}

.gallery a img {
	float: left;
	height: auto;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transition: -webkit-transform .15s ease;
	-moz-transition: -moz-transform .15s ease;
	-o-transition: -o-transform .15s ease;
	-ms-transition: -ms-transform .15s ease;
	transition: transform .15s ease;
	position: relative;
}

.hover15 .card {
	position: relative;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		display: block;
		content: '';
		width: 0;
		height: 0;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 100%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		opacity: 0;
	}

	&:hover::before {
		-webkit-animation: circle .75s;
		animation: circle .75s;
	}
}

@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 1;
	}

	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}

@keyframes circle {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 1;
	}

	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}

.profile-1 .dropdown-menu-arrow:before {
	border-color: transparent transparent rgb(103, 15, 184) transparent !important;
}

.countdown span:first-child {
	font-size: 30px;
}

.top-footer {
	width: 100%;
	line-height: 1.25rem;
	opacity: 1;
	transform: translate(0px, 0px);
	font-size: 14px;
	flex: 0 0 auto;

	h6 {
		font-size: 14px;
		margin-bottom: 1rem;
		font-weight: 600;
	}

	p {
		color: #8c8ea9;
	}

	a {
		color: #8c8ea9;
		line-height: 2;
		color: #8c8ea9;
		line-height: 2;

		address {
			color: #8c8ea9;
			margin-bottom: 0;
			line-height: 2;
		}
	}

	img {
		width: 80px;
		border: 1px solid #e6eaea;
		padding: 2px;
		height: 80px;

		&:hover {
			color: #8e9090;
			opacity: .6;
		}
	}

	.list-unstyled li {
		border-bottom: 0;
	}
}

@media (max-width: 988px) {
	.note-toolbar {
		display: block !important;
	}
}

#smartwizard {
	.nav.nav-tabs.step-anchor {
		border-bottom: 0 !important;
		padding: 30px;
	}

	.nav-tabs {
		border-top: 0 !important;
	}

	.nav-link {
		padding: 10px 30px;
	}
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
	background: transparent;
}

#basicwizard .nav-tabs .nav-link {
	padding: .80rem .80rem;
}

@media only screen and (max-width: 1279px) and (min-width: 992px) {
	#orders {
		position: relative;
		bottom: -6px;
		height: 102px !important;
		width: 100% !important;
		left: -6px;
	}
}

@media screen and (max-width: 998px) and (min-width: 768px) {

	.note-popover .popover-content,
	.card-header.note-toolbar {
		padding: 14px 0 5px 5px !important;
		margin: 0;
		background: $background;
	}
}

@media (max-width: 495px) {
	.nested-media .media {
		display: block;
	}
}

.widget-drop {
	.dropdown-menu {
		position: absolute;
		transform: translate3d(-156px, 31px, 0px) !important;
		top: 0px;
		left: 0px;
		will-change: transform;
		min-width: 80px;
	}

	.button:focus {
		border-color: transparant !important;
	}
}

.border-right-1 {
	border-right: 1px solid #d5dce3;
}

.material-switch> {
	input[type="checkbox"] {
		display: none;
	}

	label {
		cursor: pointer;
		height: 0px;
		position: relative;
		width: 40px;

		&::before {
			background: #e1e2f1;
			box-shadow: inset 0px 0px 10px rgb(161, 181, 197);
			border-radius: 8px;
			content: '';
			height: 16px;
			margin-top: -8px;
			position: absolute;
			opacity: 0.3;
			transition: all 0.4s ease-in-out;
			width: 40px;
		}

		&::after {
			background: rgb(255, 255, 255);
			border-radius: 16px;
			box-shadow: 0px 0px 5px rgb(161, 181, 197);
			content: '';
			height: 24px;
			left: -4px;
			margin-top: -8px;
			position: absolute;
			top: -4px;
			transition: all 0.3s ease-in-out;
			width: 24px;
		}
	}

	input[type="checkbox"]:checked+label {
		&::before {
			background: inherit;
			opacity: 0.5;
		}

		&::after {
			background: inherit;
			left: 20px;
		}
	}
}

.absolute-image1 img {
	position: absolute;
	top: 190px;
	left: -300px;
	display: block;
	width: 50%;
	animation: floating 3s ease infinite;
	will-change: transform;
}

.absolute-image2 img {
	position: absolute;
	right: -300px;
	display: block;
	width: 50%;
	animation: floating 3s ease infinite;
	will-change: transform;
}

@media (max-width: 992px) {
	.absolute-image1 img {
		left: -50px;
	}

	.absolute-image2 img {
		right: -50px;
		top: 82px;
	}

	.page-main:after {
		border-radius: 0;
	}
}

.content-area {
	min-height: 70vh;
}

.notifications .dropdown-menu-arrow,
.message .dropdown-menu-arrow {
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		right: 15px;
		border-bottom: 10px solid rgba(211, 211, 224, 0.9);
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	}

	&:after {
		content: '';
		position: absolute;
		top: -8.5px;
		right: 16px;
		border-bottom: 9px solid $white;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
	}
}

.pulse {
	display: block;
	position: absolute;
	top: .2rem;
	right: .5rem;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #ff1a1a;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(255, 26, 26, 0.9);
	animation: pulse 2s infinite;

	&:hover {
		animation: none;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 26, 26, 0.9);
	}

	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(255, 26, 26, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 26, 26, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 26, 26, 0.9);
		box-shadow: 0 0 0 0 rgba(255, 26, 26, 0.7);
	}

	70% {
		-moz-box-shadow: 0 0 0 10px rgba(255, 26, 26, 0);
		box-shadow: 0 0 0 10px rgba(255, 26, 26, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 26, 26, 0);
		box-shadow: 0 0 0 0 rgba(255, 26, 26, 0);
	}
}

@media (min-width: 361px) and (max-width: 992px) {
	.page-header {
		display: block !important;

		.breadcrumb {
			right: 0;
			margin-bottom: 0.5rem;
		}
	}
}

/** Sticky styless **/
@media (min-width: 992px) {
	.sticky {
		width: 100%;
	}

	.sticky-pin {
		top: 0;
		width: 100%;
		z-index: 1;
	}
}

/*latetes timeline */

.latest-timeline .timeline {
	list-style-type: none;
	position: relative;
	padding: 0;

	&:before {
		content: ' ';
		background: $border;
		display: inline-block;
		position: absolute;
		left: 10px;
		width: 2px;
		height: 100%;
		z-index: 400;
	}

	.event-text {
		margin: 20px 0;
		padding-left: 2rem;

		&:before {
			content: ' ';
			background: white;
			display: inline-block;
			position: absolute;
			border-radius: 50%;
			border: 3px solid #7E6EE6;
			left: 1px;
			width: 20px;
			height: 20px;
			z-index: 400;
		}

		&:first-child:before {
			border: 3px solid #0dd157;
		}

		&:nth-child(2):before {
			border: 3px solid #3ec7e8;
		}

		&:nth-child(3):before {
			border: 3px solid #f35e90;
		}

		&:nth-child(4):before {
			border: 3px solid #fc7303;
		}

		&:last-child:before {
			border: 3px solid #ec2d38;
		}
	}
}

/*--end--*/

.card-over {
	position: relative;
	overflow: hidden;
	background: $white;
	margin-bottom: 0.8rem;
	border: 1px solid $border;
}

.card-over-data {
	position: absolute;
	width: 100%;
}

.icon-service {
	display: inline-flex;
	width: 40px;
	height: 40px;
	text-align: center;
	border-radius: 6px;
	align-items: center;
	justify-content: center;

	i {
		font-size: 1.1rem;
	}
}

/* background animations*/

.animation-zidex {
	z-index: 1000;
}

.circles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	li {
		position: absolute;
		display: block;
		list-style: none;
		width: 20px;
		height: 20px;
		background: rgba(255, 255, 255, 0.2);
		animation: animate 25s linear infinite;
		bottom: -150px;

		&:nth-child(1) {
			left: 25%;
			width: 40px;
			height: 40px;
			animation-delay: 0s;
		}

		&:nth-child(2) {
			left: 10%;
			width: 20px;
			height: 20px;
			animation-delay: 2s;
			animation-duration: 12s;
		}

		&:nth-child(3) {
			left: 70%;
			width: 20px;
			height: 20px;
			animation-delay: 4s;
		}

		&:nth-child(4) {
			left: 40%;
			width: 35px;
			height: 35px;
			animation-delay: 0s;
			animation-duration: 18s;
		}

		&:nth-child(5) {
			left: 65%;
			width: 20px;
			height: 20px;
			animation-delay: 0s;
		}

		&:nth-child(6) {
			left: 75%;
			width: 30px;
			height: 30px;
			animation-delay: 3s;
		}

		&:nth-child(7) {
			left: 35%;
			width: 35px;
			height: 35px;
			animation-delay: 7s;
		}

		&:nth-child(8) {
			left: 50%;
			width: 25px;
			height: 25px;
			animation-delay: 15s;
			animation-duration: 45s;
		}

		&:nth-child(9) {
			left: 20%;
			width: 15px;
			height: 15px;
			animation-delay: 2s;
			animation-duration: 35s;
		}

		&:nth-child(10) {
			left: 85%;
			width: 35px;
			height: 35px;
			animation-delay: 0s;
			animation-duration: 11s;
		}
	}
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

/* Header logo*/
@media (max-width: 320px) {

	.message,
	.notifications {
		display: none;
	}
}

.sticky.sticky-pin .demo_changer {
	z-index: 10038;
}

@media (max-width: 992px) {
	.nav-link.icon.toggle i {
		font-size: 24px;
		margin-top: 9px;
		display: block;
	}
}

.onoffswitch2 {
	position: relative;
	width: 30px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.onoffswitch2-checkbox {
	display: none;
}

.onoffswitch2-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	height: 15px;
	padding: 0;
	line-height: 16px;
	border: 1px solid #CCCCCC;
	border-radius: 15px;
	background-color: $white;
	transition: background-color 0.3s ease-in;

	&:before {
		content: "";
		height: 16px;
		display: block;
		width: 17px;
		margin: 0px;
		background: $white;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 13px;
		border: 1px solid #CCCCCC;
		border-radius: 15px;
		transition: all 0.3s ease-in 0s;
	}
}

.onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
	right: 0px;
}

.switch_section {
	span {
		color: #212121 !important;
		font-size: 14px;
		font-weight: 500;
	}

	li {
		width: 100%;
		height: 30px;
	}

	width: 100%;
	height: auto;
	list-style: none;
	margin: 0 auto;
	margin: 0.8rem 0 0.8rem 0;
}

.switch-toggle {
	margin: 4px 5px;
}

.error {
	color: #ff0000;
}

input {
	&.error {
		border: 1px solid #ff0000 !important;
	}

	&.valid {
		border: 1px solid #4ecc48 !important;
	}

	&[type="search"] {

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			-webkit-appearance: none;
		}
	}

	&[type=search] {

		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&[type=text]::-ms-clear {
		display: none;
	}

	&::-webkit-input-placeholder {
		color: $white-8
	}
}

*::-ms-backdrop,
.card-body {
	height: 100%;
}

*::-ms-backdrop,
.breadcrumb-3 li,
*::-ms-backdrop,
.breadcrumb-4 li,
*::-ms-backdrop,
.breadcrumb-5 li,
*::-ms-backdrop,
.breadcrumb-1 li,
*::-ms-backdrop,
.breadcrumb-2 li,
*::-ms-backdrop,
.breadcrumb-6 li {
	display: inline-block;
}

*::-ms-backdrop,
.media-list .mediaicon {
	margin-right: 1.5rem !important;
}

*::-ms-backdrop,
.chat-persons {
	height: 100%;
}

*::-ms-backdrop,
.full-screen-link.icon.nav-link {
	display: none;
}

*::-ms-backdrop,
.incoming-chart {
	height: 300px !important;
}

*::-ms-backdrop,
.form-control {
	padding: 0.575rem 0.75rem;
	line-height: 1.3;
}

*::-ms-backdrop,
.input100:focus+.focus-input100+.symbol-input100 {
	background: transparent !important;
}

*::-ms-backdrop,
.card-img-top.image-ie {
	height: 330px;
}

.sidetab-menu {
	p,
	.list-group-item {
		color: $color;
	}
}

hr:not([size]) {
    height: 0px;
    opacity: 1;
}

.login-img {
    .header-setting-icon {
        position: absolute;
        left: auto;
        right: 30px;
        top: 50px;
        width: auto;
        z-index: 99;
    }
}

@media (max-width: 767px) {
    .login-img .header-setting-icon {
        left: auto;
        right: 20px;
        top: 5px;
    }
}



// ratings styles //

.rating-stars i {
    padding: 5px 8px;
}

.rating-stars {
    display: inline-block;
    cursor: pointer;
    color: #ebeefb;
    font-size: 30px !important;
}

.jq-stars {
    display: inline-block;
}

.jq-rating-label {
    font-size: 22px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-family: helvetica, arial, verdana;
}

.jq-star {
    width: 100px;
    height: 100px;
    display: inline-block;
    cursor: pointer;
}

.jq-star-svg {
    padding-left: 0;
    width: 100%;
    height: 100%;

    path {
        stroke-linejoin: round;
    }
}

.jq-shadow {
    -webkit-filter: drop-shadow(-2px -2px 2px #888);
    filter: drop-shadow(-2px -2px 2px #888);
}

// ratings styles //

// handle-counter //

.handle-counter {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.handle-counter .btn {
    padding: 7px 10px !important;
}

.handle-counter input {
    float: left;
    text-align: center;
    height: 41px;
    border-radius: 0;
    width: 70px;
    border: 1px solid $border;
    outline: none;
    border-left: 0;
    border-right: 0;
}

.handle-counter .counter-minus,
.handle-counter .handle-counter .counter-plus {
    float: left;
    text-align: center;
    border-radius: 1px;
}

.handle-counter .counter-minus {
    border-radius: 3px 0px 0px 3px !important;
}

.handle-counter .counter-plus {
    border-radius: 0px 3px 3px 0px !important;
}

.counter-minus {
    border-radius: 0px 0 0 0px !important;
}

.counter-plus {
    border-radius: 0 0px 0px 0 !important;
}

// handle-counter //

.map svg {
	// height: 400px;
}

@media (min-width: 1200px) {
	.mapcontainer .map, .mapcontainer1 .map {
		width: 400px;
		margin: 0 auto;
		height: 400px;
	}
	.mapcontainer4 .map {
		width: 700px;
		margin: 0 auto;
	}
}

@media (max-width: 1199px) {
	.mapcontainer .map, .mapcontainer1 .map {
		margin: 0 auto;
		height: 50%;
		width: 50%;
	}
	.mapcontainer4 .map {
		margin: 0 auto;
	}
}

.mapael .map {
    margin-bottom: 10px;
    position: relative;
}

.card-pay {
	.tab-content i {
		color: inherit;
	}
}

.card-pay .tabs-menu {
    margin-bottom: 25px;
    border-radius: 5px;
    overflow: hidden;

    li {
        width: 33.3%;
        display: block;

        a {
            padding: 0.5rem 1rem;
            background: #f3f4f7;
            display: block;
            text-align: center;

            // border-right: 1px solid $border;
            &.active {
                background: $primary !important;
                color: #fff !important;
                text-align: center;
                svg {
                  fill: $white;
                }
            }
        }

        &:last-child a {
            border-right: 0;
        }
    }
}

@media (max-width: 576px) {
    .card-pay .tabs-menu li {
        width: 100%;
    }
}

.file-manger-icon img {
    width: 70px;
    height: 70px;
}

.file-manger-icon i {
    font-size: 70px;
    margin: 0 auto;
    text-align: center;
}

#lightgallery.list-unstyled li {
    margin: 0;
    border: 0;
}

.feature .border {
    position: relative;
    display: inline-block;
    width: 3em;
    height: 3em;
    line-height: 2em;
    vertical-align: middle;
    padding-top: 8px;
    border-radius: 50%;
    color: #fff;
}

.users.store {
	.rating-stars i {
		padding: 0;
	}
}

.col-login {
	.logo-light {
		display: none;
	}
	.logo-dark {
		display: inline-block;
	}
}

hr:not([size]) {
	height: 1px;
	opacity: 0.15;
}

.recent-activity {
	display: flex;
    position: relative;
    min-height: 61px;
    &::before {
		content: "";
		position: absolute;
		top: 40px;
		bottom: -2px;
		left: 18px;
		border-left: 2px dotted $border;
   	}
	&:last-child {
		min-height: 40px;
		&::before {
			display: none;
		}
	}
}

.dataTables_length {
	.select2-container .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 2rem !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 2.5 !important;
	}
}

.task-list li .task-icon {
    position: absolute;
    left: 10px;
    top: 5px;
    border-radius: 50%;
    padding: 2px;
    width: 12px;
    height: 12px;
    z-index: 2;
    transition: all ease 0.2s;
}

.task-list {
    position: relative;

	li {
		position: relative;
		min-height: 71px;
		padding-left: 55px;

		&:last-child {
			min-height: 45px;
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 9px;
		bottom: 0;
		height: 88%;
		left: 15px;
		border-left: 1px solid $border;
	}

}
#countrysales {
	min-height: 300px !important;
	margin: 0 auto;
}
.product-summary {
	tbody td img{
		width: 50px;
	}
}

@media (max-width: 767px) {
	.product-name {
		display: none;
	}
}

.circle-chart {
	width: 50px;
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;
}

.circle-chart strong {
	position: absolute;
	top: 5px;
	left: 4px;
	width: 100%;
	text-align: center;
	line-height: 3.5;
	font-size: 13px;
	font-weight: 600;
}

.todolist {
	.custom-controls-stacked .custom-control {
		margin-bottom: 20px;
		.custom-control-label {
			font-size: 14px;
		}
	}
}
.deals, .leaderboard {
	&.table td {
		padding: 1rem;
	}
}
.apexcharts-canvas, .apexcharts-canvas svg{
	width: 100% !important;
}

.apexcharts-toolbar {
	z-index: 0 !important;
}

.acc-step-number {
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
}

.about-icons {
    height: 40px;
    width: 40px;
}

.apexcharts-text tspan, .apexcharts-legend-text {
	font-family: "Mulish", sans-serif !important;
}

#newusers {
	width: 100px;
}

.coloured-navigation {
	.nav-link:hover, .nav-link:focus {
		color: $white !important;
	}
}
.hor-content {
    min-height: calc(100vh - 132px) !important;
}

.productdesc-1 {
	.carousel-control-prev-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
		opacity: 0.3;
	}
	.carousel-control-next-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
		opacity: 0.3;
	}
}

.layout-boxed {
	#AreaChart1, #AreaChart2, #AreaChart3, #AreaChart4 {
		width: 143% !important;
	}
}

