/*------ Card -------*/

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	border: 1px solid $border;
	background-clip: border-box;
	box-shadow: none;
	border-radius: 3px;

	> {
		hr {
			margin-right: 0;
			margin-left: 0;
		}

		.list-group {
			&:first-child .list-group-item:first-child {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}

			&:last-child .list-group-item:last-child {
				border-bottom-right-radius: 7px;
				border-bottom-left-radius: 7px;
			}
		}
	}
}

.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.5rem;
}

.card-title {
	margin-bottom: 1.5rem;
}

.card-subtitle {
	margin-top: -0.75rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link {
	&:hover {
		text-decoration: none;
	}
}

.card-header {
	padding: 1.5rem 1.5rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid $border;

	&:first-child {
		border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
	}

	+.list-group .list-group-item:first-child {
		border-top: 0;
	}
}

.card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid $border;
}

.card-header-tabs {
	margin-right: -0.75rem;
	margin-bottom: -1.5rem;
	margin-left: -0.75rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -0.75rem;
	margin-left: -0.75rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}

.card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
}

.card-img-top {
	width: 100%;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
}

.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(3px - 1px);
	border-bottom-left-radius: calc(3px - 1px);
}

.card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;

	.card {
		margin-bottom: 0.75rem;
	}
}

@media (min-width: 576px) {
	.card-deck {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-right: -0.75rem;
		margin-left: -0.75rem;

		.card {
			display: -ms-flexbox;
			display: flex;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-right: 0.75rem;
			margin-bottom: 0;
			margin-left: 0.75rem;
		}
	}
}

.card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;

	>.card {
		margin-bottom: 0.75rem;
	}
}

@media (min-width: 576px) {
	.card-group {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;

		>.card {
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-bottom: 0;

			+.card {
				margin-left: 0;
				border-left: 0;
			}

			&:first-child {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;

				.card-img-top,
				.card-header {
					border-top-right-radius: 0;
				}

				.card-img-bottom,
				.card-footer {
					border-bottom-right-radius: 0;
				}
			}

			&:last-child {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;

				.card-img-top,
				.card-header {
					border-top-left-radius: 0;
				}

				.card-img-bottom,
				.card-footer {
					border-bottom-left-radius: 0;
				}
			}

			&:only-child {
				border-radius: 3px;

				.card-img-top,
				.card-header {
					border-top-left-radius: 3px;
					border-top-right-radius: 3px;
				}

				.card-img-bottom,
				.card-footer {
					border-bottom-right-radius: 3px;
					border-bottom-left-radius: 3px;
				}
			}

			&:not(:first-child):not(:last-child):not(:only-child) {
				border-radius: 0;

				.card-img-top,
				.card-img-bottom,
				.card-header,
				.card-footer {
					border-radius: 0;
				}
			}
		}
	}
}

.card-columns .card {
	margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;

		.card {
			display: inline-block;
			width: 100%;
		}
	}
}

.card-blog-overlay {
	background: url('../images/thumbnails/thumb2.jpg');
	background-size: cover;
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.card-blog-overlay1:before,
.card-blog-overlay2:before {
	content: '';
	display: block;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
}

.card-blog-overlay1 {
	background: url('../images/thumbnails/thumb1.jpg');
	background-size: cover;
}

.card-blog-overlay2 {
	background: url('../images/photos/22.jpg');
	background-size: cover;
}

/*------ Default Card Values -------*/

.card {
	position: relative;
	margin-bottom: 0.8rem;
	width: 100%;

	.card {
		border: 1px solid $border;
	}
}

@media print {
	.card {
		box-shadow: none;
		border: none;
	}
}

.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 1.5rem 1.5rem;
	position: relative;

	+.card-body {
		border-top: 1px solid $border;
	}
}

.tilebox-one i {
	font-size: 62px;
}

.card-body> :last-child {
	margin-bottom: 0;
}

@media print {
	.card-body {
		padding: 0;
	}
}

.card-body-scrollable {
	overflow: auto;
}

.card-footer,
.card-bottom {
	padding: 1rem 1.5rem;
	background: none;
}

.card-footer {
	border-top: 1px solid $border;
	color: inherit;
}

.card-header {
	background: none;
	padding: 0.5rem 1.5rem;
	display: -ms-flexbox;
	display: flex;
	min-height: 3.5rem;
	-ms-flex-align: center;
	align-items: center;

	.card-title {
		margin-bottom: 0;
	}

	&.border-0+.card-body {
		padding-top: 0;
	}
}

@media print {
	.card-header {
		display: none;
	}
}

.card-img-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}

.card-title {
	font-size: 1rem;
	line-height: 1.2;
	font-weight: 600;

	a {
		color: inherit;
	}

	&:only-child {
		margin-bottom: 0;
	}

	small {
		color: #9aa0ac;
		font-size: 0.875rem;
		display: block;
		margin: -.75rem 0 1rem;
		line-height: 1.1;
		font-weight: 400;
	}
}

.card-subtitle {
	color: #9aa0ac;
	font-size: 0.875rem;
	display: block;
	margin: -.75rem 0 1rem;
	line-height: 1.1;
	font-weight: 400;
}

.card-table {
	margin-bottom: 0 !important;

	tr {
		&:first-child {

			td,
			th {
				border-top: 0;
			}
		}

		td:first-child,
		th:first-child {
			padding-left: 1.5rem;
		}

		td:last-child,
		th:last-child {
			padding-right: 1.5rem;
		}
	}
}

.card-body+.card-table {
	border-top: 1px solid $border;
}

.card-profile .card-header {
	height: 5rem;
	background-size: cover;
	border: 0;
}

.card-profile-img {
	max-width: 6rem;
	margin-bottom: 1rem;
	border: 3px solid #fff;
	border-radius: 100%;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.card-link+.card-link {
	margin-left: 1rem;
}

.card-body+.card-list-group {
	border-top: 1px solid $border;
}

.card-list-group .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	&:last-child {
		border-bottom: 0;
	}

	&:first-child {
		border-top: 0;
	}
}

.card-header-tabs {
	margin: -1.25rem 0;
	border-bottom: 0;
	line-height: 2rem;

	.nav-item {
		margin-bottom: 1px;
	}
}

.card-header-pills {
	margin: -.75rem 0;
}

.card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
}

.card-aside-column {
	min-width: 5rem;
	width: 30%;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	background: no-repeat center/cover;
}

.card-value {
	font-size: 1rem;
	line-height: 3.4rem;
	height: 3.4rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;

	i {
		vertical-align: middle;
		font-size: 30px;
	}
}

.card-chart-bg {
	height: 4rem;
	margin-top: -1rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.card-options {
	margin-left: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-right: -.5rem;
	color: #9aa0ac;
	-ms-flex-item-align: center;
	align-self: center;

	a:not(.btn) {
		margin-left: .5rem;
		color: #8c8ea9;
		display: inline-block;
		min-width: 1rem;

		&:hover {
			text-decoration: none;
			color: #6e7687;
		}

		i {
			font-size: 1rem;
			vertical-align: middle;
		}
	}

	.dropdown-toggle:after {
		display: none;
	}
}

.card-img-absolute {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	margin-left: -28px;
}

.card-image {
	position: absolute;
	top: 0;
	height: 100%;
	margin-left: -126px;
}

/*Card options*/

.card-collapsed {
	> :not(.card-header):not(.card-status) {
		display: none;
	}

	.card-options-collapse i:before {
		content: '\e92d';
	}
}

.card-fullscreen {
	.card-options-fullscreen i:before {
		content: '\e992';
	}

	.card-options-remove {
		display: none;
	}
}

/*Card maps*/

.card-map {
	height: 15rem;
	background: #e9ecef;
}

.card-map-placeholder {
	background: no-repeat center;
}

/**Card tabs*/

.card-tabs {
	display: -ms-flexbox;
	display: flex;
}

.card-tabs-bottom .card-tabs-item {
	border: 0;

	&.active {
		border-top-color: #fff;
	}
}

.card-tabs-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: block;
	padding: 1.4rem 1.5rem;
	color: inherit;
	overflow: hidden;
}

a.card-tabs-item {
	background: #fafbfc;

	&:hover {
		text-decoration: none;
		color: inherit;
	}

	&:focus {
		z-index: 1;
	}

	&.active {
		background: #fff;
		border-bottom-color: #fff;
	}
}

/**Card status*/

.card-status {
	position: absolute;
	top: -1px;
	left: 0px;
	right: 0px;
	height: 3px;
	border-radius: 7px 7px 0 0;
}

.card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 3px 0 0 3px;
}

/**Card icon*/

.card-icon {
	width: 3rem;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: center;
}

/**Card fullscreen*/

.card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	margin: 0;
}

/**Card alert*/

.card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
}

.card-category {
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: $background;
}

.card-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.card-aside-img {
	img {
		width: 80px;
		height: 80px;
		vertical-align: middle;
	}

	&.wrap-border img {
		border: 1px solid rgba(167, 180, 201, 0.3);
		padding: 3px;
	}

	position: relative;

	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.card-item-desc {
	padding: 15px;

	h6 {
		margin-top: 5px;
	}
}

.card-blogs .card {
	border: none;
	box-shadow: none;
}

.card-blogs1 {
	.card {
		box-shadow: none;
		border-bottom: 1px solid rgba(167, 180, 201, 0.3);
		padding-bottom: 15px;
		margin-bottom: 10px;

		&:last-child {
			box-shadow: none;
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	.card-item-desc .card-item-price {
		.newprice {
			font-weight: 600;
		}

		.oldprice {
			font-size: 14px;
		}
	}
}

.card-blogs2 .card {
	border: none;
	box-shadow: none;
}

.custom-header.card-header{
	padding:1.5rem 1.5rem;
}
.custom-header.card-header .card-title {
	margin-bottom: .9rem;
}
.card-bgimg{
	// background: url(../images/photos/bg.jpg) center center;
	background-size: cover;
	position:relative;
	border-radius:7px;
}
.card-bgimg:before{
	opacity: 0.9;
    border-radius: 7px;
    background: linear-gradient(125deg, #1753fc 35%, #9258f1 65%);
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: block;
    top: 0;
	z-index:1;
	border-radius:7px;
}
.card-bgimg .card-body{
	z-index:10;
	border-right:1px solid rgba(255,255,255,0.1);
}
