/*------ Accordion -------*/

.accordion .card {
	&:not(:first-of-type) {
	  &:not(:last-of-type) {
		border-bottom: 0;
		border-radius: 0;
	  }
  
	  .card-header:first-child {
		border-radius: 0;
	  }
	}
  
	&:first-of-type {
	  border-bottom: 0;
	  border-bottom-right-radius: 0;
	  border-bottom-left-radius: 0;
	}
  
	&:last-of-type {
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;
	}
  }
  
  /* #bs-collapse icon scale option */
  
  .panel-title a.accordion-toggle {
	&:before {
	  content: "\f068";
	  padding: 0 10px 0 0;
	  color: #fff;
	  font-family: FontAwesome;
	  float: right;
	}
  
	&.collapsed:before {
	  content: "\f067";
	  padding: 0 10px 0 0;
	  color: #fff;
	  font-family: FontAwesome;
	  float: right;
	}
  }
  
  .accordionjs .acc_section {
	&.acc_active > .acc_head {
	  color: #fff !important;
  
	  h3 {
		color: #fff !important;
	  }
	}
  
	z-index: 0 !important;
  }
  
  .accordion-wizard .badge-pill {
	padding-right: .6em;
	padding-left: .6em;
	// border-radius: 0;
  }

  
/* #accordion rotate icon option */

#accordion .panel-heading1 a:before {
	content: "\f01a";
	font-size: 24px;
	position: absolute;
	font-family: 'FontAwesome';
	right: 5px;
	top: 10px;
	transform: rotate(180deg);
	transition: all 0.5s;
}
#accordion .panel-heading1.active a:before {
	transform: rotate(0deg);
	transition: all 0.5s;
}