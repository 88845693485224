/*----- Custom control -----*/

.custom-control {
	position: relative;
	display: block;
	min-height: 1.2rem;
	padding-left: 1.5rem;
}

.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem;
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;

	&:checked~.custom-control-label::before {
		color: #fff;
	}

	&:focus~.custom-control-label::before {
		box-shadow: 0 0 0 1px $background, 0 0 0 2px rgba(98, 58, 162, 0.25);
	}

	&:active~.custom-control-label::before {
		color: #fff;
		background-color: rgba(218, 201, 232, 0.5);
	}

	&:disabled~.custom-control-label {
		color: #868e96;

		&::before {
			background-color: #e6e6f5;
			opacity: 0.5;
		}
	}
}

.custom-control-label {
	margin-bottom: 0;

	&::before {
		position: absolute;
		top: 0.15rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: $border;
	}

	&::after {
		position: absolute;
		top: 0.15rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	padding: 0.5rem 1.75rem 0.5rem 0.75rem;
	line-height: 1.5;
	color: #8c8ea9;
	vertical-align: middle;
	background: #e1e2f1 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
	background-size: 8px 10px;
	border: 1px solid $border;
	border-radius: 3px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&:focus {
		outline: 0;
		box-shadow: none;

		&::-ms-value {
			color: #495057;
			background-color: #fff;
		}
	}

	&[multiple],
	&[size]:not([size="1"]) {
		height: auto;
		padding-right: 0.75rem;
		background-image: none;
	}

	&:disabled {
		color: #868e96;
		background-color: #e9ecef;
	}

	&::-ms-expand {
		opacity: 0;
	}
}

.custom-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 75%;
}

.custom-select-lg {
	height: calc(2.6875rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 125%;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 2.375rem;
	margin: 0;
	opacity: 0;

	&:focus~.custom-file-label {
		border-color: #1991eb;
		box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);

		&::after {
			border-color: #1991eb;
		}
	}

	&:lang(en)~.custom-file-label::after {
		content: "Browse";
	}
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 0;
	height: 2.375rem;
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	color: #495057;
	background-color: #e1e2f1;
	border: 1px solid $border;
	border-radius: 3px;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(2.375rem - 1px * 2);
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		color: #fff;
		content: "Browse";
		border-left: 1px solid $border;
		border-radius: 0 3px 3px 0;
	}
}

.custom-range {
	width: 100%;
	padding-left: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&:focus {
		outline: none;
	}

	&::-moz-focus-outer {
		border: 0;
	}

	&::-webkit-slider-thumb {
		width: 1rem;
		height: 1rem;
		margin-top: -0.25rem;
		border: 0;
		border-radius: 1rem;
		-webkit-appearance: none;
		appearance: none;

		&:focus {
			outline: none;
			box-shadow: 0 0 0 1px $background, 0 0 0 2px rgba(98, 58, 162, 0.25);
		}

		&:active {
			background-color: #d4e1f4;
		}
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: $border;
		border-color: transparent;
		border-radius: 1rem;
	}

	&::-moz-range-thumb {
		width: 1rem;
		height: 1rem;
		border: 0;
		border-radius: 1rem;
		-moz-appearance: none;
		appearance: none;

		&:focus {
			outline: none;
			box-shadow: 0 0 0 1px $background, 0 0 0 2px rgba(98, 58, 162, 0.25);
		}

		&:active {
			background-color: #d4e1f4;
		}
	}

	&::-moz-range-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: $border;
		border-color: transparent;
		border-radius: 1rem;
	}

	&::-ms-thumb {
		width: 1rem;
		height: 1rem;
		border: 0;
		border-radius: 1rem;
		appearance: none;

		&:focus {
			outline: none;
			box-shadow: 0 0 0 1px $background, 0 0 0 2px rgba(98, 58, 162, 0.25);
		}

		&:active {
			background-color: #d4e1f4;
		}
	}

	&::-ms-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: transparent;
		border-color: transparent;
		border-width: 0.5rem;
	}

	&::-ms-fill-lower {
		background-color: $border;
		border-radius: 1rem;
	}

	&::-ms-fill-upper {
		margin-right: 15px;
		background-color: $border;
		border-radius: 1rem;
	}
}

.custom-control {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-controls-stacked .custom-control {
	margin-bottom: .25rem;
}

.custom-control-label {
	vertical-align: middle;

	&:before {
		border: 1px solid $border;
		background-color: #e6e6f5;
		background-size: .5rem;
	}
}

.custom-control-description {
	line-height: 1.5rem;
}

*::-ms-backdrop,
.custom-control-label {
	padding-left: 1.8rem;
}

*::-ms-backdrop,
.custom-control {
	padding-left: 0;
}



.custom-control-label-md {
	padding-left: 15px;
	padding-top: 6px;
	display: block;
	&::before, &::after {
		top: 0.25rem !important;
		width: 1.5rem !important;
		height: 1.5rem !important;
	}
}
.custom-control-md {
	min-height: 2rem !important;
}
.custom-control-lg {
	min-height: 2.5rem;
}
.custom-control-label-lg {
	padding-left: 15px;
	padding-top: 10px;
	display: block;
	&::before, &::after {
		top: 0.25rem !important;
		width: 2rem !important;
		height: 2rem !important;
	}
}

