.select2-container--default {
	.select2-selection--single {
		background-color: #fff;
		border: 1px solid $border !important;
	}

	&.select2-container--focus .select2-selection--multiple {
		color: #8c8ea9;
		background-color: #ffffff !important;
		outline: 0;
		box-shadow: none;
	}
}

.select2-selection--multiple:placeholder,
.select2-container .select2-search--inline .select2-search__field {
	color: #8c8ea9;
}

.select2-container--default {
	.select2-selection--multiple {
		background-color: white;
		border: 1px solid $border !important;
		cursor: text;
	}

	.select2-search--dropdown .select2-search__field {
		border: 1px solid $border !important;
	}

	.select2-selection--multiple {

		.select2-selection__choice,
		.select2-selection__choice__remove {
			color: #fff !important;
		}
	}

	.select2-results>.select2-results__options {
		box-shadow: none;
	}
}

.select2-container .select2-selection--single {
	height: 2.375rem !important;
}

.select2-container--default .select2-selection--single {
	.select2-selection__rendered {
		color: #444;
		line-height: 35px !important;
	}

	.select2-selection__arrow {
		height: 2.375rem !important;
	}
}