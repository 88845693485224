// Boxed Styles //

@media (min-width: 1400px) {
    body.layout-boxed {
      background: $background;
      .page {
        width: 1400px;
        margin: 0 auto;
        background: $background;
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 100vh;
        box-shadow: $black-3 0px 5px 0px 0px, $black-3 0px 5px 25px 0px;
        overflow: hidden;
      }
      &.horizontal .hor-header .container,
      &.horizontal .horizontal-main .container,
      &.horizontal .main-content.hor-content .container {
        max-width: 95% !important;
      }
      .sticky-pin .horizontal-main {
        max-width: 1400px;
        margin: 0;
      }
      .app-header {
        max-width: 1400px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 0;
      }
      .main-content .container,
      .app-header > .container,
      .horizontal-mainwrapper.container {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
      .horizontalMenu > .horizontalMenu-list > li > a {
        padding: 15px 11px 15px 11px;
      }
      .page.bg-primary-transparent,
      .main-error-wrapper.page {
        background: $primary !important;
      }
      .app-sidebar {
        left: auto !important;
      }
      .main-content-left-chat .main-nav-line-chat .nav-link + .nav-link {
        margin-left: 0;
      }
      .main-nav-line-chat {
        padding: 0 17px;
      }
      .app-sidebar .main-sidebar-header {
        left: auto;
        right: auto;
      }
      &.dark-mode {
        background: $dark-body !important;
        .page {
          background: transparent;
          box-shadow: $white-1 0px 5px 0px 0px, $white-1 0px 5px 25px 0px;
        }
      }
    }
}

// Scroll-layout Styles //

@media (max-width: 991px) {
    .scrollable-layout .responsive-navbar.navbar {
      .navbar-collapse {
        position: absolute !important;
      }

      position: inherit !important;
    }
}
@media (min-width: 992px) {
    .horizontal.scrollable-layout .app-sidebar {
        position: relative !important;
        max-width: 100% !important;
    }

    .horizontal.scrollable-layout .app-header {
        position: relative !important;
        max-width: 100% !important;
    }
}
.scrollable-layout {
    .app-header {
        position: absolute !important;
        max-width: 100% !important;
    }

    .app-sidebar,
    .main-sidebar-header {
        position: absolute;
    }

    .page {
        position: relative;
    }

    @media (min-width: 992px) {
      &.horizontal .sticky.sticky-pin {
          position: inherit;
      }
    }

    &.double-menu, &.double-menu-tabs {
      @media (min-width: 992px) {
          &.app .app-sidebar {
              position: absolute !important;
          }
      }
    }
}
