/*----- Range slider -------*/

.range {
	display: table;
	position: relative;
	height: 25px;
	margin-top: 20px;
	background-color: rgb(245, 245, 245);
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;

	input[type="range"] {
		-webkit-appearance: none !important;
		-moz-appearance: none !important;
		-ms-appearance: none !important;
		-o-appearance: none !important;
		appearance: none !important;
		display: table-cell;
		width: 100%;
		background-color: transparent;
		height: 25px;
		cursor: pointer;

		&::-webkit-slider-thumb,
		&::-moz-slider-thumb {
			-webkit-appearance: none !important;
			-moz-appearance: none !important;
			-ms-appearance: none !important;
			-o-appearance: none !important;
			appearance: none !important;
			width: 11px;
			height: 25px;
			color: rgb(255, 255, 255);
			text-align: center;
			white-space: nowrap;
			vertical-align: baseline;
			border-radius: 0px;
			background-color: rgb(153, 153, 153);
		}
	}

	output {
		display: table-cell;
		padding: 3px 5px 2px;
		min-width: 40px;
		color: rgb(255, 255, 255);
		background-color: rgb(153, 153, 153);
		text-align: center;
		text-decoration: none;
		border-radius: 4px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		width: 1%;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		transition: all 0.5s ease;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: -moz-none;
		-o-user-select: none;
		user-select: none;
	}

	input[type="range"] {
		outline: none;
	}

	&.range-success {
		input[type="range"] {

			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: #4ecc48;
			}
		}

		output {
			background-color: #4ecc48;
		}

		input[type="range"] {
			outline-color: #4ecc48;
		}
	}

	&.range-info {
		input[type="range"] {

			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: #45aaf2;
			}
		}

		output {
			background-color: #45aaf2;
		}

		input[type="range"] {
			outline-color: #45aaf2;
		}
	}

	&.range-warning {
		input[type="range"] {

			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: #ecb403;
			}
		}

		output {
			background-color: #ecb403;
		}

		input[type="range"] {
			outline-color: #ecb403;
		}
	}

	&.range-danger {
		input[type="range"] {

			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: #c21a1a;
			}
		}

		output {
			background-color: #c21a1a;
		}

		input[type="range"] {
			outline-color: #c21a1a;
		}
	}
}