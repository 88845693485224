/*-----Lists-----*/

.list-unstyled {
	padding-left: 0;
	list-style: none;

	li {
		border-bottom: 1px solid $border;
	}
}

.nav-sidebar .list-unstyled li:last-child {
	border-bottom: 0px;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-right: 0.5rem;
	}
}

.list-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}

.list-group-item-action {
	width: 100%;
	color: $color;
	text-align: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		background-color: $background;
	}

	&:active {
		color: #8c8ea9;
		background-color: $background;
	}
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	// margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border;
}

.listorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border;
	list-style-type: decimal;
	list-style-position: inside;
}

.listorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border;
	list-style-type: upper-alpha;
	list-style-position: inside;
}

.listunorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border;
	list-style-type: circle;
	list-style-position: inside;
}

.listunorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border;
	list-style-type: square;
	list-style-position: inside;
}

.list-group-item {
	&:first-child {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	&:last-child {
		margin-bottom: 0;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	&:hover,
	&:focus {
		z-index: 1;
		text-decoration: none;
	}

	&.disabled,
	&:disabled {
		color: #7c90b1;
		background-color: #fff;
	}

	&.active {
		z-index: 0;
		color: inherit;
		background-color: $background;
		border: 1px solid $border;
	}
}

.list-group-flush {
	.list-group-item {
		border-right: 0;
		border-left: 0;
		border-radius: 0;
	}

	&:first-child .list-group-item:first-child {
		border-top: 0;
	}

	&:last-child .list-group-item:last-child {
		border-bottom: 0;
	}
}

.list-group-item-primary {
	color: #24426c !important;
	background-color: #cbdbf2 !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #24426c;
			background-color: #b7cded;
		}

		&.active {
			color: #fff;
			background-color: #24426c;
			border-color: #24426c;
		}
	}
}

.list-group-item-secondary {
	color: #464a4e !important;
	background-color: #dddfe2 !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #464a4e;
			background-color: #cfd2d6;
		}

		&.active {
			color: #fff;
			background-color: #464a4e;
			border-color: #464a4e;
		}
	}
}

.list-group-item-success {
	color: #316100 !important;
	background-color: #d2ecb8 !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #316100;
			background-color: #c5e7a4;
		}

		&.active {
			color: #fff;
			background-color: #316100;
			border-color: #316100;
		}
	}
}

.list-group-item-info {
	color: #24587e !important;
	background-color: #cbe7fb !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #24587e;
			background-color: #b3dcf9;
		}

		&.active {
			color: #fff;
			background-color: #24587e;
			border-color: #24587e;
		}
	}
}

.list-group-item-warning {
	color: #7d6608 !important;
	background-color: #fbeebc !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #7d6608;
			background-color: #fae8a4;
		}

		&.active {
			color: #fff;
			background-color: #7d6608;
			border-color: #7d6608;
		}
	}
}

.list-group-item-danger {
	color: #6b1110 !important;
	background-color: #f1c1c0 !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #6b1110;
			background-color: #ecacab;
		}

		&.active {
			color: #fff;
			background-color: #6b1110;
			border-color: #6b1110;
		}
	}
}

.list-group-item-light {
	color: #818182 !important;
	background-color: #fdfdfe !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #818182;
			background-color: #ececf6;
		}

		&.active {
			color: #fff;
			background-color: #818182;
			border-color: #818182;
		}
	}
}

.list-group-item-dark {
	color: #1b1e21 !important;
	background-color: #c6c8ca !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #1b1e21;
			background-color: #b9bbbe;
		}

		&.active {
			color: #fff;
			background-color: #1b1e21;
			border-color: #1b1e21;
		}
	}
}

.list-media {
	.media-img {
		position: relative;
		float: left;
		width: 48px;
		margin-right: 20px;
	}

	.info {
		padding-left: 55px;
		min-height: 40px;
		height: auto;
		position: relative;

		h4 a {
			font-size: 16px;
			margin-top: 10px;
		}

		p {
			font-size: 13px;
		}

		.text-right {
			right: 0;
			color: #8a8a8a;
			top: 50%;
			font-size: 12px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			margin-top: -28px;
		}
	}
}

.list-inline-dots .list-inline-item+.list-inline-item:before {
	content: '· ';
	margin-left: -2px;
	margin-right: 3px;
}

.list-separated-item {
	padding: 1rem 0;

	&:first-child {
		padding-top: 0;
	}
}

.list-group-item {
	&.active .icon {
		color: inherit !important;
	}

	.icon {
		color: $color !important;
	}
}

.list-group-transparent .list-group-item {
	background: none !important;
	border: 0 !important;
	padding: .5rem 1rem;
	border-radius: 3px;

	&.active {
		background: #e6e6f5;
		font-weight: 600;
	}
}
