.custom-checkbox {
	.custom-control-label::before {
		border-radius: 0px;
	}

	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
		}

		&:indeterminate~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
		}

		&:disabled {
			&:checked~.custom-control-label::before {
				background-color: $primary-2;
			}

			&:indeterminate~.custom-control-label::before {
				background-color: rgba(212, 182, 228, 0.5);
			}
		}
	}
}

.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}

	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
		}

		&:disabled:checked~.custom-control-label::before {
			background-color: $primary-2;
		}
	}
}


.custom-switch-indicator-lg {
    display: inline-block;
    height: 1.5rem;
    width: 3rem;
    background: #f5f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #ebeaf1;
    transition: .3s border-color, .3s background-color;

    &:before {
        content: '';
        position: absolute;
        height: calc(1.5rem - 4px);
        width: calc(1.5rem - 4px);
        top: 1px;
        left: 1px;
        background: $white;
        border-radius: 50%;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 $black-4;
    }
}

.custom-switch-input:checked~.custom-switch-indicator-lg:before {
    left: calc(1.46rem + 1px);
}

.custom-switch-indicator-xl {
    display: inline-block;
    height: 2rem;
    width: 4rem;
    background: #f5f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #ebeaf1;
    transition: .3s border-color, .3s background-color;

    &:before {
        content: '';
        position: absolute;
        height: calc(2rem - 4px);
        width: calc(2rem - 4px);
        top: 1px;
        left: 1px;
        background: $white;
        border-radius: 50%;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 $black-4;
    }
}

.custom-switch-input:checked~.custom-switch-indicator-xl:before {
    left: calc(1.96rem + 1px) !important;
}