$background:#f0f0f9;
$color: #1c1d22;
$border: #e3e3f7;

/*primary color variables*/
$primary:var(--primary-bg-color);
$primary-hover:var(--primary-bg-hover);
$primary-border:var(--primary-bg-border);
$primary-shadow:var(--primary-shadow);
$primary-05: var(--primary-05);
$primary-1: var(--primary-1);
$primary-2: var(--primary-2);
$primary-3: var(--primary-3);
$primary-4: var(--primary-4);
$primary-5: var(--primary-5);
$primary-6: var(--primary-6);
$primary-7: var(--primary-7);
$primary-8: var(--primary-8);
$primary-9: var(--primary-9);

/*Color variables*/
$secondary: #0099ff;
$info: #26c2f7;
$success: #21c44c;
$warning: #ffb209;
$pink: #ec82ef;
$danger: #f5334f;
$blue: #4049ec;
$yellow: #ecb403;
$teal: #2bcbba;
$purple: #7f53ac;
$orange: #fc7303;
$dark: #1c1d22;
$indigo: #6574cd;
$white:#fff;
$black:#000;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-09:rgba(255, 255, 255, 0.09);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*dark-theme variables*/
$dark-body: var(--dark-body);
$dark-theme: var(--dark-theme);
$dark-border: var(--dark-border);
$dark-color: var(--dark-color);
$dark-shadow: var(--dark-shadow);
