.panel-body-landing:hover>.table>tbody>tr>td {
	color: #fff !important;
	-webkit-transition: all .3s ease;
}

.panel-heading-landing {
	background: #f7f7f7 !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px #1643A3 !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
}

.panel-heading-landing-box {
	background: #1643A3 !important;
	color: #fff !important;
	font-size: 16px !important;
	text-align: center;
	border-top: none !important;
}

.panel-title-landing {
	color: #1643A3 !important;
	font-size: 35px;
	font-weight: bold;
}

.panel-body-landing {
	border: solid 2px #1643A3 !important;
	border-top: none !important;
	border-bottom: none !important;
	text-align: center;
	background: #fff;
}

.panel-footer-landing {
	border: solid 2px #1643A3 !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-top: none !important;
	text-align: center;
	background: #f7f7f7;
}

.panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;

	>.panel-heading {
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		-webkit-transition: all .3s ease;
	}

	h3 {
		margin-bottom: 0;
		padding: 20px 0;
	}
}

.panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	background: #fff;
}

.panel.price {
	>.panel-heading {
		color: #fff;
	}

	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		&:first-child {
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
		}
	}

	margin-bottom: 1.5rem;
	box-shadow: 0px 22px 68px -9px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	overflow: hidden;

	&:hover {
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
	}
}

.price {
	.panel-footer {
		border-bottom: 0px;
		background-color: #fff;
		border-left: 0;
		border-right: 0;
	}

	&.panel-color>.panel-body {
		background-color: #fff;
	}
}

.panel-body {
	padding: 15px;

	.lead {
		strong {
			font-size: 40px;
			margin-bottom: 0;
		}

		font-size: 20px;
		margin-bottom: 0;
		padding: 10px 0;
	}
}

.panel-footer {
	padding: 10px 15px;
	background-color: #fff;
	border-top: 1px solid $border;
	border-left: 1px solid $border;
	border-right: 1px solid $border;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.panel-group .panel {
	border-radius: 3px;
	box-shadow: none;
	border: 1px solid $border;
}

.panel-default>.panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: $background;
	border: 0;
}

.panel-title {
	font-size: 14px;
	margin-bottom: 0;

	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}
}

.more-less {
	float: right;
	color: #212121;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 0px solid $border;
}

.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: #fff;
	background: none;
	box-shadow: none;

	&:last-child {
		border-bottom: none;
	}
}

.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}

	.panel1 {
		border-radius: 0;
	}
}

.panel-body1 {
	padding: 10px;
}

.panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
}

.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}

.panel-heading1 {
	background-color: $primary;
	border-radius: 0;
	border: none;
	color: #fff;
	padding: 0;
}

.panel-group1 .panel-body {
	border: 1px solid $border;
}

.panel-title1 a {
	display: block;
	color: #fff;
	padding: 10px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
}

.panel-body1 {
	background: #fff;
}

.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}

	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius 0.3s linear 0.2s;

		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}

.panel-heading1 {
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 7px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}

		&:before {
			content: "\e994";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 7px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
	}

	&.active a:before {
		content: ' ';
		transition: all 0.5s;
		transform: scale(0);
	}
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
	margin-bottom: 0;
}

#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all 0.5s;
	}

	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all 0.5s;
	}
}

.expanel-default {
	border: 1px solid $border !important;
}

.expanel-secondary {
	border: 1px solid $secondary !important;
}

.expanel-success {
	border: 1px solid $success !important;
}

.expanel-danger {
	border: 1px solid $danger !important;
}

.expanel-primary>.expanel-heading {
	color: #fff !important;
}

.expanel-secondary>.expanel-heading {
	color: #fff !important;
	background-color: $secondary !important;
	border-color: $secondary !important;
}

.expanel-success>.expanel-heading {
	color: #fff !important;
	background-color: $success !important;
	border-color: $success !important;
}

.expanel-danger>.expanel-heading {
	color: #fff !important;
	background-color: $danger !important;
	border-color: $danger !important;
}

.expanel-warning>.expanel-heading {
	color: #fff !important;
	background-color: $warning !important;
	border-color: $warning !important;
}

.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}

.expanel {
	margin-bottom: 20px !important;
	background-color: #fff !important;
	border: 1px solid $border;
	border-radius: 4px !important;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
}

.expanel-default>.expanel-heading {
	background-color: $background !important;
	border-color: $border !important;
}

.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $border;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.expanel-footer {
	padding: 10px 15px !important;
	background-color: $background !important;
	border-top: 1px solid $border !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.expanel-body {
	padding: 15px !important;
}