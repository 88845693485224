/* ###### variables ####### */
@import "variables";


/* ###### Plugins ####### */
@import "plugins/calendar/calendar";
@import "plugins/date-picker/date-picker";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/leaflet/leaflet";
@import "plugins/multipleselect//multiple-select";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/right-sidebar/right-sidebar";
@import "plugins/select2/select2.min";
@import "plugins/single-page/single-page";
@import "plugins/sweet-alert/sweetalert";
