.bg-success {
	background: $success !important;
}

a.bg-success {

	&:hover,
	&:focus {
		background-color: #15bf42 !important;
	}
}

button.bg-success {

	&:hover,
	&:focus {
		background-color: #15bf42 !important;
	}
}

.bg-info {
	background: $info !important;
}

a.bg-info {

	&:hover,
	&:focus {
		background-color: #1eb0e2 !important;
	}
}

button.bg-info {

	&:hover,
	&:focus {
		background-color: #1eb0e2 !important;
	}
}

.bg-warning {
	background: $warning !important;
}

a.bg-warning {

	&:hover,
	&:focus {
		background-color: #e0a325 !important;
	}
}

button.bg-warning {

	&:hover,
	&:focus {
		background-color: #e0a325 !important;
	}
}

.bg-danger {
	background: $danger !important;
}

a.bg-danger {

	&:hover,
	&:focus {
		background-color: #de223d !important;
	}
}

button.bg-danger {

	&:hover,
	&:focus {
		background-color: #de223d !important;
	}
}

.bg-light {
	background-color: $background !important;
}

a.bg-light {

	&:hover,
	&:focus {
		background-color: $background !important;
	}
}

button.bg-light {

	&:hover,
	&:focus {
		background-color: $background !important;
	}
}

.bg-dark {
	background-color: $dark !important;
}

a.bg-dark {

	&:hover,
	&:focus {
		background-color: #1d2124 !important;
	}
}

button.bg-dark {

	&:hover,
	&:focus {
		background-color: #1d2124 !important;
	}
}

.bg-facebook {
	background: #2b4170 !important;
}

/*--- gradient-backgrounds --*/

.bg-success-gradient {
	background: linear-gradient(to bottom right, $success 0%, rgba($success, 0.5) 100%) !important;
}

a.bg-success-gradient {

	&:hover,
	&:focus {
		background-color: #448700 !important;
	}
}

button.bg-success-gradient {

	&:hover,
	&:focus {
		background-color: #448700 !important;
	}
}

.bg-info-gradient {
	background: linear-gradient(to bottom right, $info 0%, rgba($info, 0.5) 100%) !important;
}

a.bg-info-gradient {

	&:hover,
	&:focus {
		background-color: #1594ef !important;
	}
}

button.bg-info-gradient {

	&:hover,
	&:focus {
		background-color: #1594ef !important;
	}
}

.bg-warning-gradient {
	background: linear-gradient(to bottom right, $warning 0%, rgba($warning, 0.5) 100%) !important;
}

a.bg-warning-gradient {

	&:hover,
	&:focus {
		background-color: #FBB034 !important;
	}
}

button.bg-warning-gradient {

	&:hover,
	&:focus {
		background-color: #FBB034 !important;
	}
}

.bg-danger-gradient {
	background-image: linear-gradient(to bottom right, $danger 0%, rgba($danger, 0.5) 100%) !important;
}

a.bg-danger-gradient {

	&:hover,
	&:focus {
		background-color: #a11918 !important;
	}
}

button.bg-danger-gradient {

	&:hover,
	&:focus {
		background-color: #a11918 !important;
	}
}

.bg-light-gradient {
	background-color: #f8f9fa !important;
}

a.bg-light-gradient {

	&:hover,
	&:focus {
		background-color: #dae0e5 !important;
	}
}

button.bg-light-gradient {

	&:hover,
	&:focus {
		background-color: #dae0e5 !important;
	}
}

.bg-dark-gradient {
	background-color: #343a40 !important;
}

a.bg-dark-gradient {

	&:hover,
	&:focus {
		background-color: #1d2124 !important;
	}
}

button.bg-dark-gradient {

	&:hover,
	&:focus {
		background-color: #1d2124 !important;
	}
}

.bg-facebook-gradient {
	background: linear-gradient(to bottom right, #3b5998, #2b4170) !important;
}

.bg-white {
	background-color: #fff !important;
}

.bg-transparent {
	background-color: transparent !important;
}

/*------ Background colors -------*/

.bg-purple {
	background: #7F53AC !important;
	color: #fff !important;
}

a.bg-purple {

	&:hover,
	&:focus {
		background-color: #8c31e4 !important;
	}
}

button.bg-purple {

	&:hover,
	&:focus {
		background-color: #8c31e4 !important;
	}
}

.bg-blue-lightest {
	background-color: #edf2fa !important;
}

a.bg-blue-lightest {

	&:hover,
	&:focus {
		background-color: #c5d5ef !important;
	}
}

button.bg-blue-lightest {

	&:hover,
	&:focus {
		background-color: #c5d5ef !important;
	}
}

.bg-blue-lighter {
	background-color: #c8d9f1 !important;
}

a.bg-blue-lighter {

	&:hover,
	&:focus {
		background-color: #9fbde7 !important;
	}
}

button.bg-blue-lighter {

	&:hover,
	&:focus {
		background-color: #9fbde7 !important;
	}
}

.bg-blue-light {
	background-color: #7ea5dd !important;
}

a.bg-blue-light {

	&:hover,
	&:focus {
		background-color: #5689d2 !important;
	}
}

button.bg-blue-light {

	&:hover,
	&:focus {
		background-color: #5689d2 !important;
	}
}

.bg-blue-dark {
	background-color: #3866a6 !important;
}

a.bg-blue-dark {

	&:hover,
	&:focus {
		background-color: #2b4f80 !important;
	}
}

button.bg-blue-dark {

	&:hover,
	&:focus {
		background-color: #2b4f80 !important;
	}
}

.bg-blue-darker {
	background-color: #1c3353 !important;
}

a.bg-blue-darker {

	&:hover,
	&:focus {
		background-color: #0f1c2d !important;
	}
}

button.bg-blue-darker {

	&:hover,
	&:focus {
		background-color: #0f1c2d !important;
	}
}

.bg-blue-darkest {
	background-color: #0e1929 !important;
}

a.bg-blue-darkest {

	&:hover,
	&:focus {
		background-color: #010203 !important;
	}
}

button.bg-blue-darkest {

	&:hover,
	&:focus {
		background-color: #010203 !important;
	}
}

.bg-purssianblue {
	background-color: #362f71;
}

a.bg-purssianblue-lightest {

	&:hover,
	&:focus {
		background-color: #3f3688 !important;
	}
}

button.bg-purssianblue-lightest {

	&:hover,
	&:focus {
		background-color: #3f3688 !important;
	}
}

.bg-indigo-lightest {
	background-color: #f0f1fa !important;
}

a.bg-indigo-lightest {

	&:hover,
	&:focus {
		background-color: #cacded !important;
	}
}

button.bg-indigo-lightest {

	&:hover,
	&:focus {
		background-color: #cacded !important;
	}
}

.bg-indigo-lighter {
	background-color: #d1d5f0 !important;
}

a.bg-indigo-lighter {

	&:hover,
	&:focus {
		background-color: #abb2e3 !important;
	}
}

button.bg-indigo-lighter {

	&:hover,
	&:focus {
		background-color: #abb2e3 !important;
	}
}

.bg-indigo-light {
	background-color: #939edc !important;
}

a.bg-indigo-light {

	&:hover,
	&:focus {
		background-color: #6c7bd0 !important;
	}
}

button.bg-indigo-light {

	&:hover,
	&:focus {
		background-color: #6c7bd0 !important;
	}
}

.bg-indigo-dark {
	background-color: #515da4 !important;
}

a.bg-indigo-dark {

	&:hover,
	&:focus {
		background-color: #404a82 !important;
	}
}

button.bg-indigo-dark {

	&:hover,
	&:focus {
		background-color: #404a82 !important;
	}
}

.bg-indigo-darker {
	background-color: #282e52 !important;
}

a.bg-indigo-darker {

	&:hover,
	&:focus {
		background-color: #171b30 !important;
	}
}

button.bg-indigo-darker {

	&:hover,
	&:focus {
		background-color: #171b30 !important;
	}
}

.bg-indigo-darkest {
	background-color: #141729 !important;
}

a.bg-indigo-darkest {

	&:hover,
	&:focus {
		background-color: #030407 !important;
	}
}

button.bg-indigo-darkest {

	&:hover,
	&:focus {
		background-color: #030407 !important;
	}
}

.bg-purple-lightest {
	background-color: #f6effd !important;
}

a.bg-purple-lightest {

	&:hover,
	&:focus {
		background-color: #ddc2f7 !important;
	}
}

button.bg-purple-lightest {

	&:hover,
	&:focus {
		background-color: #ddc2f7 !important;
	}
}

.bg-purple-lighter {
	background-color: #e4cff9 !important;
}

a.bg-purple-lighter {

	&:hover,
	&:focus {
		background-color: #cba2f3 !important;
	}
}

button.bg-purple-lighter {

	&:hover,
	&:focus {
		background-color: #cba2f3 !important;
	}
}

.bg-purple-light {
	background-color: #c08ef0 !important;
}

a.bg-purple-light {

	&:hover,
	&:focus {
		background-color: #a761ea !important;
	}
}

button.bg-purple-light {

	&:hover,
	&:focus {
		background-color: #a761ea !important;
	}
}

.bg-purple-dark {
	background-color: #844bbb !important;
}

a.bg-purple-dark {

	&:hover,
	&:focus {
		background-color: #6a3a99 !important;
	}
}

button.bg-purple-dark {

	&:hover,
	&:focus {
		background-color: #6a3a99 !important;
	}
}

.bg-purple-darker {
	background-color: #42265e !important;
}

a.bg-purple-darker {

	&:hover,
	&:focus {
		background-color: #29173a !important;
	}
}

button.bg-purple-darker {

	&:hover,
	&:focus {
		background-color: #29173a !important;
	}
}

.bg-purple-darkest {
	background-color: #21132f !important;
}

a.bg-purple-darkest {

	&:hover,
	&:focus {
		background-color: #08040b !important;
	}
}

button.bg-purple-darkest {

	&:hover,
	&:focus {
		background-color: #08040b !important;
	}
}

.bg-pink-lightest {
	background-color: #fef0f5 !important;
}

a.bg-pink-lightest {

	&:hover,
	&:focus {
		background-color: #fbc0d5 !important;
	}
}

button.bg-pink-lightest {

	&:hover,
	&:focus {
		background-color: #fbc0d5 !important;
	}
}

.bg-pink-lighter {
	background-color: #fcd3e1 !important;
}

a.bg-pink-lighter {

	&:hover,
	&:focus {
		background-color: #f9a3c0 !important;
	}
}

button.bg-pink-lighter {

	&:hover,
	&:focus {
		background-color: #f9a3c0 !important;
	}
}

.bg-pink-light {
	background-color: #f999b9 !important;
}

a.bg-pink-light {

	&:hover,
	&:focus {
		background-color: #f66998 !important;
	}
}

button.bg-pink-light {

	&:hover,
	&:focus {
		background-color: #f66998 !important;
	}
}

.bg-pink-dark {
	background-color: #c5577c !important;
}

a.bg-pink-dark {

	&:hover,
	&:focus {
		background-color: #ad3c62 !important;
	}
}

button.bg-pink-dark {

	&:hover,
	&:focus {
		background-color: #ad3c62 !important;
	}
}

.bg-pink-darker {
	background-color: #622c3e !important;
}

a.bg-pink-darker {

	&:hover,
	&:focus {
		background-color: #3f1c28 !important;
	}
}

button.bg-pink-darker {

	&:hover,
	&:focus {
		background-color: #3f1c28 !important;
	}
}

.bg-pink-darkest {
	background-color: #31161f !important;
}

a.bg-pink-darkest {

	&:hover,
	&:focus {
		background-color: #0e0609 !important;
	}
}

button.bg-pink-darkest {

	&:hover,
	&:focus {
		background-color: #0e0609 !important;
	}
}

.bg-red-lightest {
	background-color: #fae9e9 !important;
}

a.bg-red-lightest {

	&:hover,
	&:focus {
		background-color: #f1bfbf !important;
	}
}

button.bg-red-lightest {

	&:hover,
	&:focus {
		background-color: #f1bfbf !important;
	}
}

.bg-red-lighter {
	background-color: #f0bcbc !important;
}

a.bg-red-lighter {

	&:hover,
	&:focus {
		background-color: #e79292 !important;
	}
}

button.bg-red-lighter {

	&:hover,
	&:focus {
		background-color: #e79292 !important;
	}
}

.bg-red-light {
	background-color: #dc6362 !important;
}

a.bg-red-light {

	&:hover,
	&:focus {
		background-color: #d33a38 !important;
	}
}

button.bg-red-light {

	&:hover,
	&:focus {
		background-color: #d33a38 !important;
	}
}

.bg-red-dark {
	background-color: #a41a19 !important;
}

a.bg-red-dark {

	&:hover,
	&:focus {
		background-color: #781312 !important;
	}
}

button.bg-red-dark {

	&:hover,
	&:focus {
		background-color: #781312 !important;
	}
}

.bg-red-darker {
	background-color: #520d0c !important;
}

a.bg-red-darker {

	&:hover,
	&:focus {
		background-color: #260605 !important;
	}
}

button.bg-red-darker {

	&:hover,
	&:focus {
		background-color: #260605 !important;
	}
}

.bg-red-darkest {
	background-color: #290606 !important;
}

a.bg-red-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

button.bg-red-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

.bg-orange-lightest {
	background-color: #fff5ec !important;
}

a.bg-orange-lightest {

	&:hover,
	&:focus {
		background-color: peachpuff !important;
	}
}

button.bg-orange-lightest {

	&:hover,
	&:focus {
		background-color: peachpuff !important;
	}
}

.bg-orange-lighter {
	background-color: #fee0c7 !important;
}

a.bg-orange-lighter {

	&:hover,
	&:focus {
		background-color: #fdc495 !important;
	}
}

button.bg-orange-lighter {

	&:hover,
	&:focus {
		background-color: #fdc495 !important;
	}
}

.bg-orange-light {
	background-color: #feb67c !important;
}

a.bg-orange-light {

	&:hover,
	&:focus {
		background-color: #fe9a49 !important;
	}
}

button.bg-orange-light {

	&:hover,
	&:focus {
		background-color: #fe9a49 !important;
	}
}

.bg-orange-dark {
	background-color: #ca7836 !important;
}

a.bg-orange-dark {

	&:hover,
	&:focus {
		background-color: #a2602b !important;
	}
}

button.bg-orange-dark {

	&:hover,
	&:focus {
		background-color: #a2602b !important;
	}
}

.bg-orange-darker {
	background-color: #653c1b !important;
}

a.bg-orange-darker {

	&:hover,
	&:focus {
		background-color: #3d2410 !important;
	}
}

button.bg-orange-darker {

	&:hover,
	&:focus {
		background-color: #3d2410 !important;
	}
}

.bg-orange-darkest {
	background-color: #331e0e !important;
}

a.bg-orange-darkest {

	&:hover,
	&:focus {
		background-color: #0b0603 !important;
	}
}

button.bg-orange-darkest {

	&:hover,
	&:focus {
		background-color: #0b0603 !important;
	}
}

.bg-yellow-lightest {
	background-color: #fef9e7 !important;
}

a.bg-yellow-lightest {

	&:hover,
	&:focus {
		background-color: #fcedb6 !important;
	}
}

button.bg-yellow-lightest {

	&:hover,
	&:focus {
		background-color: #fcedb6 !important;
	}
}

.bg-yellow-lighter {
	background-color: #fbedb7 !important;
}

a.bg-yellow-lighter {

	&:hover,
	&:focus {
		background-color: #f8e187 !important;
	}
}

button.bg-yellow-lighter {

	&:hover,
	&:focus {
		background-color: #f8e187 !important;
	}
}

.bg-yellow-light {
	background-color: #f5d657 !important;
}

a.bg-yellow-light {

	&:hover,
	&:focus {
		background-color: #f2ca27 !important;
	}
}

button.bg-yellow-light {

	&:hover,
	&:focus {
		background-color: #f2ca27 !important;
	}
}

.bg-yellow-dark {
	background-color: #c19d0c !important;
}

a.bg-yellow-dark {

	&:hover,
	&:focus {
		background-color: #917609 !important;
	}
}

button.bg-yellow-dark {

	&:hover,
	&:focus {
		background-color: #917609 !important;
	}
}

.bg-yellow-darker {
	background-color: #604e06 !important;
}

a.bg-yellow-darker {

	&:hover,
	&:focus {
		background-color: #302703 !important;
	}
}

button.bg-yellow-darker {

	&:hover,
	&:focus {
		background-color: #302703 !important;
	}
}

.bg-yellow-darkest {
	background-color: #302703 !important;
}

a.bg-yellow-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

button.bg-yellow-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

.bg-green-lightest {
	background-color: #eff8e6 !important;
}

a.bg-green-lightest {

	&:hover,
	&:focus {
		background-color: #d6edbe !important;
	}
}

button.bg-green-lightest {

	&:hover,
	&:focus {
		background-color: #d6edbe !important;
	}
}

.bg-green-lighter {
	background-color: #cfeab3 !important;
}

a.bg-green-lighter {

	&:hover,
	&:focus {
		background-color: #b6df8b !important;
	}
}

button.bg-green-lighter {

	&:hover,
	&:focus {
		background-color: #b6df8b !important;
	}
}

.bg-green-light {
	background-color: #8ecf4d !important;
}

a.bg-green-light {

	&:hover,
	&:focus {
		background-color: #75b831 !important;
	}
}

button.bg-green-light {

	&:hover,
	&:focus {
		background-color: #75b831 !important;
	}
}

.bg-green-dark {
	background-color: #4b9500 !important;
}

a.bg-green-dark {

	&:hover,
	&:focus {
		background-color: #316200 !important;
	}
}

button.bg-green-dark {

	&:hover,
	&:focus {
		background-color: #316200 !important;
	}
}

.bg-green-darker {
	background-color: #264a00 !important;
}

a.bg-green-darker {

	&:hover,
	&:focus {
		background-color: #0c1700 !important;
	}
}

button.bg-green-darker {

	&:hover,
	&:focus {
		background-color: #0c1700 !important;
	}
}

.bg-green-darkest {
	background-color: #132500 !important;
}

a.bg-green-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

button.bg-green-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

.bg-teal-lightest {
	background-color: #eafaf8 !important;
}

a.bg-teal-lightest {

	&:hover,
	&:focus {
		background-color: #c1f0ea !important;
	}
}

button.bg-teal-lightest {

	&:hover,
	&:focus {
		background-color: #c1f0ea !important;
	}
}

.bg-teal-lighter {
	background-color: #bfefea !important;
}

a.bg-teal-lighter {

	&:hover,
	&:focus {
		background-color: #96e5dd !important;
	}
}

button.bg-teal-lighter {

	&:hover,
	&:focus {
		background-color: #96e5dd !important;
	}
}

.bg-teal-light {
	background-color: #6bdbcf !important;
}

a.bg-teal-light {

	&:hover,
	&:focus {
		background-color: #42d1c2 !important;
	}
}

button.bg-teal-light {

	&:hover,
	&:focus {
		background-color: #42d1c2 !important;
	}
}

.bg-teal-dark {
	background-color: #22a295 !important;
}

a.bg-teal-dark {

	&:hover,
	&:focus {
		background-color: #19786e !important;
	}
}

button.bg-teal-dark {

	&:hover,
	&:focus {
		background-color: #19786e !important;
	}
}

.bg-teal-darker {
	background-color: #11514a !important;
}

a.bg-teal-darker {

	&:hover,
	&:focus {
		background-color: #082723 !important;
	}
}

button.bg-teal-darker {

	&:hover,
	&:focus {
		background-color: #082723 !important;
	}
}

.bg-teal-darkest {
	background-color: #092925 !important;
}

a.bg-teal-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

button.bg-teal-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

.bg-cyan-lightest {
	background-color: #e8f6f8 !important;
}

a.bg-cyan-lightest {

	&:hover,
	&:focus {
		background-color: #c1e7ec !important;
	}
}

button.bg-cyan-lightest {

	&:hover,
	&:focus {
		background-color: #c1e7ec !important;
	}
}

.bg-cyan-lighter {
	background-color: #b9e3ea !important;
}

a.bg-cyan-lighter {

	&:hover,
	&:focus {
		background-color: #92d3de !important;
	}
}

button.bg-cyan-lighter {

	&:hover,
	&:focus {
		background-color: #92d3de !important;
	}
}

.bg-cyan-light {
	background-color: #5dbecd !important;
}

a.bg-cyan-light {

	&:hover,
	&:focus {
		background-color: #3aabbd !important;
	}
}

button.bg-cyan-light {

	&:hover,
	&:focus {
		background-color: #3aabbd !important;
	}
}

.bg-cyan-dark {
	background-color: #128293 !important;
}

a.bg-cyan-dark {

	&:hover,
	&:focus {
		background-color: #0c5a66 !important;
	}
}

button.bg-cyan-dark {

	&:hover,
	&:focus {
		background-color: #0c5a66 !important;
	}
}

.bg-cyan-darker {
	background-color: #09414a !important;
}

a.bg-cyan-darker {

	&:hover,
	&:focus {
		background-color: #03191d !important;
	}
}

button.bg-cyan-darker {

	&:hover,
	&:focus {
		background-color: #03191d !important;
	}
}

.bg-cyan-darkest {
	background-color: #052025 !important;
}

a.bg-cyan-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

button.bg-cyan-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

.bg-white-lightest {
	background-color: white !important;
}

a.bg-white-lightest {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

button.bg-white-lightest {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

.bg-white-lighter {
	background-color: white !important;
}

a.bg-white-lighter {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

button.bg-white-lighter {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

.bg-white-light {
	background-color: white !important;
}

a.bg-white-light {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

button.bg-white-light {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

.bg-white-dark {
	background-color: #cccccc !important;
}

a.bg-white-dark {

	&:hover,
	&:focus {
		background-color: #b3b2b2 !important;
	}
}

button.bg-white-dark {

	&:hover,
	&:focus {
		background-color: #b3b2b2 !important;
	}
}

.bg-white-darker {
	background-color: #666666 !important;
}

a.bg-white-darker {

	&:hover,
	&:focus {
		background-color: #4d4c4c !important;
	}
}

button.bg-white-darker {

	&:hover,
	&:focus {
		background-color: #4d4c4c !important;
	}
}

.bg-white-darkest {
	background-color: #333333 !important;
}

a.bg-white-darkest {

	&:hover,
	&:focus {
		background-color: #1a1919 !important;
	}
}

button.bg-white-darkest {

	&:hover,
	&:focus {
		background-color: #1a1919 !important;
	}
}

.bg-gray-lightest {
	background-color: #f3f4f5 !important;
}

a.bg-gray-lightest {

	&:hover,
	&:focus {
		background-color: #d7dbde !important;
	}
}

button.bg-gray-lightest {

	&:hover,
	&:focus {
		background-color: #d7dbde !important;
	}
}

.bg-gray-lighter {
	background-color: #dbdde0 !important;
}

a.bg-gray-lighter {

	&:hover,
	&:focus {
		background-color: #c0c3c8 !important;
	}
}

button.bg-gray-lighter {

	&:hover,
	&:focus {
		background-color: #c0c3c8 !important;
	}
}

.bg-gray-light {
	background-color: #aab0b6 !important;
}

a.bg-gray-light {

	&:hover,
	&:focus {
		background-color: #8f979e !important;
	}
}

button.bg-gray-light {

	&:hover,
	&:focus {
		background-color: #8f979e !important;
	}
}

.bg-gray-dark {
	background-color: #6b7278 !important;
}

a.bg-gray-dark {

	&:hover,
	&:focus {
		background-color: #53585d !important;
	}
}

button.bg-gray-dark {

	&:hover,
	&:focus {
		background-color: #53585d !important;
	}
}

.bg-gray-darker {
	background-color: #36393c !important;
}

a.bg-gray-darker {

	&:hover,
	&:focus {
		background-color: #1e2021 !important;
	}
}

button.bg-gray-darker {

	&:hover,
	&:focus {
		background-color: #1e2021 !important;
	}
}

.bg-gray-darkest {
	background-color: #1b1c1e !important;
}

a.bg-gray-darkest {

	&:hover,
	&:focus {
		background-color: #030303 !important;
	}
}

button.bg-gray-darkest {

	&:hover,
	&:focus {
		background-color: #030303 !important;
	}
}

.bg-gray-dark-lightest {
	background-color: #ebebec !important;
}

a.bg-gray-dark-lightest {

	&:hover,
	&:focus {
		background-color: #d1d1d3 !important;
	}
}

button.bg-gray-dark-lightest {

	&:hover,
	&:focus {
		background-color: #d1d1d3 !important;
	}
}

.bg-gray-dark-lighter {
	background-color: #c2c4c6 !important;
}

a.bg-gray-dark-lighter {

	&:hover,
	&:focus {
		background-color: #a8abad !important;
	}
}

button.bg-gray-dark-lighter {

	&:hover,
	&:focus {
		background-color: #a8abad !important;
	}
}

.bg-gray-dark-light {
	background-color: #717579 !important;
}

a.bg-gray-dark-light {

	&:hover,
	&:focus {
		background-color: #585c5f !important;
	}
}

button.bg-gray-dark-light {

	&:hover,
	&:focus {
		background-color: #585c5f !important;
	}
}

.bg-gray-dark-dark {
	background-color: #2a2e33 !important;
}

a.bg-gray-dark-dark {

	&:hover,
	&:focus {
		background-color: #131517 !important;
	}
}

button.bg-gray-dark-dark {

	&:hover,
	&:focus {
		background-color: #131517 !important;
	}
}

.bg-gray-dark-darker {
	background-color: #15171a !important;
}

a.bg-gray-dark-darker {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

button.bg-gray-dark-darker {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

.bg-gray-dark-darkest {
	background-color: #0a0c0d !important;
}

a.bg-gray-dark-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

button.bg-gray-dark-darkest {

	&:hover,
	&:focus {
		background-color: black !important;
	}
}

.bg-azure-lightest {
	background-color: #ecf7fe !important;
}

a.bg-azure-lightest {

	&:hover,
	&:focus {
		background-color: #bce3fb !important;
	}
}

button.bg-azure-lightest {

	&:hover,
	&:focus {
		background-color: #bce3fb !important;
	}
}

.bg-azure-lighter {
	background-color: #c7e6fb !important;
}

a.bg-azure-lighter {

	&:hover,
	&:focus {
		background-color: #97d1f8 !important;
	}
}

button.bg-azure-lighter {

	&:hover,
	&:focus {
		background-color: #97d1f8 !important;
	}
}

.bg-azure-light {
	background-color: #7dc4f6 !important;
}

a.bg-azure-light {

	&:hover,
	&:focus {
		background-color: #4daef3 !important;
	}
}

button.bg-azure-light {

	&:hover,
	&:focus {
		background-color: #4daef3 !important;
	}
}

.bg-azure-dark {
	background-color: #3788c2 !important;
}

a.bg-azure-dark {

	&:hover,
	&:focus {
		background-color: #2c6c9a !important;
	}
}

button.bg-azure-dark {

	&:hover,
	&:focus {
		background-color: #2c6c9a !important;
	}
}

.bg-azure-darker {
	background-color: #1c4461 !important;
}

a.bg-azure-darker {

	&:hover,
	&:focus {
		background-color: #112839 !important;
	}
}

button.bg-azure-darker {

	&:hover,
	&:focus {
		background-color: #112839 !important;
	}
}

.bg-azure-darkest {
	background-color: #0e2230 !important;
}

a.bg-azure-darkest {

	&:hover,
	&:focus {
		background-color: #020609 !important;
	}
}

button.bg-azure-darkest {

	&:hover,
	&:focus {
		background-color: #020609 !important;
	}
}

.bg-lime-lightest {
	background-color: #f2fbeb !important;
}

a.bg-lime-lightest {

	&:hover,
	&:focus {
		background-color: #d6f3c1 !important;
	}
}

button.bg-lime-lightest {

	&:hover,
	&:focus {
		background-color: #d6f3c1 !important;
	}
}

.bg-lime-lighter {
	background-color: #d7f2c2 !important;
}

a.bg-lime-lighter {

	&:hover,
	&:focus {
		background-color: #bbe998 !important;
	}
}

button.bg-lime-lighter {

	&:hover,
	&:focus {
		background-color: #bbe998 !important;
	}
}

.bg-lime-light {
	background-color: #a3e072 !important;
}

a.bg-lime-light {

	&:hover,
	&:focus {
		background-color: #88d748 !important;
	}
}

button.bg-lime-light {

	&:hover,
	&:focus {
		background-color: #88d748 !important;
	}
}

.bg-lime-dark {
	background-color: #62a82a !important;
}

a.bg-lime-dark {

	&:hover,
	&:focus {
		background-color: #4a7f20 !important;
	}
}

button.bg-lime-dark {

	&:hover,
	&:focus {
		background-color: #4a7f20 !important;
	}
}

.bg-lime-darker {
	background-color: #315415 !important;
}

a.bg-lime-darker {

	&:hover,
	&:focus {
		background-color: #192b0b !important;
	}
}

button.bg-lime-darker {

	&:hover,
	&:focus {
		background-color: #192b0b !important;
	}
}

.bg-lime-darkest {
	background-color: #192a0b !important;
}

a.bg-lime-darkest {

	&:hover,
	&:focus {
		background-color: #010200 !important;
	}
}

button.bg-lime-darkest {

	&:hover,
	&:focus {
		background-color: #010200 !important;
	}
}

.bg-blue-1 {
	background-color: #0061da;
	color: #fff !important;
}

.bg-blue {
	background: #0061da;
	color: #fff !important;
}

a.bg-blue {

	&:hover,
	&:focus {
		background-color: #24987E !important;
	}
}

button.bg-blue {

	&:hover,
	&:focus {
		background-color: #24987E !important;
	}
}

.bg-indigo {
	background: linear-gradient(to bottom right, #6574cd 0%, #2f88f7 100%);
	color: #fff !important;
}

a.bg-indigo {

	&:hover,
	&:focus {
		background-color: #3f51c1 !important;
	}
}

button.bg-indigo {

	&:hover,
	&:focus {
		background-color: #3f51c1 !important;
	}
}

.bg-purple-gradient {
	background: linear-gradient(to bottom right, #7F53AC 0%, #647DEE 100%) !important;
	color: #fff !important;
}

a.bg-purple-gradient {

	&:hover,
	&:focus {
		background-color: #8c31e4 !important;
	}
}

button.bg-purple-gradient {

	&:hover,
	&:focus {
		background-color: #8c31e4 !important;
	}
}

.bg-pink {
	background: #fc5296 !important;
	color: #fff !important;
}

.bg-darkpink {
	background-color: #14A485 !important;
	color: #fff !important;
}

a.bg-pink {

	&:hover,
	&:focus {
		background-color: #f33d7a !important;
	}
}

button.bg-pink {

	&:hover,
	&:focus {
		background-color: #f33d7a !important;
	}
}

.bg-red {
	background: #ff2929;
	color: #fff !important;
}

a.bg-red {

	&:hover,
	&:focus {
		background-color: #a11918 !important;
	}
}

button.bg-red {

	&:hover,
	&:focus {
		background-color: #a11918 !important;
	}
}

.bg-orange {
	background: #fc7303;
	color: #fff !important;
}

a.bg-orange {

	&:hover,
	&:focus {
		background-color: #fc7a12 !important;
	}
}

button.bg-orange {

	&:hover,
	&:focus {
		background-color: #fc7a12 !important;
	}
}

.bg-yellow-1 {
	background-color: #FBB034;
	color: #fff !important;
}

.bg-yellow {
	background: #FBB034;
	color: #fff !important;
}

a.bg-yellow {

	&:hover,
	&:focus {
		background-color: #c29d0b !important;
	}
}

button.bg-yellow {

	&:hover,
	&:focus {
		background-color: #c29d0b !important;
	}
}

.bg-green-1 {
	background-color: #4ecc48;
	color: #fff !important;
}

.bg-green {
	background: #4ecc48;
	color: #fff !important;
}

a.bg-green {

	&:hover,
	&:focus {
		background-color: #448700 !important;
	}
}

button.bg-green {

	&:hover,
	&:focus {
		background-color: #448700 !important;
	}
}

.bg-teal {
	background: #1caf9f !important;
}

a.bg-teal {

	&:hover,
	&:focus {
		background-color: #22a193 !important;
	}
}

button.bg-teal {

	&:hover,
	&:focus {
		background-color: #22a193 !important;
	}
}

.bg-cyan {
	background: #007ea7 !important;
	color: #fff !important;
}

a.bg-cyan {

	&:hover,
	&:focus {
		background-color: #117a8b !important;
	}
}

button.bg-cyan {

	&:hover,
	&:focus {
		background-color: #117a8b !important;
	}
}

.bg-white {
	background-color: #fff !important;
}

a.bg-white {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

button.bg-white {

	&:hover,
	&:focus {
		background-color: #e6e5e5 !important;
	}
}

.bg-gray {
	background: #5a6970 !important;
}

a.bg-gray {

	&:hover,
	&:focus {
		background-color: #6c757d !important;
	}
}

button.bg-gray {

	&:hover,
	&:focus {
		background-color: #6c757d !important;
	}
}

x .bg-gray-dark {
	background: #343a40 !important;
}

.bg-lightpink-red {
	color: #ff7088 !important;
}

a.bg-gray-dark {

	&:hover,
	&:focus {
		background-color: #1d2124 !important;
	}
}

button.bg-gray-dark {

	&:hover,
	&:focus {
		background-color: #1d2124 !important;
	}
}

.bg-azure {
	background: #45aaf2;
}

a.bg-azure {

	&:hover,
	&:focus {
		background-color: #1594ef !important;
	}
}

button.bg-azure {

	&:hover,
	&:focus {
		background-color: #1594ef !important;
	}
}

.bg-purple-1 {
	background: #7F53AC;
}

.bg-lime {
	background: #7bd235;
}

a.bg-lime {

	&:hover,
	&:focus {
		background-color: #63ad27 !important;
	}
}

button.bg-lime {

	&:hover,
	&:focus {
		background-color: #63ad27 !important;
	}
}

.bg-google-plus {
	background-color: #dd4b39;
}

.bg-pinterest {
	background: linear-gradient(to right bottom, #c51629 0%, #bd081c 100%);
}

.bg-light-gray {
	background-color: #e0e5ea;
}

.bg-dribbble {
	background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
}

.bg-google {
	background: linear-gradient(to bottom right, #e64522, #c33219) !important;
	color: #fff;
}

/*--bg-transparents--*/

.bg-success-transparent {
	background-color: rgba(0, 230, 130, 0.1);
}

.bg-info-transparent {
	background-color: rgba(0, 214, 230, 0.1);
}

.bg-warning-transparent {
	background-color: rgba(255, 162, 43, 0.1);
}

.bg-danger-transparent {
	background-color: rgba(255, 56, 43, 0.1);
}

.bg-pink-transparent {
	background-color: rgba(255, 43, 136, 0.1);
}

.bg-purple-transparent {
	background-color: rgba(96, 77, 216, 0.1);
}

.bg-dark-transparent {
	background-color: rgba(55, 66, 84, 0.1);
}

.bg-white-transparent {
	background-color: rgba(255, 255, 255, 0.2);
}

.text-cyan {
	color: #17a2b8 !important;
}

.text-yellow {
	color: #ecb403 !important;
}

.text-purple {
	color: #867efc !important;
}

.text-lightpink-red {
	color: #ff7088 !important;
}

.text-lightgreen {
	color: #26eda2 !important;
}

.text-indigo {
	color: #6574cd !important;
}

.text-fb-blue {
	color: #234684 !important;
}

.text-orange {
	color: #fc7303 !important;
}

.text-green {
	color: #4ecc48 !important;
}

.text-green-1 {
	color: #0dff01 !important;
}

.text-teal {
	color: #2bcbba !important;
}

.text-white {
	color: #fff !important;
}

.text-gray-dark {
	color: #343a40 !important;
}

.text-azure {
	color: #45aaf2 !important;
}

.text-lime {
	color: #7bd235 !important;
}

.text-facebook {
	color: #3b5998;
}

.text-pinterest {
	color: #bd081c;
}

.text-google-plus {
	color: #dd4b39!important;
}

.text-red {
	color: #c21a1a !important;
}

.text-pink {
	color: #ec82ef !important;
}

.text-twitter {
	color: #1da1f2;
}
