.chat {
	outline: 0;
	margin: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
}

.chat-line {
	padding: 0;
	text-align: right;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;

	+.chat-line {
		padding-top: 1rem;
	}
}

.chat-message {
	position: relative;
	display: inline-block;
	background-color: #467fcf;
	color: #fff;
	font-size: 0.875rem;
	padding: .375rem .5rem;
	border-radius: 3px;
	white-space: normal;
	text-align: left;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;

	> :last-child {
		margin-bottom: 0 !important;
	}

	&:after {
		content: "";
		position: absolute;
		right: -5px;
		top: 7px;
		border-bottom: 6px solid transparent;
		border-left: 6px solid #467fcf;
		border-top: 6px solid transparent;
	}

	img {
		max-width: 100%;
	}

	p {
		margin-bottom: 1em;
	}
}

.chat-line-friend {
	-ms-flex-direction: row;
	flex-direction: row;

	+.chat-line-friend {
		margin-top: -.5rem;

		.chat-author {
			visibility: hidden;
		}

		.chat-message:after {
			display: none;
		}
	}

	.chat-message {
		background-color: #f3f3f3;
		color: #495057;
		margin-left: .5rem;
		margin-right: 2.5rem;

		&:after {
			right: auto;
			left: -5px;
			border-left-width: 0;
			border-right: 5px solid #f3f3f3;
		}
	}
}

/*---chat---*/
/*--chat--*/

.chat {
	margin-bottom: auto;

	.card {
		// height: 600px;
	}

	.card-header {
		padding: 1rem;
	}
}

.contacts_body {
	white-space: nowrap;
}

.contacts_body {
	.contacts {
		list-style: none;
		padding: 0;

		li {
			width: 100% !important;
			padding: 10px 15px;
			border-bottom: 1px solid $border;
		}
	}

	.active {
		background-color: $background;
	}
}

.chatbox {
	.user_img {
		height: 50px;
		width: 50px;
		border: 1.5px solid #f5f6fa;
	}

	.user_img_msg {
		height: 40px;
		width: 40px;
		border: 1.5px solid #f5f6fa;
	}

	.img_cont {
		position: relative;
		height: 50px;
		width: 50px;
	}

	.img_cont_msg {
		height: 40px;
		width: 40px;
		display: contents;
	}
}

.chat-box-single-line {
	height: 12px;
	margin: 7px 0 30px;
	position: relative;
	text-align: center;
}

.chat abbr.timestamp {
	padding: 4px 14px;
	background: $background;
	border-radius: 4px;
}

.chatbox {
	.user_info {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 15px;

		span {
			color: #464444;
		}
	}

	.action-header small {
		font-size: 10px;
	}
}

.chat {
	.video_cam {
		margin-left: 50px;
		margin-top: 5px;

		span {
			color: white;
			font-size: 20px;
			cursor: pointer;
			margin-right: 20px;
		}
	}

	.msg_cotainer {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 10px;
		background-color: $background;
		padding: 10px;
		position: relative;
	}

	.msg_cotainer_send {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 10px;
		background-color: $background;
		padding: 10px;
		position: relative;
	}

	.msg_time {
		position: absolute;
		left: 0;
		bottom: -18px;
		color: #7886a0;
		font-size: 10px;
	}

	.msg_time_send {
		position: absolute;
		right: 0;
		bottom: -18px;
		color: #7886a0;
		font-size: 10px;
	}

	.msg_head {
		position: relative;
		box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
	}
}

#action_menu_btn {
	position: absolute;
	right: 36px;
	top: 21px;
	color: white;
	cursor: pointer;
	font-size: 20px;
}

.action_menu {
	z-index: 1;
	position: absolute;
	padding: 15px 0;
	background-color: #fff;
	color: #646367;
	top: 40px;
	right: 15px;
	display: none;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

.chat {
	.action-header {
		position: relative;
		background: #fff;
		padding: 15px 13px 15px 17px;
		box-shadow: 0 1px 11px 1px rgba(226, 227, 239, 0.9);
	}

	.dropdown-menu.dropdown-menu-end.show {
		width: 100%;
		margin-bottom: 5px;
		position: absolute;
		transform: translate3d(-176px, 22px, 0px);
		top: 10px !important;
		left: 0px;
		will-change: transform;
	}

	.msb-reply textarea {
		width: 100%;
		font-size: 13px;
		padding: 12px 47px;
		resize: none;
		height: 44px;
		border: 1px solid $border;
		background: $background;
		margin-left: -42px;
		color: #8c8ea9;
	}
}

.msb-reply button {
	position: absolute;
	top: 10px;
	right: 4px;
	border: 0;
	height: 44px;
	width: 44px;
	font-size: 20px;
	color: #fff;
}

.chat {
	.attach_btn {
		background: transparent;
		border: 0 !important;
		cursor: pointer;
		z-index: 1000;
		margin-left: 0px;
	}

	.dropdown-menu li {
		padding: 7px;
		color: $color;

		&:hover {
			background: #e6e6f5;
		}

		i {
			padding: 7px;
		}
	}
}

.action_menu ul li:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.2);
}

.chat {
	.actions {
		>li {
			display: inline-block;
			margin-top: 15px;
		}

		&:not(.a-alt)>li>a>i {
			color: $color;
		}
	}

	.justify-content-start,
	.justify-content-end {
		margin-bottom: 30px;
	}
}

.contacts_body {
	white-space: nowrap;
	height: 510px;
}

.main-chat-list {
  height: calc(100vh - 190px);
}

.main-chat-msgs {
  height: calc(100vh - 370px);
}
