.pop-primary {
	color: #4d9dff;
}

.popsecondary {
	color: #628dea;
}

.popinfo {
	color: #88c8f7;
}

.popsuccess {
	color: #bfff80;
}

.popdanger {
	color: #ea6262;
}

.popwarning {
	color: #f7db6e;
}

.popover {
	top: 0;
	left: 0;
	z-index: 9;
	display: block;
	max-width: 276px;
	font-family: inherit;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #dee3eb;
	border-radius: 3px;

	.arrow {
		position: absolute;
		display: block;
		width: 0.5rem;
		height: 0.5rem;
		margin: 0 3px;

		&::before,
		&::after {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
	margin-bottom: 0.5rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
	bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	border-width: 0.5rem 0.25rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
	bottom: 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	bottom: 1px;
	border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
	margin-left: 0.5rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
	left: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	border-width: 0.25rem 0.5rem 0.25rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
	left: 0;
	border-right-color: #dee3eb;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	left: 1px;
	border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
	margin-top: 0.5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
	top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-width: 0 0.25rem 0.5rem 0.25rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
	top: 0;
	border-bottom-color: #dee3eb;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	top: 1px;
	border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 0.5rem;
	margin-left: -0.25rem;
	content: "";
	border-bottom: 1px solid #fff;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
	margin-right: 0.5rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
	right: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	border-width: 0.25rem 0 0.25rem 0.5rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
	right: 0;
	border-left-color: #dee3eb;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	right: 1px;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	color: inherit;
	background-color: #fff;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);

	&:empty {
		display: none;
	}
}

.popover-body {
	padding: 0.75rem 1rem;
	color: #6e7687;
}

.popover {
	-webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
	filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));

	&.bs-popover-top,
	&.bs-popover-auto[x-placement^="top"] {
		margin-bottom: 0.625rem;
	}

	.arrow {
		margin-left: calc(.25rem + -5px);
	}
}

.popover-static-demo .popover {
	.arrow {
		margin-left: calc(.25rem + 122px);
	}

	position: relative;
}