/*-----Alerts-----*/

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;

	&:first-child {
		margin-bottom: 1rem;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 500;

	&:hover,
	a:hover {
		text-decoration: underline;
	}
}

hr.message-inner-separator {
	clear: both;
	margin-top: 10px;
	margin-bottom: 13px;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.75rem 1.25rem;
	color: inherit;
}

.alert-success {
	color: $success;
	background-color: rgba($success, 0.3);
	border-color: rgba($success, 0.3);

	hr {
		border-top-color: #c5e7a4;
	}

	.alert-link {
		color: #172e00;
	}
}

.alert-info {
	color: $info;
	background-color: rgba($info, 0.3);
	border-color: rgba($info, 0.3);

	hr {
		border-top-color: #b3dcf9;
	}

	.alert-link {
		color: #193c56;
	}
}

.alert-warning {
	color: $warning;
	background-color: rgba($warning, 0.3);
	border-color: rgba($warning, 0.3);

	hr {
		border-top-color: #fae8a4;
	}

	.alert-link {
		color: #4d3f05;
	}
}

.alert-danger {
	color: $danger;
	background-color: rgba($danger, 0.3);
	border-color: rgba($danger, 0.3);

	hr {
		border-top-color: #ecacab;
	}

	.alert-link {
		color: #3f0a09;
	}
}

.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;

	hr {
		border-top-color: #ececf6;
	}

	.alert-link {
		color: #686868;
	}
}

.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;

	hr {
		border-top-color: #b9bbbe;
	}

	.alert-link {
		color: #040505;
	}
}

.alert {
	font-size: 0.9375rem;
}

.alert-icon {
	padding-left: 3rem;

	>i {
		color: inherit !important;
		font-size: 1rem;
		position: absolute;
		top: 1rem;
		left: 1rem;
	}
}

.alert-avatar {
	padding-left: 3.75rem;

	.avatar {
		position: absolute;
		top: .5rem;
		left: .75rem;
	}
}