@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);

.noselect {
	-webkit-user-select: none;

	/* Chrome/Safari */
	-moz-user-select: none;

	/* Firefox */
	-ms-user-select: none;

	/* IE10+ */
}

.left-align {
	text-align: left;
}

.right-align {
	text-align: right;
}

.cal1 .clndr {
	.clndr-controls {
		width: 100%;
		position: relative;
		display: flex;
		padding: 15px;
		border: 1px solid #eaf2f9;
		border-bottom: 0;
		background-color: #f6f9fc;

		.month {
			float: left;
			width: 33%;
			text-align: center;
			line-height: 32px;
		}

		.clndr-control-button {
			float: left;
			width: 33%;

			&.rightalign {
				text-align: right;
				width: 34%;
			}

			.clndr-next-button,
			.clndr-previous-button {
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				padding: 6px 8px;
				min-width: 80px;
				text-align: center;
				display: inline-block;
				border-radius: 3px;

				&:hover {
					color: #fff;
				}

				&.inactive {
					opacity: 0.5;

					&:hover {
						background: none;
						cursor: default;
					}
				}
			}
		}
	}

	.clndr-table {
		table-layout: fixed;
		width: 100%;

		.header-days {
			height: 50px;
			font-size: 10px;

			.header-day {
				vertical-align: middle;
				text-align: center;
				color: #fff;
			}
		}

		tr {
			height: 85px;

			td {
				vertical-align: top;
			}

			.day {
				width: 100%;
				height: inherit;

				&.today,
				&.my-today {
					color: #fff;
				}

				&.event:hover,
				&.my-event:hover {
					background: #3ac555;
					color: #fff;
				}

				&.inactive,
				&.my-inactive {
					background: #f2f4f8;
				}

				.day-contents {
					box-sizing: border-box;
					padding: 8px;
					font-size: 12px;
					text-align: right;
				}
			}

			.empty,
			.adjacent-month,
			.my-empty,
			.my-adjacent-month {
				width: 100%;
				height: inherit;
			}

			.empty:last-child,
			.adjacent-month:last-child,
			.my-empty:last-child,
			.my-adjacent-month:last-child {
				border-right: 1px solid #eaf2f9;
			}

			&:last-child {

				.day,
				.my-day {
					border-bottom: 1px solid #eaf2f9;
				}

				.empty,
				.my-empty {
					border-bottom: 1px solid #d7e7ff;
				}
			}
		}
	}
}

.cal2 {
	max-width: 177px;
	margin: 30px auto;
	font-family: 'Droid Sans Mono';

	.clndr {
		.clndr-controls {
			display: block;
			display: inline-block;
			width: 100%;
			margin-bottom: 3px;

			.clndr-previous-button {
				float: left;
				width: 10%;
				text-align: left;
				cursor: pointer;
				-webkit-user-select: none;

				/* Chrome/Safari */
				-moz-user-select: none;

				/* Firefox */
				-ms-user-select: none;

				/* IE10+ */

				&:hover {
					background-color: #f4f4f4;
				}
			}

			.month {
				float: left;
				width: 80%;
				text-align: center;
			}

			.clndr-next-button {
				float: left;
				width: 10%;
				text-align: right;
				cursor: pointer;
				-webkit-user-select: none;

				/* Chrome/Safari */
				-moz-user-select: none;

				/* Firefox */
				-ms-user-select: none;

				/* IE10+ */

				&:hover {
					background-color: #f4f4f4;
				}
			}
		}

		.clndr-grid {
			text-align: center;
			border: 1px solid #FF4545;
			display: inline-block;

			.header-day {
				float: left;
				width: 25px;
				height: 25px;
				background: #FF4545;
			}

			.day {
				float: left;
				width: 25px;
				height: 25px;

				&.event {
					background-color: #B4E09F;
				}

				&.today {
					background-color: #E3C57F;
				}

				&.selected {
					background-color: #E37FD6;
				}

				&.inactive {
					color: gray;
				}
			}

			.empty,
			.adjacent-month {
				float: left;
				width: 25px;
				height: 25px;
				background: #ddd;
			}
		}

		.clndr-today-button {
			width: 100%;
			text-align: center;
			cursor: pointer;

			&:hover {
				background-color: #ddd;
			}
		}
	}

	.multi-month-controls .quarter-button {
		display: inline-block;
		width: 25%;

		&:hover {
			cursor: pointer;
			background-color: #f4f4f4;
		}
	}

	.day.inactive {
		background-color: #aaa;
	}
}

.cal3 {
	max-width: 354px;
	margin: 0 auto;
	font-family: 'Droid Sans Mono';

	.cal {
		float: left;
		width: 177px;
	}

	.clndr {
		.clndr-controls {
			display: block;
			display: inline-block;
			width: 100%;
			margin-bottom: 8px;

			&.top {
				margin-bottom: -22px;
				position: relative;
				z-index: 1;
			}

			.clndr-previous-button {
				float: left;
				width: 10%;
				text-align: left;
				cursor: pointer;
				-webkit-user-select: none;

				/* Chrome/Safari */
				-moz-user-select: none;

				/* Firefox */
				-ms-user-select: none;

				/* IE10+ */

				&:hover {
					background-color: #f4f4f4;
				}
			}

			.month {
				text-align: center;
				width: 80%;
				margin: 0 auto;
			}

			.clndr-next-button {
				float: right;
				width: 10%;
				text-align: right;
				cursor: pointer;
				-webkit-user-select: none;

				/* Chrome/Safari */
				-moz-user-select: none;

				/* Firefox */
				-ms-user-select: none;

				/* IE10+ */

				&:hover {
					background-color: #f4f4f4;
				}
			}
		}

		.clndr-grid {
			text-align: center;
			border: 1px solid #FF4545;
			display: inline-block;

			.header-day {
				float: left;
				width: 25px;
				height: 25px;
				background: #FF4545;
			}

			.day {
				float: left;
				width: 25px;
				height: 25px;

				&.event {
					background-color: #B4E09F;
				}

				&.today {
					background-color: #E3C57F;
				}
			}

			.empty,
			.adjacent-month {
				float: left;
				width: 25px;
				height: 25px;
				background: #ddd;
			}
		}

		.clndr-today-button {
			width: 100%;
			text-align: center;
			cursor: pointer;

			&:hover {
				background-color: #ddd;
			}
		}
	}

	.multi-month-controls .quarter-button {
		display: inline-block;
		width: 25%;

		&:hover {
			cursor: pointer;
			background-color: #f4f4f4;
		}
	}
}

.clndr-next-button,
.clndr-previous-button,
.clndr-next-year-button,
.clndr-previous-year-button {
	-webkit-user-select: none;

	/* Chrome/Safari */
	-moz-user-select: none;

	/* Firefox */
	-ms-user-select: none;

	/* IE10+ */
}

.clndr-next-button.inactive,
.clndr-previous-button.inactive,
.clndr-next-year-button.inactive,
.clndr-previous-year-button.inactive {
	opacity: 0.5;
	cursor: default;
}
