@charset "UTF-8";
@import url("../plugins/web-fonts/feather/feather.css");
@import url("../plugins/web-fonts/font-awesome/css/font-awesome.min.css");
@import url("../plugins/web-fonts/Glyphicons/glyphicon.css");
@import url("../plugins/web-fonts/ionicons/ionicons.css");
@import url("../plugins/web-fonts/Linearicons/Linearicons.css");
@import url("../plugins/web-fonts/materialdesignicons/materialdesignicons.css");
@import url("../plugins/web-fonts/Pe-icon-7-stroke/Pe-icon-7.css");
@import url("../plugins/web-fonts/simple-line-icons/simple-line-icons.css");
@import url("../plugins/web-fonts/themify/themify.css");
@import url("../plugins/web-fonts/typicons/typicons.css");
@import url("../plugins/web-fonts/weathericons/weathericons.css");
@import url("../plugins/web-fonts/boxicons/boxicons.css");
