/*------ Breadcrumb ------*/

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	list-style: none;
	border-radius: 3px;
	position: relative;
	right: 0;
	padding-right: 0;
	color: #323c53;
	padding-left: 0;
}

.breadcrumb-item {
	a {
		color: $color;
	}

	+.breadcrumb-item {
		&::before {
			display: inline-block;
			color: $color;
			opacity: 0.4;
			content: "\ea55";
			font-family: boxicons !important;
		}

		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
}

.breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: $background;
	border-radius: .25rem;
}

.breadcrumb-item1 {
	a:hover {
		color: $primary;
		text-decoration: underline;
	}

	+.breadcrumb-item1 {
		&::before {
			display: inline-block;
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			content: "/";
			color: #cec5c5;
		}

		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}

	&.active {
		color: $color;
	}
}

.breadcrumb-3 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
	}

	li {
		display: contents;
		text-align: center;
	}
}

.breadcrumb-4 li,
.breadcrumb-5 li {
	display: contents;
	text-align: center;
}

.breadcrumb-4 ol,
.breadcrumb-5 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: center;
}

.breadcrumb-1 li,
.breadcrumb-2 li,
.breadcrumb-6 li {
	text-align: right;
	display: contents;
}

.breadcrumb-1 ol,
.breadcrumb-2 ol,
.breadcrumb-6 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: right;
}

.page-header .breadcrumb {
	font-size: 1rem;
}

@media (max-width: 480px) {
	.breadcrumb {
		flex-wrap: wrap;
		margin-bottom: 0;
		list-style: none;
		border-radius: 3px;
		position: relative;
		right: 0;
		top: 0px;
		padding-right: 0;
		color: #fff;
		left: 0px;
		padding: 0;
		margin-top: 5px;
	}
}