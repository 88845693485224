.footer {
	border-top: 1px solid $border;
	font-size: 0.875rem;
	padding: 1.25rem 0;
	color: $color;
	background-color: $white;

	.container {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.social {
		ul li {
			float: left;
			padding: 7px;

			a {
				color: #8c8ea9;
			}
		}

		margin: 0 auto;
	}
}

@media print {
	.footer {
		display: none;
	}
}

.footer-payments {
	padding-left: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;

	li {
		&:not(:last-child) {
			margin-right: 1.5rem;
		}

		display: inline-block;
	}

	a {
		color: #a7a8c7;
		text-decoration: none;
		font-size: 2rem;

		&:hover {
			opacity: .9;
		}
	}
}

.main-footer {
	padding: 20px 30px 20px 280px;
	font-size: 14px;
	font-weight: 400;
	background-color: #fff;
	border-top: 1px solid #e6eaea;
}

.footer-social-list {
	li {
		&:not(:last-child) {
			margin-right: 1.5rem;
		}

		display: inline-block;
	}

	a {
		color: $color;
		text-decoration: none;
		font-size: 1rem;
	}

	padding-left: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;
}

@media (max-width: 991px) {
	.footer-social-list {
		text-align: center;
		margin-top: 1rem;
	}

	.footer1 {
		text-align: center;
	}
}

@media (min-width: 992px) {
	.footer.left-footer {
		padding: 1.25rem 1.25rem 1.25rem 250px;
	}
}