.logo a img {
	display: none;
}

.bg_dark {
	background: #0a080e;
	color     : #fff;
}

.styleswitch.layoutswitch {
	background        : none repeat scroll 0 0 #f4f4f4;
	border            : medium none !important;
	color             : #fff !important;
	display           : inline-block;
	height            : auto;
	margin            : 3px 0;
	padding           : 2px;
	text-align        : center;
	text-transform    : uppercase;
	width             : 100%;
	text-decoration   : none !important;
	-webkit-transition: all .3s ease-out;
	-moz-transition   : all .3s ease-out;
	-ms-transition    : all .3s ease-out;
	-o-transition     : all .3s ease-out;
	transition        : all .3s ease-out;
}

.styleswitch.layoutswitch:hover {
	background: #000 !important;
}

.styleswitch {
	background-color: #e425e9;
	display         : inline-block;
	margin          : 4px 8px;
	width           : 25px;
	height          : 25px;
	border-radius   : 5px;
}

.styleswitch:active,
.styleswitch:focus {
	border: 5px solid #000;
}

#switcher-body .container {
	background: #fff;
	margin    : 50px auto;
	border    : 1px solid #dedede;
	padding   : 40px;
}

.switcher {
	width        : 50px;
	height       : 50px;
	display      : inline-block;
	margin       : 4px 8px;
	border-radius: 5px;
	border       : 0px solid #000;
}

.demo_changer {
	z-index    : 999;
	position   : fixed;
	right      : -270px;
	font-weight: 400 !important;
	height     : 100%;
  overflow-y: scroll;
}

.purchase {
	text-decoration: underline;
	color          : #4BACC6
}

.demoPanel {
	position: relative;
}

.evo-pop {
	z-index: 10000;
	width  : 204px;
	padding: 3px 3px 0
}

.evo-pop-ie {
	z-index: 10000;
	width  : 212px;
	padding: 3px
}

.evo-palette td {
	font-size: 1px;
	border   : solid 1px #c0c0c0;
	padding  : 7px;
	cursor   : pointer
}

.evo-palette tr.top td {
	border-bottom: 0
}

.evo-palette tr.in td {
	border-top   : 0;
	border-bottom: 0
}

.evo-palette tr.bottom td {
	border-top: 0
}

.evo-palette div.sep {
	height: 3px
}

.evo-palette,
.evo-palette-ie {
	border-collapse : separate;
	border-spacing  : 4px 0;
	border-collapse: expression('separate', cellSpacing='2px')
}

.evo-palette th,
.evo-palette-ie th {
	border     : 0;
	padding    : 5px 3px;
	text-align : left;
	font-weight: normal;
	background : transparent !important
}

.evo-palette-ie td {
	font-size: 1px;
	border   : solid 1px #c0c0c0;
	padding  : 7px;
	cursor   : pointer
}

.evo-palette2,
.evo-palette2-ie {
	margin         : auto;
	border-collapse: collapse
}

.evo-palette2 td,
.evo-palette2-ie td {
	font-size: 1px;
	cursor   : pointer
}

.evo-palette2 td {
	padding: 6px 7px
}

.evo-palette2-ie td {
	padding: 5px
}

.evo-palcenter {
	padding   : 5px;
	text-align: center
}

.evo-colorind,
.evo-colorind-ie,
.evo-colorind-ff {
	border: solid 1px #c3c3c3;
	width : 20px;
	height: 20px;
	float : right
}

.evo-colorind {
	position: relative;
	top     : 2px
}

.evo-colorind-ie {
	position: relative;
	top     : -23px
}

.evo-colorbox-ie {
	font-size: 8px;
	padding  : 3px 9px !important
}

.evo-colortxt-ie {
	position: relative;
	top     : -6px
}

.evo-pop:after,
.evo-pop-ie:after,
.evo-colorind:after,
.evo-colorind-ie:after,
.evo-colorind-ff:after,
.evo-color span:after,
.evo-cHist:after {
	content   : ".";
	display   : block;
	height    : 0;
	clear     : both;
	visibility: hidden;
	font-size : 0
}

.evo-color {
	width  : 94px;
	padding: 1px 3px 0 4px
}

.evo-color div {
	border       : solid 1px #808080;
	border-right : solid 1px #c0c0c0;
	border-bottom: solid 1px #c0c0c0;
	padding      : 3px;
	margin-bottom: 5px;
	width        : 10px;
	height       : 10px;
	float        : left
}

.evo-color span {
	font-size: 15px;
	margin   : 1px 0 4px 3px;
	float    : left
}

.evo-sep {
	height   : 10px;
	font-size: 0
}

.evo-more {
	padding  : 4px 5px 4px;
	font-size: smaller
}

.evo-cHist {
	padding: 3px
}

.evo-cHist div {
	cursor : pointer;
	border : solid 1px #c0c0c0;
	padding: 3px;
	margin : 5px;
	width  : 10px;
	height : 10px;
	float  : left
}

a.evo-hist {
	margin-left: 6px
}

.evo-pointer {
	cursor: pointer
}

.evo-pop {
	background: #FFFFFF;
	border    : 1px solid #DEDEDE;
	padding   : 10px !important;
	width     : 225px !important;
	z-index   : 10000;
}

.evo-colorind,
.evo-colorind-ie,
.evo-colorind-ff {
	border  : 1px solid #C3C3C3;
	height  : 20px;
	position: absolute;
	top     : 11px;
	right   : 5px;
	width   : 20px;
}

.demo_changer p {
	margin: 10px 0;
}

.demo_changer .dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
	background: #fff;
}

.demo_changer h4 {
	color         : #545454 !important;
	margin        : 10px 0px !important;
	text-transform: uppercase;
	border-bottom : 1px solid #e1e0ea;
	border-top : 1px solid #e1e0ea;
	background-color: #f2f6f9;
}

.demo_changer select:active,
.demo_changer select:focus,
.demo_changer select:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover,
.demo_changer .btn:active,
.demo_changer .btn:focus,
.demo_changer .btn:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover {
	box-shadow: none !important;
}

.demo_changer .button {
	-moz-user-select: none;
	user-select: none;
	background-image: none;
	border          : 1px solid #ddd;
	border-radius   : 3px;
	cursor          : pointer;
	display         : inline-block;
	font-size       : 14px;
	font-weight     : normal;
	background      : #fff;
	line-height     : 1.42857;
	margin-bottom   : 0;
	width           : 145px !important;
	padding         : 6px 12px;
	text-align      : center;
	vertical-align  : middle;
	white-space     : nowrap;
}

.demo_changer .img-thumbnail {
	height          : 35px;
	background-color: #fff;
	border          : 1px solid #ddd;
	margin-bottom   : 5px;
	width           : 46% !important;
	padding         : 2px;
}

.form_holder img {
	border: 4px solid #E1E1E1;
	height: 40px;
	margin: 2px !important;
	width : 40px !important;
}

.demo_changer hr {
	border-color: #E0E0E0;
}

.demo_changer,
.demo_changer p {
	color: #545454 !important;
}

.demo_changer h4 {
	color         : #212121 !important;
	font-size     : 14px;
	padding-bottom: 0;
	text-align    : left;
	padding       : 10px;
	font-weight   : 600;
}

.demo_changer i {
	color     : #fff;
	font-size : 21px;
	margin-top: 10px;

}

/*.demo_changer .btn {
	margin-top: 10px;
}*/
.demo_changer .demo-icon {
	cursor       : pointer;
	text-align   : center;
	padding      : 2px;
	float        : left;
	height       : 40px;
	line-height  : 38px;
	width        : 40px;
	border-radius: 0px 0 0 0px !important;
	top          : 17rem;
	position     : relative;
}

@media (max-width: 992px) {
	.demo_changer .form_holder {
		height: 100vh;
	}
}

@media (min-width: 992px) {
	.demo_changer .form_holder {
		height: 100vh;
	}
}

.demo_changer .form_holder {
	background   : #fff;
	border-radius: 0;
	cursor       : pointer;
	float        : right;
	font-size    : 12px;
	padding      : 0 7px;
	width        : 250px;
	box-shadow   : 0 0 0 1px rgba(61, 119, 180, .12), 0 8px 16px 0 rgba(91, 139, 199, .24);
}

.demo_changer .form_holder p {
	font-size: 12px;
}

.color_display_2 {
	clear         : both;
	color         : #FFFFFF;
	display       : inline-block;
	font-size     : 12px;
	width         : 31px !important;
	height        : 31px;
	margin-bottom : 7px;
	padding       : 4px 10px;
	text-align    : center;
	text-shadow   : 0 -1px 0 rgba(0, 0, 0, 0.3);
	text-transform: uppercase;
	box-shadow    : 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 2px rgba(255, 255, 255, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
}

.color_display_2 a {
	color          : #fff;
	text-decoration: none;
}

.color_display_2 a:focus,
.color_display_2 a:hover {
	color          : #ededed !important;
	text-decoration: none;
}

.demoPanel {
	margin : 0;
	padding: 0;
	width  : 125px;
}

.predefined_styles {
	padding   : 0;
	text-align: center;

}

@media only screen and (min-width: 768px) {
	.demo_changer {
		top: 0;
	}

	.demo_changer .demo-icon {
		height: 40px;
		width : 40px;
	}

	.demo_changer i {
		font-size: 17px;
	}
}

@media only screen and (max-width: 768px) {
	.demo_changer {
		top: 0px;
	}

	.demo_changer i {
		font-size : 21px;
		margin-top: 8px;
	}

	.demo_changer .demo-icon {
		height: 40px;
		width : 40px;
	}

	.demo_changer {
		display: block;
	}
}

.bootstrap-select.show-menu-arrow.open>.btn {
	z-index: 2051;
}

.bootstrap-select .btn:focus {
	outline       : thin dotted #333333 !important;
	outline       : 5px auto -webkit-focus-ring-color !important;
	outline-offset: -2px;
}

.bootstrap-select.btn-group .btn .filter-option {
	overflow  : hidden;
	position  : absolute;
	left      : 12px;
	right     : 25px;
	text-align: left;
}

.bootstrap-select.btn-group .btn .caret {
	position      : absolute;
	top           : 50%;
	right         : 12px;
	margin-top    : -2px;
	vertical-align: middle;
}

.bootstrap-select.btn-group>.disabled,
.bootstrap-select.btn-group .dropdown-menu li.disabled>a {
	cursor: not-allowed;
}

.bootstrap-select.btn-group>.disabled:focus {
	outline: none !important;
}

.bootstrap-select.btn-group[class*="span"] .btn {
	width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
	min-width         : 100%;
	-moz-box-sizing   : border-box;
	-webkit-box-sizing: border-box;
	box-sizing        : border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
	position             : static;
	border               : 0;
	padding              : 0;
	margin               : 0;
	-webkit-border-radius: 0;
	-moz-border-radius   : 0;
	border-radius        : 0;
	-webkit-box-shadow   : none;
	-moz-box-shadow      : none;
	box-shadow           : none;
}

.bootstrap-select.btn-group .dropdown-menu dt {
	display: block;
	padding: 3px 20px;
	cursor : default;
}

.bootstrap-select.btn-group .div-contain {
	overflow: hidden;
}

.bootstrap-select.btn-group .dropdown-menu li {
	position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li>a.opt {
	position    : relative;
	padding-left: 35px;
}

.bootstrap-select.btn-group .dropdown-menu li>a {
	cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li>dt small {
	font-weight: normal;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a i.check-mark {
	display   : inline-block;
	position  : absolute;
	right     : 15px;
	margin-top: 2.5px;
}

.bootstrap-select.btn-group .dropdown-menu li a i.check-mark {
	display: none;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
	margin-right: 34px;
}

.bootstrap-select.btn-group .dropdown-menu li small {
	padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu li:not(.disabled)>a:hover small,
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled)>a:focus small,
.bootstrap-select.btn-group .dropdown-menu li.active:not(.disabled)>a small {
	color: #64b1d8;
	color: rgba(255, 255, 255, 0.4);
}

.bootstrap-select.btn-group .dropdown-menu li>dt small {
	font-weight: normal;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
	content            : '';
	display            : inline-block;
	border-left        : 7px solid transparent;
	border-right       : 7px solid transparent;
	border-bottom      : 7px solid #CCC;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position           : absolute;
	bottom             : -4px;
	left               : 9px;
	display            : none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
	content      : '';
	display      : inline-block;
	border-left  : 6px solid transparent;
	border-right : 6px solid transparent;
	border-bottom: 6px solid white;
	position     : absolute;
	bottom       : -4px;
	left         : 10px;
	display      : none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
	bottom          : auto;
	top             : -3px;
	border-top      : 7px solid #ccc;
	border-bottom   : 0;
	border-top-color: rgba(0, 0, 0, 0.2);
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
	bottom       : auto;
	top          : -3px;
	border-top   : 6px solid #ffffff;
	border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
	right: 12px;
	left : auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
	right: 13px;
	left : auto;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:after {
	display: block;
}

.bootstrap-select.btn-group .no-results {
	padding   : 3px;
	background: #f5f5f5;
	margin    : 0 5px;
}

.mobile-device {
	position: absolute;
	top     : 0;
	left    : 0;
	display : block !important;
	width   : 100%;
	height  : 100% !important;
	opacity : 0;
}

.bootstrap-select.fit-width {
	width: auto !important;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option {
	position: static;
}

.bootstrap-select.btn-group.fit-width .btn .caret {
	position  : static;
	top       : auto;
	margin-top: -1px;
}

.control-group.error .bootstrap-select .dropdown-toggle {
	border-color: #b94a48;
}

.bootstrap-select-searchbox {
	padding: 4px 8px;
}

.bootstrap-select-searchbox input {
	margin-bottom: 0;
}

.horizontal .vertical-switcher {
	display: none;
}

@media (max-width: 991px) {
	.vertical-switcher {
		display: none;
	}
}

/* .horizontal .vertical-images {
	display: none;
} */

/* RTL */

.rtl .demo_changer {
	transition: all 0.3s ease;
	left      : -270px;
	right     : auto !important;
}

.rtl .demo_changer.active {
	transition: all 0.3s ease;
	right     : auto !important;
	left      : 0;
}

.rtl .demo_changer .form_holder {
	float       : left;
}

.rtl .demo_changer h4 {
	text-align: right;
}

.rtl .onoffswitch2-label:before {
	left : 13px;
	right: inherit;
}

.rtl .onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
	left : 0px;
	right: inherit;
}

.rtl .ps>.ps__rail-y {
	left : 0 !important;
	right: auto !important;
}
.error-page1 .switcher-nav{
	display: none;
}
.error-page1 .horizontal-styles{
	display: none;
}
.error-page1 .leftmenu-styles{
	display: none;
}
.error-page1 .header-styles{
	display: none;
}
.error-page1 .vertical-images{
	display: none;
}
.error-page1 .switcher-layout{
	display: none;
}
.error-page1 .vertical-switcher{
	display: none;
}
.card-body .switch_section span {
    color: #1c1d22 !important;
    font-size: 15px;
    font-weight: 500;
}
.card-body .predefined_styles {
	text-align:Center !important;
}

/* licenses */
.tx-26{
    font-size: 26px;
}
.tx-14 {
    font-size: 14px;
}
.tx-12 {
    font-size: 12px;
}
.font-weight-semibold {
    font-weight: 500;
}
.w-50 {
    width: 50% !important;
  }

  .w-55 {
    width: 50% !important;
  }

  .w-xs {
    min-width: 90px;
  }

  .w-sm {
    min-width: 110px;
  }

  .w-md {
    min-width: 130px;
  }

  .w-lg {
    min-width: 150px;
  }
  .w-xl {
    min-width: 190px;
  }

  .buynow .money-bag,  .free-img{
    margin: 0 auto;
    display: block;
    margin-top: -20px;
  }

  .buynow .dropdown-item {
    display: block;
    width: 100%;
    padding:0.45rem .80rem;
    clear: both;
    font-weight: 400;
    color: #576074;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 15px;
  }
  .buynow .dropdown-item:hover{
    background-color: #f5f4ff !important;
	border-radius: 5px;
  }
  .buynow .btn{
    padding: 0.375rem 0.75rem;
  }
  .buynow .dropdown-menu{
    border-radius: 5px !important;
      background-color: #ffffff;
      border: 1px solid #ffffff8a;
    box-shadow: 0 0px 90px rgb(155 153 153 / 48%) !important;
  }
  .buynow .card-block{
    border: 1px solid rgb(231 229 245 / 11%) !important;
      box-shadow: 0 0.15rem 1.75rem 0 rgb(255 255 255 / 6%);
      top: 0;
      bottom: 0;
      background:#6a1cbb69;
      left: 0;
      border-radius: 7px;
      width: 100%;
      height: 100%;
      position: absolute;
      inset-inline-end: 0;
      bottom: 0;
      inset-inline-start: 0;
  }

  @media (max-width: 480px) {
    .buynow .card .btn {
      display: initial !important;
    }
  }
  .buynow.modal .btn-close:focus{
   box-shadow: none;
  }
  .buynow .modal-content {
    position        : relative;
    display         : flex;
    flex-direction  : column;
    width           : 100%;
    pointer-events  : auto;
    background-color: #000000a8;
    background-clip : padding-box;
    border          : 1px solid rgba(0, 0, 0, 0.2);
    border-radius   : 1rem;
    outline         : 0;
  }

  .buynow .modal-dialog .image-top {
    margin-top: -10px !important;
  }
  .buynow .modal-dialog .card-block{
    background: #6a1cbbbf;
  }
  .buynow .regular-license {
    z-index: 9;
  }
  .border-0{
    border: 0px !important;
  }
   .modal .modal-header {
    padding: 10px 20px;
  }
  @media (max-width: 480px){
    .buynow .modal-dialog  .modal-body{
      margin: 15px;
    }
  }
  .buynow .modal-dialog  .btn-close{
    position: absolute;
    float: right;
    margin-right: auto;
    right: 33px;
    top: 30px;
    padding:7px;
    background-image: none;
    border: 1px solid #ffffff47;
    border-radius: 5px;
    line-height: 0;
  }
  .buynow .modal-dialog  .btn-close span{
    color: #fff;
    font-size: 24px;
    padding: 8px 0px;
    line-height: 0;
  }
   .lh-xs {
    line-height: 0.75 !important;
    margin-top: 6px;
  }
  .buynow .license-view{
    text-align: center;
    text-decoration: underline;
  }
  .buynow .license-view a{
       padding: 10px;
      border-radius: 5px;
  }
  .buynow .license-view a:hover{
       padding: 10px;
      background-color: rgb(255 255 255 / 12%);
      border-radius: 5px;
  }
  .bg-image-style {
    background-image: url(../img/bg3.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    position: relative;
    border-radius: 0px;
  }
  .bg-image-style::before{
    content: "";
     background-color: #ffffffa6;
     width: 100%;
     height: 100%;
     left: 0%;
     top: 0;
     position:absolute;
  }
  .buynow .modal-backdrop{
    background-color: #000000b8;
  }
  .buynow .card-body.imag-list{
    overflow: visible;
  }
  .buynow .imag-list{
    position: relative;
    content: "";
    left: 0;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    overflow: hidden;
    z-index: 1;

  }
  .buynow .imag-list::before{
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 7px;
    position: absolute;
    background: #6a1cbb82;
    width: 100%;
    height: 100%;
    z-index: -1;
    }
    .buynow .modal-content {
      z-index: 0;
      border: 0px #000 solid !important;
  }
  .buynow  .modal-content::before{
      content: "";
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      top: 0;
      left: 0;
      right: 0;
      border-radius:1rem;
      position: absolute;
      background: #000000b3 ;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .buynow .Licenses-img{
      border: 1px solid #ffffff2e;
      border-radius: 7px;
    }
    .modal.buynow .modal-body .card{
      background: transparent;

    }
