/*------ App Header -------*/

.header.top-header {
    top: 0;
    position: fixed;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    border-bottom: 1px solid $border;
}
.app-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: $white;
    z-index: 999;
    padding: 0.45rem 0;
    -webkit-transition: padding-left 0.2s ease;
    -o-transition: padding-left 0.2s ease;
    transition: padding-left 0.2s ease;
}
.app-header .header-brand {
    color: inherit;
    margin: 0 auto;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    min-width: 220px;
    text-align: center;
    min-width: auto !important;
    .header-brand-img {
        margin-right: 0;
    }
}
.header-brand-img {
    height: 2.5rem;
    line-height: 2rem;
    vertical-align: bottom;
    margin-right: 0;
    width: auto;
    margin-top: 3px;
}
.app-sidebar__toggle {
    a {
        color: #b6c1d9;
    }
    text-align: center;
    font-size: 20px;
    position: relative;
    line-height: 2.5;
    &:hover {
        color: $primary;
    }
    &:focus,
    &:hover {
        text-decoration: none;
    }
}
.close-toggle {
    display: none;
}
.sidenav-toggled {
    .close-toggle {
        display: flex;
    }
    .open-toggle {
        display: none;
    }
}
.nav-link.icon {
    margin: 5px;
    padding: 5px 8px;
    text-align: center;
    height: 2.5rem;
    font-size: 1.2rem;
    position: relative;
    border-radius: 3px;
    .nav-span {
        font-size: 0.9rem;
        color: #576482;
        vertical-align: middle;
        margin-left: 0.5rem;
        font-weight: 400;
    }
}
.header-option {
    .header-icon {
        margin-top: -3px;
    }
}
.header-option .dropdown-menu {
    font-size: 13px;
}
.navbar-toggler.navresponsive-toggler {
    box-shadow: none;
}
.search-element {
    position: relative;
}
.header .form-inline {
    .form-control {
        padding-left: 20px;
        width: 200px;
        padding-right: 20px;
        padding-top: 7px;
        line-height: 1.4;
        // background-color: $background;
        &:focus {
            box-shadow: none;
        }
        &::placeholder {
            color: #bfbfbf !important;
        }
    }
    .btn {
        border-radius: 0;
        padding: 6px;
        border: 2px solid transparent;
        box-shadow: none;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        background: transparent;
        color: $white;
        position: absolute;
        right: 0;
        i {
            color: $color;
            font-size: 16px;
        }
    }
}
@media (min-width: 992px) {
    .header.top-header {
        padding-left: 240px;
    }
    .app-header .header-brand {
        display: none;
    }
}

@media (min-width: 992px) {
    .header.top-header {
        z-index: 975 !important;
    }
}

@media (max-width: 991px) {
    .app-sidebar__toggle {
        padding: 0 5px;
    }
    .app-sidebar__toggle {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .responsive-navbar.navbar .navbar-collapse {
        width: 100%;
        background: #fff;
        margin-top: 0;
        z-index: 9999;
        top: 65px;
        border-bottom: 1px solid $border;
        position: fixed;
        left: 0;
        right: 0;
    }
    .responsive-navbar.navbar {
        position: relative !important;
        display: -ms-flexbox;
        display: flex;
        top: -5px;
        margin: 0;
        margin-bottom: 0 !important;
        padding: 0;
    }
    .responsive-navbar .collapse.show .dropdown {
        position: inherit;
        .dropdown-menu {
            top: 50px !important;
            left: 5px !important;
            right: 5px !important;
            width: auto;
        }
    }
    .navsearch {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 20px;
        position: relative;
    }
    .app.sidebar-gone.search-show .header.top-header {
        z-index: 1036;
    }
    body {
        &.search-show,
        &.sidebar-show {
            overflow: hidden;
        }
        &.search-show:before,
        &.sidebar-show:before {
            content: '';
            position: fixed;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: $black-4;
            opacity: .6;
            z-index: 9;
        }
    }
    .header .form-inline .btn {
        padding: 8px 22px 8px 22px;
    }
    .header-brand {
        .header-brand-img {
            &.dark-logo, &.mobile-logo, &.darkmobile-logo {
                display: none;
            }
            &.desktop-lgo {
                display: block;
            }
        }
    }
}

@media (max-width: 991px) {
    .header.top-header {
        .header-option,
        .header-setting {
            display: none;
        }
    }
}

@media (max-width: 991.98px) {
    .header .form-inline .search-element .form-control {
        float: left;
        width: 100%;
        height: 40px;
        margin: 0 auto;
        // background: $background;
    }
    .search-show .search-element {
        display: block !important;
        background: $primary;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        padding: 12px 13px;
    }
    .form-control.header-search {
        color: $black;
    }
    .header {
        .form-inline {
            .search-element {
                position: absolute;
                z-index: 892;
                display: none;
            }
            .btn {
                color: #46494a !important;
            }
            .form-control::-webkit-input-placeholder {
                color: #b3bdca !important;
            }
        }
    }

    .animated-arrow.hor-toggle {
        text-align: center;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1.2rem;
        position: relative;
        border: 0;
        border-radius: 3px;
        margin: 5px;
        top: 0;
        span {
            margin-left: 8px;
            margin-top: 2px;
            vertical-align: middle;
        }
    }

}

.search-icon {
    margin-top: -3px;
}

@media (max-width: 991px) {
    .app-header {
        .dropdown-menu-arrow.dropdown-menu-end:after, .dropdown-menu-arrow.dropdown-menu-start:after {
            content: none;
        }
        .dropdown-menu-arrow.dropdown-menu-end:before, .dropdown-menu-arrow.dropdown-menu-start:before {
            content: none;
        }
    }
}

.dark-layout {
    display: none;
}

.header {
    .profile-dropdown .nav-link.icon {
        padding-top: 2px;
    }
}

.header-notify-msg {
    white-space: normal;
    width: 180px;
}
@media (max-width: 991px) {
    .navbar-toggler {
        font-size: 20px;
        line-height: 2;
    }
    .nav-link.icon {
        padding: 5px 0px;
        font-size: 20px;
    }
}

.notifications-scroll1, .notifications-scroll2 {
    height: 310px;

}

.app-header .dropdown-menu {
    min-width: 12rem;
}
