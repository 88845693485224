.item-card {
	img:hover {
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}

	.cardbody {
		position: relative;
		padding: 30px 15px;
		border-radius: 0 0 .2rem .2rem;
	}

	.cardtitle {
		span {
			display: block;
			font-size: .75rem;
		}

		a {
			color: #707070;
			font-weight: 500;
			text-decoration: none;
		}
	}

	.cardprice {
		position: absolute;
		top: 30px;
		right: 15px;

		span {
			&.type--strikethrough {
				opacity: .7;
				text-decoration: line-through;
			}

			display: block;
			color: #707070;
		}
	}
}

.item-card-img {
	overflow: hidden;
}

.item-card2-img {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.item-card2-desc ul li {
	margin-right: 1.5rem;
	font-size: 13px;

	&:last-child {
		margin-right: 0;
	}
}

/*--item-card2--*/

.item-card2-icons {
	position: absolute;
	top: 20px;
	right: 15px;
	z-index: 999;

	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.1rem;
		border-radius: 50px;
		color: #fff;
	}
}

/*--item-list--*/

.item-list .list-group-item {
	border: 0;
	margin-bottom: 0;
	border-bottom: 1px solid rgba(167, 180, 201, 0.3);

	i {
		margin-right: 5px;
		width: 25px;
		height: 25px;
		border-radius: 2px;
		font-size: 12px;
		text-align: center;
		line-height: 25px;
		color: #fff !important;
	}
}

/*--item-user--*/

.item-user .item-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: 0.3rem;

	&:hover {
		color: #fff;
	}
}

.item-box {
	.item-box-wrap {
		margin-top: 15px;
	}

	.stamp i {
		font-size: 18px;
		line-height: 2;
	}

	.item-box-wrap h5 {
		font-size: 20px;
		font-weight: 600;
	}
}