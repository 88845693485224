@media (min-width: 768px) {
  #checkoutsteps .checkoutline {
    height: 3px;
    background-color: $background;
    -webkit-transform: translateY(1.7rem);
    transform: translateY(1.7rem);
    margin: 0 11%;
    position: relative;
    z-index: 0;
    top: 28px;
  }
}

#checkoutsteps ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

#checkoutsteps ul[role="tablist"] li {
  display: flex;
  z-index: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  font-weight: 500;
  font-size: 1.1rem;
}

#checkoutsteps>.steps a {
  display: block;
  width: auto;
  background-color: transparent;
  color: $color;
  font-size: 16px;
  font-weight: 500;
  padding: 14px 0;
  text-decoration: none;
  border-radius: 30px;
  cursor: default;
  text-align: center;
  outline: none;
}

.wizard>.steps>ul li .current-info {
  display: none;
}

#checkoutsteps>.steps .current a .number {
  background: #{$primary};
  color: $white;
}

#checkoutsteps .item .thumb {
  display: inline-flex;
  width: 100px;
  height: 90px;
  justify-content: center;
  align-items: center;
}

#checkoutsteps .item .left {
  display: flex;
  align-items: center;
}

#checkoutsteps .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid $border;
  margin-bottom: 30px;
}

#checkoutsteps .item .purchase {
  text-decoration: none;
}

#checkoutsteps .item .purchase {
  display: inline-block;
  margin-left: 21px;
  color: $color;
}

#checkoutsteps .total {
  float: right;
  color: #{$primary};
}

#checkoutsteps>.steps {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 3px;
  padding: 0;
}

#checkoutsteps.wizard {
  border-radius: 10px;
}

.wizard>.content>.title {
  font-size: 18px;
  color: #8f9cc0;
  font-weight: 700;
  margin-bottom: 5px;
  display: none;
}

.wizard>.content>.body {
  float: none;
  position: static;
  width: auto;
  height: auto;
}

.wizard>.content>.title.current {
  display: none !important;
}

@media (min-width: 768px) {
  #checkoutsteps>.steps .number {
    background-color: $background;
    color: $color;
    border: 1px solid $border;
    font-size: 17px;
    font-weight: 400;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 15px auto;
  }
}

@media (max-width: 767px) {
  #checkoutsteps>.steps .number {
    background-color: $background;
    color: $color;
    font-size: 15px;
    font-weight: 600;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 15px auto;
  }
}

@media (max-width: 767px) {
  #checkoutsteps>.steps a {
    font-size: 10px !important;
  }
}

@media (max-width: 767px) {
  #checkoutsteps .checkoutline {
    height: 3px;
    background-color: $background;
    -webkit-transform: translateY(1.7rem);
    transform: translateY(1.7rem);
    margin: 0 11%;
    position: relative;
    z-index: 0;
    top: 20px;
  }
}

@media (max-width: 400px) {
  #checkoutsteps>.steps .number {
    font-size: 11px;
    width: 25px;
    height: 25px;
    // margin: 15px 2px;
  }

  .wizard>.actions a {
    text-align: center;
  }

  .wizard>.actions>ul {
    display: block;
  }

  .wizard>.actions>ul>li {
    margin: 2px;
  }

  #checkoutsteps .item .left {
    display: block;
  }

  #checkoutsteps .item .purchase {
    margin-left: 0;
  }

  #wizard3 {
    ul {
      display: block;
    }

    &.wizard.vertical>.steps ul li+li {
      margin-left: 0;
      margin-top: 10px;
    }

    &.wizard>.steps a {
      justify-content: start;
    }
  }
}