/*------Drop Downs-------*/

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle {
	&::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}

	&:empty::after {
		margin-left: 0;
	}
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.9375rem;
	color: #495057;
	text-align: left;
	list-style: none;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $border;
}

.dropdown-menu-end {
	right: 0;
	left: auto;
}

.dropup {
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: 0.125rem;
	}

	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0;
			border-right: 0.3em solid transparent;
			border-bottom: 0.3em solid;
			border-left: 0.3em solid transparent;
		}

		&:empty::after {
			margin-left: 0;
		}
	}
}

.dropright {
	.dropdown-menu {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: 0.125rem;
	}

	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0;
			border-bottom: 0.3em solid transparent;
			border-left: 0.3em solid;
		}

		&:empty::after {
			margin-left: 0;
		}

		&::after {
			vertical-align: 0;
		}
	}
}

.dropleft {
	.dropdown-menu {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: 0.125rem;
	}

	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			display: none;
		}

		&::before {
			display: inline-block;
			width: 0;
			height: 0;
			margin-right: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0.3em solid;
			border-bottom: 0.3em solid transparent;
		}

		&:empty::after {
			margin-left: 0;
		}

		&::before {
			vertical-align: 0;
		}
	}
}

.dropdown-menu {

	&[data-popper-placement^="top"],
	&[data-popper-placement^="right"],
	&[data-popper-placement^="bottom"],
	&[data-popper-placement^="left"] {
		right: auto;
		bottom: auto;
	}
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;

	&:hover,
	&:focus {
		text-decoration: none;
		background-color: $background;
	}

	&.active,
	&:active {
		text-decoration: none;
		background-color: $background;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: transparent;
	}
}

.dropdown-menu.show {
	display: block;
	margin: 0;
	border: 1px solid $border;
	box-shadow: none;
}

.nav-item.show .dropdown-menu.dropdown-menu-arrow.show {
	top: -1px !important;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #868e96;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}

.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;

	&::after {
		margin-left: 0;
	}
}

.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}

/*-----List Of Dropdwons-----*/

.btn.dropdown-toggle~.dropdown-menu {
	background-color: rgb(244, 244, 244) !important;
	background-color: rgb(255, 255, 255) !important;
	border: 0 solid rgb(66, 133, 244) !important;
	box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
	// top: 0px !important;
	margin: 0px !important;
	padding: 0px !important;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu {
	background-color: rgb(244, 244, 244) !important;
	background-color: rgb(255, 255, 255) !important;
	border: 0 solid rgb(66, 133, 244) !important;
	box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
	top: 0px !important;
	margin: 0px !important;
	padding: 0px !important;
	position: absolute !important;
	width: 100% !important;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 9999;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	background-color: $white;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;

	>li>a {
		display: block;
		padding: 8px 20px;
		clear: both;
		font-weight: 400;
		line-height: 1.428571429;
		color: $color;
		white-space: nowrap;

		&:hover,
		&:focus {
			text-decoration: none;
			color: $color;
			background-color: $background;
		}
	}

	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: $border;
	}

	.dropdown-plus-title {
		width: 100% !important;
		color: $color !important;
		padding: 6px 12px !important;
		font-weight: 500 !important;
		border: 0 solid $border !important;
		border-bottom-width: 1px !important;
		cursor: pointer !important;
	}
}

ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	line-height: 20px !important;
}

.btn.dropdown-toggle {
	&.btn-primary~.dropdown-menu .dropdown-plus-title {
		border-color: $primary !important;
	}

	&.btn-success~.dropdown-menu .dropdown-plus-title {
		border-color: $success !important;
	}

	&.btn-info~.dropdown-menu .dropdown-plus-title {
		border-color: $info !important;
	}

	&.btn-warning~.dropdown-menu .dropdown-plus-title {
		border-color: $warning !important;
	}

	&.btn-danger~.dropdown-menu .dropdown-plus-title {
		border-color: $danger !important;
	}
}

.dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-bottom: 1px solid $border;
}

.dropdown-demo .dropdown-menu {
	position: static;
	float: none;
}

.dropdown-menu-header label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #8c8ea9;
}

.dropdown-media-list .media {

	&:hover,
	&:focus {
		background-color: #f8f9fa;
	}

	padding: 12px 15px;
}

.media {
	display: flex;
	align-items: flex-start;
}

.dropdown-media-list {
	img {
		border-radius: 100%;
	}

	.media-body {
		margin-left: 15px;

		> {
			div {
				display: flex;
				align-items: center;
				justify-content: space-between;

				p {
					margin-bottom: 0;
					font-weight: 500;
					color: #8c8ea9;
					font-size: 14px;
				}

				span {
					font-size: 12px;
					color: #CFCFCF;
				}
			}

			p {
				font-size: 14px;
				color: #bfbfbf;
				margin-bottom: 0;
			}
		}
	}
}

.dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: 12px;
	border-top: 1px solid $border;
}

.label {
	display: inline-block;
	margin-bottom: .5rem;
	padding: 0.05rem 0.5rem;
}

.dropdown-media-list {
	padding: 0;
}

@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
		padding-top: 15px !important;
		padding-bottom: 15px !important;
	}
}

@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu {
		width: auto !important;
	}
}

.dropdown {
	display: block;
}

.dropdown-menu {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.dropdown-item {
	color: $color;
}

.dropdown-menu-arrow.dropdown-menu-end {

	&:before,
	&:after {
		left: auto;
		right: 12px;
	}
}

.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;

	&:after {
		vertical-align: 0.155em;
	}

	&:empty:after {
		margin-left: 0;
	}
}

.dropdown-icon {
	color: #9aa0ac;
	margin-left: .5rem;
	margin-right: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
}

.dropdown-menu-arrow.dropdown-menu-end:after, .dropdown-menu-arrow.dropdown-menu-start:after {
    content: "";
    position: absolute;
    top: -8.5px;
    border-bottom: 9px solid $white;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
.dropdown-menu-arrow.dropdown-menu-end:before, .dropdown-menu-arrow.dropdown-menu-start:before {
    content: "";
    position: absolute;
    top: -10px;
    border-bottom: 10px solid $border;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}